* {
  font-family: 'Dosis', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@500&display=swap');
@import url(https://db.onlinewebfonts.com/c/009befabad6721adc22360ae46867d2b?family=Rise+of+Kingdom);

@font-face {
  font-family: "Rise of Kingdom";
  src: url("https://db.onlinewebfonts.com/t/009befabad6721adc22360ae46867d2b.eot");
  src: url("https://db.onlinewebfonts.com/t/009befabad6721adc22360ae46867d2b.eot?#iefix") format("embedded-opentype"), url("https://db.onlinewebfonts.com/t/009befabad6721adc22360ae46867d2b.woff2") format("woff2"), url("https://db.onlinewebfonts.com/t/009befabad6721adc22360ae46867d2b.woff") format("woff"), url("https://db.onlinewebfonts.com/t/009befabad6721adc22360ae46867d2b.ttf") format("truetype"), url("https://db.onlinewebfonts.com/t/009befabad6721adc22360ae46867d2b.svg#Rise of Kingdom") format("svg");
}

::-webkit-scrollbar {
  width: 10px;
  display: none;
}

.doing{
  text-shadow: 0 0 10px rgb(236, 194, 87);
  color: rgb(255, 242, 170);
}

.grayFonts{
  color: #e2e2e2a6;
}

.next{
  display: flex;
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px yellow;
  }
  to {
    box-shadow: 0 0 20px yellow;
  }
}

.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #89849b;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
 
  margin: -20px 0 0 -20px;
  -webkit-animation-name: bounce;
  -moz-animation-name: bounce;
  -o-animation-name: bounce;
  -ms-animation-name: bounce;
  animation-name: bounce;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  -ms-animation-duration: 1s;
  animation-duration: 1s;
  z-index: 1000000000;
}
.pin:after {
  content: '';
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #2f2f2f;
  position: absolute;
  border-radius: 50%;
  z-index: 1000000000;

}
.pulse {
  background: rgba(0,0,0,0.2);
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
 
  margin: 11px 0px 0px -12px;
  -webkit-transform: rotateX(55deg);
  -moz-transform: rotateX(55deg);
  -o-transform: rotateX(55deg);
  -ms-transform: rotateX(55deg);
  transform: rotateX(55deg);
  z-index: -2;
  z-index: 1000000000;

}
.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  -webkit-animation: pulsate 1s ease-out;
  -moz-animation: pulsate 1s ease-out;
  -o-animation: pulsate 1s ease-out;
  -ms-animation: pulsate 1s ease-out;
  animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-box-shadow: 0 0 1px 2px #89849b;
  box-shadow: 0 0 1px 2px #89849b;
  -webkit-animation-delay: 1.1s;
  -moz-animation-delay: 1.1s;
  -o-animation-delay: 1.1s;
  -ms-animation-delay: 1.1s;
  z-index: 1000000000;

  animation-delay: 1.1s;
}
@-moz-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-moz-keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}
@-webkit-keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}
@-o-keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}
@keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}



.boxWrap2Footer {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.49261423319327735) 35%, rgba(0,0,0,0.9491968662464986) 100%), url('./assets/sword.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media screen and (max-width: 499px) {

  .miniBarMain{
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fd2;
    display: flex;
    justify-content: space-between;
  }

  .miniBar{
    display: flex;
    justify-content: space-between;
  }

  .navbarMain{
    padding-right: 30px;
     position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }
  
  .navbar {
    width: 40px;
    justify-content: flex-end;
    cursor: pointer;    
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    z-index: 1000000000000000000;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: rgba(218, 165, 32, 0.685) 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: min-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px rgba(49, 49, 49, 0.603) solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
     margin-left: auto;  
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }

  .allWrap {
    background-color: black;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .containerMob {
    display: none;
  }

  .cont {
    background: radial-gradient(circle, rgba(13, 24, 31, 0.2777485994397759) 0%, rgba(13, 24, 31, 0.48783263305322133) 69%, rgba(13, 24, 31, 0.8743872549019608) 100%), url('./assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .in2 p {
    font-size: 16px;
    color: white;
    font-weight: bold;
    text-align: center;
    letter-spacing: 3px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    font-family: "Rise of Kingdom";
  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 85%;
  }

  .introduction {
    padding-top: 40px;
  }

  .container {
    max-width: 430px;
    max-height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
    display: none;
  }

  .container:hover img {
    opacity: 0.3;
  }

  .container img:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  .container img {
    width: 40%;
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
    box-shadow: 0 0 10px #d44e00;
    border: 2px solid #d6985c;
    z-index: 10000;
  }

  .containerMob {
    max-width: 300px;
    max-height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 50px;
    margin-bottom: 80px;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
  }

  .containerMob:hover img {
    opacity: 0.3;
  }

  .containerMob img:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  .containerMob img {
    width: 33%;
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
    box-shadow: 0 0 10px #d44e00;
    border: 2px solid #d6985c;
    z-index: 10000;
  }

  .containerMob div {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 10px;
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .ag-format-container {
    width: 1142px;
    margin: 0 auto;
  }

  .ag-sparks {
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    position: relative;
  }

  .ag-sparks:before,
  .ag-sparks:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ag-sparks:before {
    content: "";

    -webkit-animation: an-blink 30s linear 3s infinite;
    -moz-animation: an-blink 30s linear 3s infinite;
    -o-animation: an-blink 30s linear 3s infinite;
    animation: an-blink 30s linear 3s infinite;
  }

  .ag-sparks:after {
    content: "";

    z-index: 10;
  }

  .ag-spark {
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    position: relative;
  }

  .ag-spark:before,
  .ag-spark:after {
    content: "";
    background-image: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/sparks/images/spark.png);
    background-position-x: 50%;
    background-repeat: repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    opacity: 0;

    will-change: transform;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ag-spark:before {
    -webkit-animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
    -moz-animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
    -o-animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
    animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
  }

  .ag-spark:after {
    -webkit-animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
    -moz-animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
    -o-animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
    animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
  }

  .ag-lightning {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ag-lightning:before,
  .ag-lightning:after {
    content: "";

    opacity: 0;

    will-change: transform;

    position: absolute;
  }

  .ag-lightning:before {
    width: 272px;
    background-image: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/sparks/images/lightning-1.png);

    top: -9.75em;
    left: 50%;

    -webkit-animation: an-lightning-first 30s linear 3s infinite;
    -moz-animation: an-lightning-first 30s linear 3s infinite;
    -o-animation: an-lightning-first 30s linear 3s infinite;
    animation: an-lightning-first 30s linear 3s infinite;
  }

  .ag-lightning:after {
    width: 254px;
    background-image: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/sparks/images/lightning-1.png);

    top: -10.1875em;
    right: -6.1875em;

    -webkit-animation: an-lightning-second 30s linear 3s infinite;
    -moz-animation: an-lightning-second 30s linear 3s infinite;
    -o-animation: an-lightning-second 30s linear 3s infinite;
    animation: an-lightning-second 30s linear 3s infinite;
  }

  @-webkit-keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @-moz-keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @-o-keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @-webkit-keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @-moz-keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @-o-keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @-webkit-keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @-moz-keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      -moz-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      -moz-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @-o-keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      -o-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      -o-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      -moz-transform: translateX(1.25em);
      -o-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      -moz-transform: translateX(-1.25em);
      -o-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @-webkit-keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @-moz-keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      -moz-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      -moz-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @-o-keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      -o-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      -o-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      -moz-transform: scale(-1, 1) translateX(1.25em);
      -o-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      -moz-transform: scale(-1, 1) translateX(-1.25em);
      -o-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @-webkit-keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @-moz-keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @-o-keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @-moz-keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @-o-keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @media only screen and (max-width: 767px) {
    .ag-format-container {
      width: 96%;
    }

  }

  @media only screen and (max-width: 639px) {}

  @media only screen and (max-width: 479px) {}

  @media (min-width: 768px) and (max-width: 979px) {
    .ag-format-container {
      width: 750px;
    }

  }

  @media (min-width: 980px) and (max-width: 1161px) {
    .ag-format-container {
      width: 960px;
    }

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: linear-gradient(87deg, rgba(0, 0, 0, 0.1956954656862745) 0%, rgba(0, 0, 0, 0.6606814600840336) 35%, rgba(0, 0, 0, 0.8819699754901961) 100%), url('./assets/map.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 57px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyHK {
    font-size: 57px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 40px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .storyH2-2 {
    font-size: 40px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 17px;
    text-align: justify;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 85%;
    margin-right: auto;
    margin-left: auto;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }


  .map {
    width: 80%;
    height: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 22px #704531;
    margin-bottom: 25px;
  }

  .logos {
    width: 55%;
    height: 55%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 22px #704531;
    margin-bottom: 25px;
    display: none;
   }

   .logos_mobile {
    width: 70%;
    height: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 22px #704531;
    margin-bottom: 25px;
  }
 

  /*.............. FAQ ..............*/


  summary {
    font-size: 18px;
    background: rgba(19, 19, 19, 0.767);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #e2e2e2 2px ridge;
    box-shadow: 0 0 15px #704531;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 85%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .faqbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13126969537815125) 0%, rgba(0, 0, 0, 0.3385526085434174) 35%, rgba(0, 0, 0, 0.7979363620448179) 100%), url('./assets/faqbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .team {
    width: 100%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .teambg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13126969537815125) 0%, rgba(0, 0, 0, 0.3385526085434174) 35%, rgba(0, 0, 0, 0.7979363620448179) 100%), url('./assets/teambg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
  }

  .kingdomsBg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
    padding-top: 50px;
  }

  .kingdomsSection1 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/lionKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection2 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/raakshaKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection3 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/snakeKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection4 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/eagleKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection5 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/dragonKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .logoF {
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #ff0, 0 -10px 20px #ff8000, 0 -18px 40px #F00;
    color: white;
    font-size: 40px
  }

  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(250, 219, 207);
    text-align: center;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
   }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fd2;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connect div:hover {
    color: rgb(255, 185, 138);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    transition: transform .2s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 18px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;

  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #99faf500;
    font-size: 15px;
    padding: 10px;
    width: 150px;
    border: 2px rgb(216, 179, 130) solid;
    color: rgb(255, 255, 255);
    box-shadow: 0 0 10px #b18a41;
    transition: 0.3s ease;
    cursor: none;
    margin-left: 20px;
    margin-right: 20px;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 55px;
    height: 55px;
  }

  .downImg {
    width: 30px;
    height: 30px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
    margin-top: -50px;
  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  /*........................Roadmap........................*/

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    margin-left: -25px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 35px;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #eeb38b;
    color: white;
    font-family: "Rise of Kingdom";
    margin-left: -65px;
    width: 195px;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 19px;
    margin-left: -65px;
    width: 220px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 60px;
    height: 60px;
    border: 4px solid #fff;
    position: absolute;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 6px;
    background-color: #f2b14b;
    left: 38px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 6px;
    border: 5px solid rgba(63, 63, 63, 0.253);
    left: 38px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #f2b14b;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #f2b14b;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid rgba(63, 63, 63, 0.253);

  }

  .footPrint2 {
    width: 40px;
    height: 40px;
  }

  .rmbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13126969537815125) 0%, rgba(0, 0, 0, 0.3385526085434174) 35%, rgba(0, 0, 0, 0.7979363620448179) 100%), url('./assets/rmbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  h4 {
    text-transform: capitalize;
    background-color: rgba(0, 0, 0, 0.384);
    color: white;
    padding: 10px;
    box-shadow: 0 0 15px #f2b24b62;
    font-size: 18px;
    margin-left: -65px;
    width: 200px;
  }

  .fire {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .fireGlow{
    animation: glow 2s ease-in-out infinite alternate;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .firefly {
    position: sticky;
    position: -webkit-sticky;
    left: 50%;
    top: 50%;
    width: 0.4vw;
    height: 0.4vw;
    margin: -0.2vw 0 0 9.8vw;
    animation: ease 200s alternate infinite;
    pointer-events: none;
  }

  .firefly::before,
  .firefly::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: -10vw;
  }

  .firefly::before {
    background: black;
    opacity: 0.4;
    animation: drift ease alternate infinite;
  }

  .firefly::after {
    background: white;
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow;
    animation: drift ease alternate infinite, flash ease infinite;
  }

  .firefly:nth-child(1) {
    animation-name: move1;
  }

  .firefly:nth-child(1)::before {
    animation-duration: 10s;
  }

  .firefly:nth-child(1)::after {
    animation-duration: 10s, 5869ms;
    animation-delay: 0ms, 5103ms;
  }

  @keyframes move1 {
    0% {
      transform: translateX(-41vw) translateY(23vh) scale(0.42);
    }

    5% {
      transform: translateX(9vw) translateY(-45vh) scale(0.34);
    }

    10% {
      transform: translateX(-17vw) translateY(-42vh) scale(1);
    }

    15% {
      transform: translateX(8vw) translateY(-23vh) scale(0.51);
    }

    20% {
      transform: translateX(-29vw) translateY(-20vh) scale(0.56);
    }

    25% {
      transform: translateX(8vw) translateY(16vh) scale(0.79);
    }

    30% {
      transform: translateX(11vw) translateY(46vh) scale(0.54);
    }

    35% {
      transform: translateX(9vw) translateY(-45vh) scale(0.58);
    }

    40% {
      transform: translateX(10vw) translateY(-37vh) scale(0.69);
    }

    45% {
      transform: translateX(5vw) translateY(26vh) scale(0.52);
    }

    50% {
      transform: translateX(-36vw) translateY(-39vh) scale(0.43);
    }

    55% {
      transform: translateX(-45vw) translateY(35vh) scale(0.96);
    }

    60% {
      transform: translateX(17vw) translateY(38vh) scale(0.66);
    }

    65% {
      transform: translateX(-39vw) translateY(-34vh) scale(0.75);
    }

    70% {
      transform: translateX(-45vw) translateY(18vh) scale(0.72);
    }

    75% {
      transform: translateX(48vw) translateY(21vh) scale(0.42);
    }

    80% {
      transform: translateX(28vw) translateY(-2vh) scale(0.82);
    }

    85% {
      transform: translateX(-29vw) translateY(30vh) scale(0.48);
    }

    90% {
      transform: translateX(12vw) translateY(16vh) scale(0.42);
    }

    95% {
      transform: translateX(-8vw) translateY(-24vh) scale(0.52);
    }

    100% {
      transform: translateX(-49vw) translateY(6vh) scale(0.34);
    }
  }

  .firefly:nth-child(2) {
    animation-name: move2;
  }

  .firefly:nth-child(2)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(2)::after {
    animation-duration: 13s, 8622ms;
    animation-delay: 0ms, 6050ms;
  }

  @keyframes move2 {
    0% {
      transform: translateX(-13vw) translateY(17vh) scale(0.94);
    }

    4.347826087% {
      transform: translateX(12vw) translateY(24vh) scale(0.48);
    }

    8.6956521739% {
      transform: translateX(16vw) translateY(-40vh) scale(0.39);
    }

    13.0434782609% {
      transform: translateX(22vw) translateY(22vh) scale(0.68);
    }

    17.3913043478% {
      transform: translateX(19vw) translateY(-14vh) scale(0.42);
    }

    21.7391304348% {
      transform: translateX(2vw) translateY(-18vh) scale(0.3);
    }

    26.0869565217% {
      transform: translateX(2vw) translateY(38vh) scale(0.29);
    }

    30.4347826087% {
      transform: translateX(28vw) translateY(6vh) scale(0.87);
    }

    34.7826086957% {
      transform: translateX(-48vw) translateY(21vh) scale(0.88);
    }

    39.1304347826% {
      transform: translateX(32vw) translateY(15vh) scale(0.76);
    }

    43.4782608696% {
      transform: translateX(32vw) translateY(1vh) scale(0.31);
    }

    47.8260869565% {
      transform: translateX(35vw) translateY(0vh) scale(0.29);
    }

    52.1739130435% {
      transform: translateX(9vw) translateY(14vh) scale(0.27);
    }

    56.5217391304% {
      transform: translateX(12vw) translateY(-20vh) scale(0.64);
    }

    60.8695652174% {
      transform: translateX(-21vw) translateY(27vh) scale(0.72);
    }

    65.2173913043% {
      transform: translateX(-47vw) translateY(-33vh) scale(0.31);
    }

    69.5652173913% {
      transform: translateX(-25vw) translateY(7vh) scale(0.85);
    }

    73.9130434783% {
      transform: translateX(42vw) translateY(38vh) scale(0.88);
    }

    78.2608695652% {
      transform: translateX(11vw) translateY(10vh) scale(0.91);
    }

    82.6086956522% {
      transform: translateX(2vw) translateY(6vh) scale(0.59);
    }

    86.9565217391% {
      transform: translateX(37vw) translateY(4vh) scale(0.59);
    }

    91.3043478261% {
      transform: translateX(-34vw) translateY(-32vh) scale(0.95);
    }

    95.652173913% {
      transform: translateX(-47vw) translateY(32vh) scale(0.81);
    }

    100% {
      transform: translateX(32vw) translateY(22vh) scale(0.53);
    }
  }

  .firefly:nth-child(3) {
    animation-name: move3;
  }

  .firefly:nth-child(3)::before {
    animation-duration: 14s;
  }

  .firefly:nth-child(3)::after {
    animation-duration: 14s, 7972ms;
    animation-delay: 0ms, 2219ms;
  }

  @keyframes move3 {
    0% {
      transform: translateX(-9vw) translateY(-12vh) scale(0.35);
    }

    4% {
      transform: translateX(9vw) translateY(45vh) scale(0.75);
    }

    8% {
      transform: translateX(37vw) translateY(-35vh) scale(0.42);
    }

    12% {
      transform: translateX(-49vw) translateY(5vh) scale(0.46);
    }

    16% {
      transform: translateX(42vw) translateY(26vh) scale(0.34);
    }

    20% {
      transform: translateX(-12vw) translateY(19vh) scale(0.84);
    }

    24% {
      transform: translateX(23vw) translateY(6vh) scale(0.45);
    }

    28% {
      transform: translateX(18vw) translateY(-16vh) scale(0.47);
    }

    32% {
      transform: translateX(22vw) translateY(-40vh) scale(0.45);
    }

    36% {
      transform: translateX(-37vw) translateY(33vh) scale(0.75);
    }

    40% {
      transform: translateX(44vw) translateY(33vh) scale(0.61);
    }

    44% {
      transform: translateX(-44vw) translateY(-18vh) scale(0.57);
    }

    48% {
      transform: translateX(-9vw) translateY(39vh) scale(0.45);
    }

    52% {
      transform: translateX(10vw) translateY(41vh) scale(0.51);
    }

    56% {
      transform: translateX(23vw) translateY(-41vh) scale(0.31);
    }

    60% {
      transform: translateX(48vw) translateY(-5vh) scale(0.33);
    }

    64% {
      transform: translateX(-49vw) translateY(-43vh) scale(0.3);
    }

    68% {
      transform: translateX(-39vw) translateY(5vh) scale(0.44);
    }

    72% {
      transform: translateX(-18vw) translateY(-28vh) scale(0.94);
    }

    76% {
      transform: translateX(22vw) translateY(-18vh) scale(0.67);
    }

    80% {
      transform: translateX(10vw) translateY(-32vh) scale(0.56);
    }

    84% {
      transform: translateX(-7vw) translateY(-48vh) scale(0.47);
    }

    88% {
      transform: translateX(30vw) translateY(37vh) scale(0.64);
    }

    92% {
      transform: translateX(-46vw) translateY(-37vh) scale(0.53);
    }

    96% {
      transform: translateX(9vw) translateY(40vh) scale(0.87);
    }

    100% {
      transform: translateX(-48vw) translateY(-17vh) scale(0.46);
    }
  }

  .firefly:nth-child(4) {
    animation-name: move4;
  }

  .firefly:nth-child(4)::before {
    animation-duration: 10s;
  }

  .firefly:nth-child(4)::after {
    animation-duration: 10s, 8866ms;
    animation-delay: 0ms, 4122ms;
  }

  @keyframes move4 {
    0% {
      transform: translateX(-1vw) translateY(17vh) scale(0.76);
    }

    5.5555555556% {
      transform: translateX(-20vw) translateY(-6vh) scale(0.96);
    }

    11.1111111111% {
      transform: translateX(7vw) translateY(27vh) scale(0.44);
    }

    16.6666666667% {
      transform: translateX(1vw) translateY(32vh) scale(0.75);
    }

    22.2222222222% {
      transform: translateX(48vw) translateY(-14vh) scale(0.83);
    }

    27.7777777778% {
      transform: translateX(-34vw) translateY(-30vh) scale(0.6);
    }

    33.3333333333% {
      transform: translateX(40vw) translateY(-27vh) scale(1);
    }

    38.8888888889% {
      transform: translateX(-14vw) translateY(-28vh) scale(0.29);
    }

    44.4444444444% {
      transform: translateX(37vw) translateY(-14vh) scale(0.56);
    }

    50% {
      transform: translateX(29vw) translateY(8vh) scale(0.37);
    }

    55.5555555556% {
      transform: translateX(-14vw) translateY(21vh) scale(0.39);
    }

    61.1111111111% {
      transform: translateX(-1vw) translateY(38vh) scale(0.64);
    }

    66.6666666667% {
      transform: translateX(40vw) translateY(-17vh) scale(0.78);
    }

    72.2222222222% {
      transform: translateX(-6vw) translateY(-45vh) scale(0.54);
    }

    77.7777777778% {
      transform: translateX(-47vw) translateY(-46vh) scale(0.38);
    }

    83.3333333333% {
      transform: translateX(38vw) translateY(-13vh) scale(0.86);
    }

    88.8888888889% {
      transform: translateX(-8vw) translateY(35vh) scale(0.39);
    }

    94.4444444444% {
      transform: translateX(-25vw) translateY(-1vh) scale(0.44);
    }

    100% {
      transform: translateX(0vw) translateY(-34vh) scale(0.57);
    }
  }

  .firefly:nth-child(5) {
    animation-name: move5;
  }

  .firefly:nth-child(5)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(5)::after {
    animation-duration: 18s, 6640ms;
    animation-delay: 0ms, 1937ms;
  }

  @keyframes move5 {
    0% {
      transform: translateX(33vw) translateY(-14vh) scale(0.83);
    }

    4.5454545455% {
      transform: translateX(-14vw) translateY(6vh) scale(0.26);
    }

    9.0909090909% {
      transform: translateX(-15vw) translateY(-12vh) scale(0.56);
    }

    13.6363636364% {
      transform: translateX(39vw) translateY(20vh) scale(0.69);
    }

    18.1818181818% {
      transform: translateX(-21vw) translateY(-1vh) scale(0.27);
    }

    22.7272727273% {
      transform: translateX(-38vw) translateY(47vh) scale(0.43);
    }

    27.2727272727% {
      transform: translateX(10vw) translateY(-4vh) scale(0.53);
    }

    31.8181818182% {
      transform: translateX(-13vw) translateY(21vh) scale(0.88);
    }

    36.3636363636% {
      transform: translateX(-3vw) translateY(46vh) scale(0.76);
    }

    40.9090909091% {
      transform: translateX(-2vw) translateY(-11vh) scale(0.32);
    }

    45.4545454545% {
      transform: translateX(14vw) translateY(4vh) scale(0.32);
    }

    50% {
      transform: translateX(8vw) translateY(8vh) scale(0.76);
    }

    54.5454545455% {
      transform: translateX(18vw) translateY(-35vh) scale(0.6);
    }

    59.0909090909% {
      transform: translateX(15vw) translateY(-43vh) scale(0.28);
    }

    63.6363636364% {
      transform: translateX(-12vw) translateY(12vh) scale(0.68);
    }

    68.1818181818% {
      transform: translateX(5vw) translateY(25vh) scale(0.96);
    }

    72.7272727273% {
      transform: translateX(-21vw) translateY(-35vh) scale(0.4);
    }

    77.2727272727% {
      transform: translateX(-44vw) translateY(7vh) scale(0.98);
    }

    81.8181818182% {
      transform: translateX(40vw) translateY(-29vh) scale(0.91);
    }

    86.3636363636% {
      transform: translateX(30vw) translateY(-36vh) scale(0.49);
    }

    90.9090909091% {
      transform: translateX(48vw) translateY(22vh) scale(0.67);
    }

    95.4545454545% {
      transform: translateX(18vw) translateY(-10vh) scale(0.77);
    }

    100% {
      transform: translateX(-40vw) translateY(-6vh) scale(0.91);
    }
  }

  .firefly:nth-child(6) {
    animation-name: move6;
  }

  .firefly:nth-child(6)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(6)::after {
    animation-duration: 15s, 8079ms;
    animation-delay: 0ms, 6703ms;
  }

  @keyframes move6 {
    0% {
      transform: translateX(7vw) translateY(-40vh) scale(0.74);
    }

    4.7619047619% {
      transform: translateX(-23vw) translateY(32vh) scale(0.7);
    }

    9.5238095238% {
      transform: translateX(-9vw) translateY(0vh) scale(0.34);
    }

    14.2857142857% {
      transform: translateX(-36vw) translateY(18vh) scale(0.65);
    }

    19.0476190476% {
      transform: translateX(37vw) translateY(-8vh) scale(0.84);
    }

    23.8095238095% {
      transform: translateX(7vw) translateY(27vh) scale(0.44);
    }

    28.5714285714% {
      transform: translateX(-3vw) translateY(8vh) scale(0.93);
    }

    33.3333333333% {
      transform: translateX(-18vw) translateY(5vh) scale(0.87);
    }

    38.0952380952% {
      transform: translateX(25vw) translateY(19vh) scale(0.45);
    }

    42.8571428571% {
      transform: translateX(46vw) translateY(14vh) scale(0.95);
    }

    47.619047619% {
      transform: translateX(13vw) translateY(-10vh) scale(0.5);
    }

    52.380952381% {
      transform: translateX(15vw) translateY(11vh) scale(0.4);
    }

    57.1428571429% {
      transform: translateX(15vw) translateY(-35vh) scale(0.96);
    }

    61.9047619048% {
      transform: translateX(-13vw) translateY(41vh) scale(0.35);
    }

    66.6666666667% {
      transform: translateX(-23vw) translateY(26vh) scale(0.9);
    }

    71.4285714286% {
      transform: translateX(-7vw) translateY(4vh) scale(0.81);
    }

    76.1904761905% {
      transform: translateX(-49vw) translateY(-18vh) scale(0.84);
    }

    80.9523809524% {
      transform: translateX(26vw) translateY(33vh) scale(0.43);
    }

    85.7142857143% {
      transform: translateX(-6vw) translateY(23vh) scale(0.48);
    }

    90.4761904762% {
      transform: translateX(46vw) translateY(-5vh) scale(0.8);
    }

    95.2380952381% {
      transform: translateX(42vw) translateY(33vh) scale(0.6);
    }

    100% {
      transform: translateX(-28vw) translateY(26vh) scale(0.64);
    }
  }

  .firefly:nth-child(7) {
    animation-name: move7;
  }

  .firefly:nth-child(7)::before {
    animation-duration: 17s;
  }

  .firefly:nth-child(7)::after {
    animation-duration: 17s, 9827ms;
    animation-delay: 0ms, 3278ms;
  }

  @keyframes move7 {
    0% {
      transform: translateX(-1vw) translateY(41vh) scale(0.51);
    }

    5.5555555556% {
      transform: translateX(38vw) translateY(32vh) scale(0.61);
    }

    11.1111111111% {
      transform: translateX(36vw) translateY(44vh) scale(0.89);
    }

    16.6666666667% {
      transform: translateX(-22vw) translateY(-36vh) scale(0.68);
    }

    22.2222222222% {
      transform: translateX(4vw) translateY(-2vh) scale(0.87);
    }

    27.7777777778% {
      transform: translateX(11vw) translateY(-5vh) scale(0.48);
    }

    33.3333333333% {
      transform: translateX(-30vw) translateY(28vh) scale(0.43);
    }

    38.8888888889% {
      transform: translateX(-38vw) translateY(-4vh) scale(0.84);
    }

    44.4444444444% {
      transform: translateX(-29vw) translateY(33vh) scale(0.43);
    }

    50% {
      transform: translateX(1vw) translateY(-4vh) scale(0.96);
    }

    55.5555555556% {
      transform: translateX(38vw) translateY(-23vh) scale(0.5);
    }

    61.1111111111% {
      transform: translateX(-47vw) translateY(-1vh) scale(0.91);
    }

    66.6666666667% {
      transform: translateX(40vw) translateY(-8vh) scale(0.52);
    }

    72.2222222222% {
      transform: translateX(26vw) translateY(14vh) scale(0.83);
    }

    77.7777777778% {
      transform: translateX(17vw) translateY(-48vh) scale(0.97);
    }

    83.3333333333% {
      transform: translateX(-22vw) translateY(45vh) scale(0.85);
    }

    88.8888888889% {
      transform: translateX(-21vw) translateY(-9vh) scale(0.45);
    }

    94.4444444444% {
      transform: translateX(39vw) translateY(38vh) scale(0.59);
    }

    100% {
      transform: translateX(-37vw) translateY(-2vh) scale(0.55);
    }
  }

  .firefly:nth-child(8) {
    animation-name: move8;
  }

  .firefly:nth-child(8)::before {
    animation-duration: 9s;
  }

  .firefly:nth-child(8)::after {
    animation-duration: 9s, 8380ms;
    animation-delay: 0ms, 4603ms;
  }

  @keyframes move8 {
    0% {
      transform: translateX(-17vw) translateY(30vh) scale(0.58);
    }

    5.8823529412% {
      transform: translateX(-13vw) translateY(-42vh) scale(0.82);
    }

    11.7647058824% {
      transform: translateX(-10vw) translateY(-38vh) scale(0.86);
    }

    17.6470588235% {
      transform: translateX(49vw) translateY(47vh) scale(0.97);
    }

    23.5294117647% {
      transform: translateX(-17vw) translateY(-44vh) scale(1);
    }

    29.4117647059% {
      transform: translateX(-6vw) translateY(-20vh) scale(0.5);
    }

    35.2941176471% {
      transform: translateX(-39vw) translateY(-34vh) scale(0.33);
    }

    41.1764705882% {
      transform: translateX(38vw) translateY(49vh) scale(0.45);
    }

    47.0588235294% {
      transform: translateX(43vw) translateY(39vh) scale(0.43);
    }

    52.9411764706% {
      transform: translateX(46vw) translateY(-4vh) scale(0.27);
    }

    58.8235294118% {
      transform: translateX(-16vw) translateY(-20vh) scale(0.71);
    }

    64.7058823529% {
      transform: translateX(-43vw) translateY(-40vh) scale(0.84);
    }

    70.5882352941% {
      transform: translateX(-10vw) translateY(13vh) scale(0.81);
    }

    76.4705882353% {
      transform: translateX(38vw) translateY(1vh) scale(0.36);
    }

    82.3529411765% {
      transform: translateX(32vw) translateY(-40vh) scale(0.71);
    }

    88.2352941176% {
      transform: translateX(0vw) translateY(-49vh) scale(0.32);
    }

    94.1176470588% {
      transform: translateX(12vw) translateY(-49vh) scale(0.29);
    }

    100% {
      transform: translateX(7vw) translateY(-3vh) scale(0.94);
    }
  }

  .firefly:nth-child(9) {
    animation-name: move9;
  }

  .firefly:nth-child(9)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(9)::after {
    animation-duration: 18s, 10823ms;
    animation-delay: 0ms, 705ms;
  }

  @keyframes move9 {
    0% {
      transform: translateX(3vw) translateY(4vh) scale(0.41);
    }

    5.2631578947% {
      transform: translateX(-43vw) translateY(40vh) scale(0.79);
    }

    10.5263157895% {
      transform: translateX(-39vw) translateY(22vh) scale(0.38);
    }

    15.7894736842% {
      transform: translateX(-32vw) translateY(1vh) scale(0.41);
    }

    21.0526315789% {
      transform: translateX(-33vw) translateY(31vh) scale(0.81);
    }

    26.3157894737% {
      transform: translateX(2vw) translateY(-33vh) scale(0.43);
    }

    31.5789473684% {
      transform: translateX(-2vw) translateY(41vh) scale(0.78);
    }

    36.8421052632% {
      transform: translateX(-1vw) translateY(25vh) scale(0.69);
    }

    42.1052631579% {
      transform: translateX(-39vw) translateY(-28vh) scale(0.94);
    }

    47.3684210526% {
      transform: translateX(29vw) translateY(13vh) scale(0.42);
    }

    52.6315789474% {
      transform: translateX(42vw) translateY(17vh) scale(0.47);
    }

    57.8947368421% {
      transform: translateX(14vw) translateY(-9vh) scale(0.59);
    }

    63.1578947368% {
      transform: translateX(12vw) translateY(-8vh) scale(0.29);
    }

    68.4210526316% {
      transform: translateX(-31vw) translateY(-25vh) scale(0.81);
    }

    73.6842105263% {
      transform: translateX(-45vw) translateY(13vh) scale(0.33);
    }

    78.9473684211% {
      transform: translateX(-6vw) translateY(-48vh) scale(0.86);
    }

    84.2105263158% {
      transform: translateX(-18vw) translateY(-12vh) scale(0.99);
    }

    89.4736842105% {
      transform: translateX(22vw) translateY(-39vh) scale(0.51);
    }

    94.7368421053% {
      transform: translateX(43vw) translateY(-15vh) scale(0.6);
    }

    100% {
      transform: translateX(41vw) translateY(-14vh) scale(0.56);
    }
  }

  .firefly:nth-child(10) {
    animation-name: move10;
  }

  .firefly:nth-child(10)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(10)::after {
    animation-duration: 15s, 9714ms;
    animation-delay: 0ms, 7928ms;
  }

  @keyframes move10 {
    0% {
      transform: translateX(-5vw) translateY(25vh) scale(0.3);
    }

    3.8461538462% {
      transform: translateX(11vw) translateY(42vh) scale(0.81);
    }

    7.6923076923% {
      transform: translateX(-45vw) translateY(25vh) scale(0.44);
    }

    11.5384615385% {
      transform: translateX(23vw) translateY(-4vh) scale(0.43);
    }

    15.3846153846% {
      transform: translateX(10vw) translateY(12vh) scale(0.84);
    }

    19.2307692308% {
      transform: translateX(-33vw) translateY(38vh) scale(0.95);
    }

    23.0769230769% {
      transform: translateX(15vw) translateY(23vh) scale(0.56);
    }

    26.9230769231% {
      transform: translateX(-12vw) translateY(-17vh) scale(0.42);
    }

    30.7692307692% {
      transform: translateX(1vw) translateY(-34vh) scale(0.61);
    }

    34.6153846154% {
      transform: translateX(22vw) translateY(-25vh) scale(0.32);
    }

    38.4615384615% {
      transform: translateX(-17vw) translateY(26vh) scale(0.65);
    }

    42.3076923077% {
      transform: translateX(3vw) translateY(29vh) scale(0.91);
    }

    46.1538461538% {
      transform: translateX(-12vw) translateY(-36vh) scale(0.64);
    }

    50% {
      transform: translateX(-44vw) translateY(41vh) scale(0.44);
    }

    53.8461538462% {
      transform: translateX(9vw) translateY(-29vh) scale(0.6);
    }

    57.6923076923% {
      transform: translateX(22vw) translateY(30vh) scale(0.98);
    }

    61.5384615385% {
      transform: translateX(-20vw) translateY(33vh) scale(0.7);
    }

    65.3846153846% {
      transform: translateX(40vw) translateY(24vh) scale(0.38);
    }

    69.2307692308% {
      transform: translateX(-19vw) translateY(4vh) scale(0.48);
    }

    73.0769230769% {
      transform: translateX(-18vw) translateY(-32vh) scale(1);
    }

    76.9230769231% {
      transform: translateX(17vw) translateY(1vh) scale(0.68);
    }

    80.7692307692% {
      transform: translateX(-40vw) translateY(-16vh) scale(0.92);
    }

    84.6153846154% {
      transform: translateX(17vw) translateY(32vh) scale(0.9);
    }

    88.4615384615% {
      transform: translateX(14vw) translateY(45vh) scale(0.8);
    }

    92.3076923077% {
      transform: translateX(14vw) translateY(50vh) scale(0.74);
    }

    96.1538461538% {
      transform: translateX(26vw) translateY(47vh) scale(0.83);
    }

    100% {
      transform: translateX(49vw) translateY(-40vh) scale(0.95);
    }
  }

  .firefly:nth-child(11) {
    animation-name: move11;
  }

  .firefly:nth-child(11)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(11)::after {
    animation-duration: 13s, 7476ms;
    animation-delay: 0ms, 5436ms;
  }

  @keyframes move11 {
    0% {
      transform: translateX(-7vw) translateY(16vh) scale(0.42);
    }

    4.1666666667% {
      transform: translateX(-44vw) translateY(14vh) scale(0.89);
    }

    8.3333333333% {
      transform: translateX(17vw) translateY(-25vh) scale(0.72);
    }

    12.5% {
      transform: translateX(-40vw) translateY(-22vh) scale(0.47);
    }

    16.6666666667% {
      transform: translateX(49vw) translateY(12vh) scale(0.93);
    }

    20.8333333333% {
      transform: translateX(-45vw) translateY(21vh) scale(0.6);
    }

    25% {
      transform: translateX(36vw) translateY(-41vh) scale(0.48);
    }

    29.1666666667% {
      transform: translateX(-9vw) translateY(0vh) scale(0.98);
    }

    33.3333333333% {
      transform: translateX(-32vw) translateY(37vh) scale(0.29);
    }

    37.5% {
      transform: translateX(31vw) translateY(-46vh) scale(0.34);
    }

    41.6666666667% {
      transform: translateX(21vw) translateY(-45vh) scale(0.51);
    }

    45.8333333333% {
      transform: translateX(-12vw) translateY(-46vh) scale(0.87);
    }

    50% {
      transform: translateX(25vw) translateY(28vh) scale(0.48);
    }

    54.1666666667% {
      transform: translateX(-16vw) translateY(-25vh) scale(0.76);
    }

    58.3333333333% {
      transform: translateX(-4vw) translateY(48vh) scale(0.63);
    }

    62.5% {
      transform: translateX(48vw) translateY(-32vh) scale(0.31);
    }

    66.6666666667% {
      transform: translateX(-39vw) translateY(-39vh) scale(0.56);
    }

    70.8333333333% {
      transform: translateX(-1vw) translateY(37vh) scale(0.5);
    }

    75% {
      transform: translateX(-9vw) translateY(-5vh) scale(0.56);
    }

    79.1666666667% {
      transform: translateX(-11vw) translateY(13vh) scale(0.96);
    }

    83.3333333333% {
      transform: translateX(-1vw) translateY(-41vh) scale(0.55);
    }

    87.5% {
      transform: translateX(17vw) translateY(18vh) scale(0.58);
    }

    91.6666666667% {
      transform: translateX(-12vw) translateY(10vh) scale(0.99);
    }

    95.8333333333% {
      transform: translateX(33vw) translateY(50vh) scale(0.85);
    }

    100% {
      transform: translateX(43vw) translateY(38vh) scale(0.52);
    }
  }

  .firefly:nth-child(12) {
    animation-name: move12;
  }

  .firefly:nth-child(12)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(12)::after {
    animation-duration: 13s, 5428ms;
    animation-delay: 0ms, 4447ms;
  }

  @keyframes move12 {
    0% {
      transform: translateX(19vw) translateY(-25vh) scale(0.71);
    }

    3.7037037037% {
      transform: translateX(5vw) translateY(-44vh) scale(0.68);
    }

    7.4074074074% {
      transform: translateX(-11vw) translateY(21vh) scale(0.96);
    }

    11.1111111111% {
      transform: translateX(-16vw) translateY(-35vh) scale(0.62);
    }

    14.8148148148% {
      transform: translateX(19vw) translateY(36vh) scale(0.51);
    }

    18.5185185185% {
      transform: translateX(-31vw) translateY(39vh) scale(0.91);
    }

    22.2222222222% {
      transform: translateX(-13vw) translateY(-33vh) scale(0.57);
    }

    25.9259259259% {
      transform: translateX(-11vw) translateY(-43vh) scale(0.91);
    }

    29.6296296296% {
      transform: translateX(6vw) translateY(-23vh) scale(0.35);
    }

    33.3333333333% {
      transform: translateX(50vw) translateY(-5vh) scale(1);
    }

    37.037037037% {
      transform: translateX(-42vw) translateY(0vh) scale(0.98);
    }

    40.7407407407% {
      transform: translateX(35vw) translateY(40vh) scale(0.97);
    }

    44.4444444444% {
      transform: translateX(-6vw) translateY(-30vh) scale(0.28);
    }

    48.1481481481% {
      transform: translateX(7vw) translateY(0vh) scale(0.85);
    }

    51.8518518519% {
      transform: translateX(-29vw) translateY(-42vh) scale(0.82);
    }

    55.5555555556% {
      transform: translateX(-13vw) translateY(-39vh) scale(0.93);
    }

    59.2592592593% {
      transform: translateX(-16vw) translateY(43vh) scale(0.99);
    }

    62.962962963% {
      transform: translateX(19vw) translateY(38vh) scale(0.61);
    }

    66.6666666667% {
      transform: translateX(13vw) translateY(26vh) scale(0.75);
    }

    70.3703703704% {
      transform: translateX(-3vw) translateY(-23vh) scale(0.7);
    }

    74.0740740741% {
      transform: translateX(-19vw) translateY(3vh) scale(0.93);
    }

    77.7777777778% {
      transform: translateX(8vw) translateY(-18vh) scale(0.77);
    }

    81.4814814815% {
      transform: translateX(-23vw) translateY(-12vh) scale(0.88);
    }

    85.1851851852% {
      transform: translateX(29vw) translateY(-27vh) scale(0.75);
    }

    88.8888888889% {
      transform: translateX(-28vw) translateY(-32vh) scale(0.46);
    }

    92.5925925926% {
      transform: translateX(-40vw) translateY(11vh) scale(0.54);
    }

    96.2962962963% {
      transform: translateX(19vw) translateY(3vh) scale(0.86);
    }

    100% {
      transform: translateX(35vw) translateY(-45vh) scale(0.73);
    }
  }

  .firefly:nth-child(13) {
    animation-name: move13;
  }

  .firefly:nth-child(13)::before {
    animation-duration: 12s;
  }

  .firefly:nth-child(13)::after {
    animation-duration: 12s, 10857ms;
    animation-delay: 0ms, 8399ms;
  }

  @keyframes move13 {
    0% {
      transform: translateX(-20vw) translateY(28vh) scale(0.31);
    }

    5.2631578947% {
      transform: translateX(10vw) translateY(-35vh) scale(0.91);
    }

    10.5263157895% {
      transform: translateX(-12vw) translateY(7vh) scale(0.65);
    }

    15.7894736842% {
      transform: translateX(-35vw) translateY(-3vh) scale(0.56);
    }

    21.0526315789% {
      transform: translateX(-24vw) translateY(-16vh) scale(0.35);
    }

    26.3157894737% {
      transform: translateX(0vw) translateY(20vh) scale(0.57);
    }

    31.5789473684% {
      transform: translateX(-45vw) translateY(-3vh) scale(0.77);
    }

    36.8421052632% {
      transform: translateX(37vw) translateY(36vh) scale(0.7);
    }

    42.1052631579% {
      transform: translateX(-9vw) translateY(8vh) scale(0.92);
    }

    47.3684210526% {
      transform: translateX(-14vw) translateY(-41vh) scale(0.29);
    }

    52.6315789474% {
      transform: translateX(-12vw) translateY(-35vh) scale(0.71);
    }

    57.8947368421% {
      transform: translateX(-3vw) translateY(-21vh) scale(0.48);
    }

    63.1578947368% {
      transform: translateX(12vw) translateY(6vh) scale(0.8);
    }

    68.4210526316% {
      transform: translateX(16vw) translateY(-5vh) scale(0.75);
    }

    73.6842105263% {
      transform: translateX(-39vw) translateY(-43vh) scale(0.68);
    }

    78.9473684211% {
      transform: translateX(35vw) translateY(-46vh) scale(0.3);
    }

    84.2105263158% {
      transform: translateX(35vw) translateY(-45vh) scale(0.83);
    }

    89.4736842105% {
      transform: translateX(-8vw) translateY(13vh) scale(0.39);
    }

    94.7368421053% {
      transform: translateX(-12vw) translateY(32vh) scale(0.45);
    }

    100% {
      transform: translateX(-14vw) translateY(42vh) scale(0.67);
    }
  }

  .firefly:nth-child(14) {
    animation-name: move14;
  }

  .firefly:nth-child(14)::before {
    animation-duration: 11s;
  }

  .firefly:nth-child(14)::after {
    animation-duration: 11s, 7829ms;
    animation-delay: 0ms, 5814ms;
  }

  @keyframes move14 {
    0% {
      transform: translateX(10vw) translateY(-18vh) scale(0.99);
    }

    5.8823529412% {
      transform: translateX(7vw) translateY(18vh) scale(0.53);
    }

    11.7647058824% {
      transform: translateX(-38vw) translateY(3vh) scale(0.96);
    }

    17.6470588235% {
      transform: translateX(22vw) translateY(-38vh) scale(0.49);
    }

    23.5294117647% {
      transform: translateX(8vw) translateY(-38vh) scale(0.41);
    }

    29.4117647059% {
      transform: translateX(34vw) translateY(26vh) scale(0.7);
    }

    35.2941176471% {
      transform: translateX(-7vw) translateY(-3vh) scale(0.52);
    }

    41.1764705882% {
      transform: translateX(-43vw) translateY(45vh) scale(0.43);
    }

    47.0588235294% {
      transform: translateX(47vw) translateY(9vh) scale(0.89);
    }

    52.9411764706% {
      transform: translateX(45vw) translateY(32vh) scale(0.77);
    }

    58.8235294118% {
      transform: translateX(8vw) translateY(16vh) scale(0.64);
    }

    64.7058823529% {
      transform: translateX(-45vw) translateY(-38vh) scale(0.92);
    }

    70.5882352941% {
      transform: translateX(-6vw) translateY(-40vh) scale(0.64);
    }

    76.4705882353% {
      transform: translateX(29vw) translateY(-27vh) scale(0.83);
    }

    82.3529411765% {
      transform: translateX(4vw) translateY(10vh) scale(0.86);
    }

    88.2352941176% {
      transform: translateX(-8vw) translateY(43vh) scale(0.91);
    }

    94.1176470588% {
      transform: translateX(33vw) translateY(10vh) scale(0.57);
    }

    100% {
      transform: translateX(-17vw) translateY(14vh) scale(0.83);
    }
  }

  .firefly:nth-child(15) {
    animation-name: move15;
  }

  .firefly:nth-child(15)::before {
    animation-duration: 14s;
  }

  .firefly:nth-child(15)::after {
    animation-duration: 14s, 8455ms;
    animation-delay: 0ms, 1227ms;
  }

  @keyframes move15 {
    0% {
      transform: translateX(12vw) translateY(-1vh) scale(0.28);
    }

    4% {
      transform: translateX(6vw) translateY(-14vh) scale(0.61);
    }

    8% {
      transform: translateX(9vw) translateY(-13vh) scale(0.51);
    }

    12% {
      transform: translateX(26vw) translateY(14vh) scale(0.81);
    }

    16% {
      transform: translateX(-32vw) translateY(7vh) scale(0.99);
    }

    20% {
      transform: translateX(19vw) translateY(10vh) scale(0.27);
    }

    24% {
      transform: translateX(-13vw) translateY(-10vh) scale(0.83);
    }

    28% {
      transform: translateX(-35vw) translateY(-2vh) scale(0.39);
    }

    32% {
      transform: translateX(-28vw) translateY(-13vh) scale(0.82);
    }

    36% {
      transform: translateX(-45vw) translateY(28vh) scale(0.48);
    }

    40% {
      transform: translateX(-5vw) translateY(42vh) scale(0.74);
    }

    44% {
      transform: translateX(-30vw) translateY(22vh) scale(0.44);
    }

    48% {
      transform: translateX(15vw) translateY(-21vh) scale(0.55);
    }

    52% {
      transform: translateX(-6vw) translateY(1vh) scale(0.94);
    }

    56% {
      transform: translateX(-26vw) translateY(13vh) scale(0.26);
    }

    60% {
      transform: translateX(44vw) translateY(-49vh) scale(0.67);
    }

    64% {
      transform: translateX(23vw) translateY(-28vh) scale(0.35);
    }

    68% {
      transform: translateX(35vw) translateY(37vh) scale(0.56);
    }

    72% {
      transform: translateX(-4vw) translateY(43vh) scale(0.76);
    }

    76% {
      transform: translateX(-43vw) translateY(43vh) scale(0.56);
    }

    80% {
      transform: translateX(-12vw) translateY(1vh) scale(0.99);
    }

    84% {
      transform: translateX(-26vw) translateY(3vh) scale(0.3);
    }

    88% {
      transform: translateX(46vw) translateY(-9vh) scale(0.55);
    }

    92% {
      transform: translateX(33vw) translateY(-15vh) scale(0.66);
    }

    96% {
      transform: translateX(19vw) translateY(-3vh) scale(0.59);
    }

    100% {
      transform: translateX(-32vw) translateY(-18vh) scale(0.31);
    }
  }

  @keyframes drift {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes flash {

    0%,
    30%,
    100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw yellow;
    }

    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw yellow;
    }
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    opacity: 0.5;
    transition: 0.5s ease;
    z-index: 1;

  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 80%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 1;
    transition: transform .4s;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .miniBarMain{
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fd2;
    display: flex;
    justify-content: space-between;
  }

  .miniBar{
    display: flex;
    justify-content: space-between;
  }

  .navbarMain{
    padding-right: 30px;
     position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }
  
  .navbar {
    width: 50px;
    justify-content: flex-end;
    cursor: pointer;    
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    z-index: 1000000000000000000;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: rgba(218, 165, 32, 0.685) 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px rgba(49, 49, 49, 0.603) solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
     margin-left: auto;  
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }

  .allWrap {
    background-color: black;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .containerMob {
    display: none;
  }

  .cont {
    background: radial-gradient(circle, rgba(13, 24, 31, 0.2777485994397759) 0%, rgba(13, 24, 31, 0.48783263305322133) 69%, rgba(13, 24, 31, 0.8743872549019608) 100%), url('./assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .in2 p {
    font-size: 16px;
    color: white;
    font-weight: bold;
    text-align: center;
    letter-spacing: 3px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    font-family: "Rise of Kingdom";

  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 75%;
  }

  .introduction {
    padding-top: 30px;
  }

  .container {
    max-width: 430px;
    max-height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
    display: none;
  }

  .container:hover img {
    opacity: 0.3;
  }

  .container img:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  .container img {
    width: 40%;
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
    box-shadow: 0 0 10px #d44e00;
    border: 2px solid #d6985c;
    z-index: 10000;
  }

  .containerMob {
    max-width: 350px;
    max-height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 50px;
    margin-bottom: 80px;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
  }

  .containerMob:hover img {
    opacity: 0.3;
  }

  .containerMob img:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  .containerMob img {
    width: 40%;
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
    box-shadow: 0 0 10px #d44e00;
    border: 2px solid #d6985c;
    z-index: 10000;
  }

  .containerMob div {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }


  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .ag-format-container {
    width: 1142px;
    margin: 0 auto;
  }

  .ag-sparks {
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    position: relative;
  }

  .ag-sparks:before,
  .ag-sparks:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ag-sparks:before {
    content: "";

    -webkit-animation: an-blink 30s linear 3s infinite;
    -moz-animation: an-blink 30s linear 3s infinite;
    -o-animation: an-blink 30s linear 3s infinite;
    animation: an-blink 30s linear 3s infinite;
  }

  .ag-sparks:after {
    content: "";

    z-index: 10;
  }

  .ag-spark {
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    position: relative;
  }

  .ag-spark:before,
  .ag-spark:after {
    content: "";
    background-image: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/sparks/images/spark.png);
    background-position-x: 50%;
    background-repeat: repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    opacity: 0;

    will-change: transform;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ag-spark:before {
    -webkit-animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
    -moz-animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
    -o-animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
    animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
  }

  .ag-spark:after {
    -webkit-animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
    -moz-animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
    -o-animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
    animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
  }

  .ag-lightning {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ag-lightning:before,
  .ag-lightning:after {
    content: "";

    opacity: 0;

    will-change: transform;

    position: absolute;
  }

  .ag-lightning:before {
    width: 272px;
    background-image: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/sparks/images/lightning-1.png);

    top: -9.75em;
    left: 50%;

    -webkit-animation: an-lightning-first 30s linear 3s infinite;
    -moz-animation: an-lightning-first 30s linear 3s infinite;
    -o-animation: an-lightning-first 30s linear 3s infinite;
    animation: an-lightning-first 30s linear 3s infinite;
  }

  .ag-lightning:after {
    width: 254px;
    background-image: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/sparks/images/lightning-1.png);

    top: -10.1875em;
    right: -6.1875em;

    -webkit-animation: an-lightning-second 30s linear 3s infinite;
    -moz-animation: an-lightning-second 30s linear 3s infinite;
    -o-animation: an-lightning-second 30s linear 3s infinite;
    animation: an-lightning-second 30s linear 3s infinite;
  }

  @-webkit-keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @-moz-keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @-o-keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @-webkit-keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @-moz-keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @-o-keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @-webkit-keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @-moz-keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      -moz-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      -moz-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @-o-keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      -o-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      -o-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      -moz-transform: translateX(1.25em);
      -o-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      -moz-transform: translateX(-1.25em);
      -o-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @-webkit-keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @-moz-keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      -moz-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      -moz-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @-o-keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      -o-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      -o-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      -moz-transform: scale(-1, 1) translateX(1.25em);
      -o-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      -moz-transform: scale(-1, 1) translateX(-1.25em);
      -o-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @-webkit-keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @-moz-keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @-o-keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @-moz-keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @-o-keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @media only screen and (max-width: 767px) {
    .ag-format-container {
      width: 96%;
    }

  }

  @media only screen and (max-width: 639px) {}

  @media only screen and (max-width: 479px) {}

  @media (min-width: 768px) and (max-width: 979px) {
    .ag-format-container {
      width: 750px;
    }

  }

  @media (min-width: 980px) and (max-width: 1161px) {
    .ag-format-container {
      width: 960px;
    }

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: linear-gradient(87deg, rgba(0, 0, 0, 0.1956954656862745) 0%, rgba(0, 0, 0, 0.6606814600840336) 35%, rgba(0, 0, 0, 0.8819699754901961) 100%), url('./assets/map.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 60px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyHK {
    font-size: 60px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 60px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .storyH2-2 {
    font-size: 60px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 17px;
    text-align: justify;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 85%;
    margin-right: auto;
    margin-left: auto;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }


  .map {
    width: 75%;
    height: 75%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 22px #704531;
    margin-bottom: 25px;
  }

  .logos {
    width: 55%;
    height: 55%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 22px #704531;
    margin-bottom: 25px;
    display: none;
   }

   .logos_mobile {
    width: 65%;
    height: 65%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 22px #704531;
    margin-bottom: 25px;
  }
 

  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    background: rgba(19, 19, 19, 0.767);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #e2e2e2 2px ridge;
    box-shadow: 0 0 15px #704531;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 85%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .faqbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13126969537815125) 0%, rgba(0, 0, 0, 0.3385526085434174) 35%, rgba(0, 0, 0, 0.7979363620448179) 100%), url('./assets/faqbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .team {
    width: 95%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .teambg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13126969537815125) 0%, rgba(0, 0, 0, 0.3385526085434174) 35%, rgba(0, 0, 0, 0.7979363620448179) 100%), url('./assets/teambg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
  }

  .kingdomsBg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
    padding-top: 50px;
  }

  .kingdomsSection1 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/lionKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection2 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/raakshaKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection3 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/snakeKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection4 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/eagleKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection5 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/dragonKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .logoF {
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #ff0, 0 -10px 20px #ff8000, 0 -18px 40px #F00;
    color: white;
    font-size: 45px
  }

  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(250, 219, 207);
    text-align: center;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
   }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fd2;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connect div:hover {
    color: rgb(255, 185, 138);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    transition: transform .2s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 18px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;

  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #99faf500;
    font-size: 17px;
    padding: 10px;
    width: 190px;
    border: 2px rgb(216, 179, 130) solid;
    color: rgb(255, 255, 255);
    box-shadow: 0 0 10px #b18a41;
    transition: 0.3s ease;
    cursor: none;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 78px;
    height: 78px;
  }

  .downImg {
    width: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  /*........................Roadmap........................*/

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 40px;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #eeb38b;
    color: white;
    font-family: "Rise of Kingdom";
    margin-left: -40px;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 19px;
    margin-left: -40px;
    width: 300px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #fff;
    position: absolute;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #f2b14b;
    left: 41px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    border: 5px solid rgba(63, 63, 63, 0.253);
    left: 41px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #f2b14b;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #f2b14b;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid rgba(63, 63, 63, 0.253);

  }

  .footPrint2 {
    width: 50px;
    height: 50px;
  }

  .rmbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13126969537815125) 0%, rgba(0, 0, 0, 0.3385526085434174) 35%, rgba(0, 0, 0, 0.7979363620448179) 100%), url('./assets/rmbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  h4 {
    text-transform: capitalize;
    background-color: rgba(0, 0, 0, 0.384);
    color: white;
    padding: 10px;
    box-shadow: 0 0 15px #f2b24b62;
    font-size: 20px;
    margin-left: -40px;
    width: 330px;
  }

  .fire {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .fireGlow{
    animation: glow 2s ease-in-out infinite alternate;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .firefly {
    position: sticky;
    position: -webkit-sticky;
    left: 50%;
    top: 50%;
    width: 0.4vw;
    height: 0.4vw;
    margin: -0.2vw 0 0 9.8vw;
    animation: ease 200s alternate infinite;
    pointer-events: none;
  }

  .firefly::before,
  .firefly::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: -10vw;
  }

  .firefly::before {
    background: black;
    opacity: 0.4;
    animation: drift ease alternate infinite;
  }

  .firefly::after {
    background: white;
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow;
    animation: drift ease alternate infinite, flash ease infinite;
  }

  .firefly:nth-child(1) {
    animation-name: move1;
  }

  .firefly:nth-child(1)::before {
    animation-duration: 10s;
  }

  .firefly:nth-child(1)::after {
    animation-duration: 10s, 5869ms;
    animation-delay: 0ms, 5103ms;
  }

  @keyframes move1 {
    0% {
      transform: translateX(-41vw) translateY(23vh) scale(0.42);
    }

    5% {
      transform: translateX(9vw) translateY(-45vh) scale(0.34);
    }

    10% {
      transform: translateX(-17vw) translateY(-42vh) scale(1);
    }

    15% {
      transform: translateX(8vw) translateY(-23vh) scale(0.51);
    }

    20% {
      transform: translateX(-29vw) translateY(-20vh) scale(0.56);
    }

    25% {
      transform: translateX(8vw) translateY(16vh) scale(0.79);
    }

    30% {
      transform: translateX(11vw) translateY(46vh) scale(0.54);
    }

    35% {
      transform: translateX(9vw) translateY(-45vh) scale(0.58);
    }

    40% {
      transform: translateX(10vw) translateY(-37vh) scale(0.69);
    }

    45% {
      transform: translateX(5vw) translateY(26vh) scale(0.52);
    }

    50% {
      transform: translateX(-36vw) translateY(-39vh) scale(0.43);
    }

    55% {
      transform: translateX(-45vw) translateY(35vh) scale(0.96);
    }

    60% {
      transform: translateX(17vw) translateY(38vh) scale(0.66);
    }

    65% {
      transform: translateX(-39vw) translateY(-34vh) scale(0.75);
    }

    70% {
      transform: translateX(-45vw) translateY(18vh) scale(0.72);
    }

    75% {
      transform: translateX(48vw) translateY(21vh) scale(0.42);
    }

    80% {
      transform: translateX(28vw) translateY(-2vh) scale(0.82);
    }

    85% {
      transform: translateX(-29vw) translateY(30vh) scale(0.48);
    }

    90% {
      transform: translateX(12vw) translateY(16vh) scale(0.42);
    }

    95% {
      transform: translateX(-8vw) translateY(-24vh) scale(0.52);
    }

    100% {
      transform: translateX(-49vw) translateY(6vh) scale(0.34);
    }
  }

  .firefly:nth-child(2) {
    animation-name: move2;
  }

  .firefly:nth-child(2)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(2)::after {
    animation-duration: 13s, 8622ms;
    animation-delay: 0ms, 6050ms;
  }

  @keyframes move2 {
    0% {
      transform: translateX(-13vw) translateY(17vh) scale(0.94);
    }

    4.347826087% {
      transform: translateX(12vw) translateY(24vh) scale(0.48);
    }

    8.6956521739% {
      transform: translateX(16vw) translateY(-40vh) scale(0.39);
    }

    13.0434782609% {
      transform: translateX(22vw) translateY(22vh) scale(0.68);
    }

    17.3913043478% {
      transform: translateX(19vw) translateY(-14vh) scale(0.42);
    }

    21.7391304348% {
      transform: translateX(2vw) translateY(-18vh) scale(0.3);
    }

    26.0869565217% {
      transform: translateX(2vw) translateY(38vh) scale(0.29);
    }

    30.4347826087% {
      transform: translateX(28vw) translateY(6vh) scale(0.87);
    }

    34.7826086957% {
      transform: translateX(-48vw) translateY(21vh) scale(0.88);
    }

    39.1304347826% {
      transform: translateX(32vw) translateY(15vh) scale(0.76);
    }

    43.4782608696% {
      transform: translateX(32vw) translateY(1vh) scale(0.31);
    }

    47.8260869565% {
      transform: translateX(35vw) translateY(0vh) scale(0.29);
    }

    52.1739130435% {
      transform: translateX(9vw) translateY(14vh) scale(0.27);
    }

    56.5217391304% {
      transform: translateX(12vw) translateY(-20vh) scale(0.64);
    }

    60.8695652174% {
      transform: translateX(-21vw) translateY(27vh) scale(0.72);
    }

    65.2173913043% {
      transform: translateX(-47vw) translateY(-33vh) scale(0.31);
    }

    69.5652173913% {
      transform: translateX(-25vw) translateY(7vh) scale(0.85);
    }

    73.9130434783% {
      transform: translateX(42vw) translateY(38vh) scale(0.88);
    }

    78.2608695652% {
      transform: translateX(11vw) translateY(10vh) scale(0.91);
    }

    82.6086956522% {
      transform: translateX(2vw) translateY(6vh) scale(0.59);
    }

    86.9565217391% {
      transform: translateX(37vw) translateY(4vh) scale(0.59);
    }

    91.3043478261% {
      transform: translateX(-34vw) translateY(-32vh) scale(0.95);
    }

    95.652173913% {
      transform: translateX(-47vw) translateY(32vh) scale(0.81);
    }

    100% {
      transform: translateX(32vw) translateY(22vh) scale(0.53);
    }
  }

  .firefly:nth-child(3) {
    animation-name: move3;
  }

  .firefly:nth-child(3)::before {
    animation-duration: 14s;
  }

  .firefly:nth-child(3)::after {
    animation-duration: 14s, 7972ms;
    animation-delay: 0ms, 2219ms;
  }

  @keyframes move3 {
    0% {
      transform: translateX(-9vw) translateY(-12vh) scale(0.35);
    }

    4% {
      transform: translateX(9vw) translateY(45vh) scale(0.75);
    }

    8% {
      transform: translateX(37vw) translateY(-35vh) scale(0.42);
    }

    12% {
      transform: translateX(-49vw) translateY(5vh) scale(0.46);
    }

    16% {
      transform: translateX(42vw) translateY(26vh) scale(0.34);
    }

    20% {
      transform: translateX(-12vw) translateY(19vh) scale(0.84);
    }

    24% {
      transform: translateX(23vw) translateY(6vh) scale(0.45);
    }

    28% {
      transform: translateX(18vw) translateY(-16vh) scale(0.47);
    }

    32% {
      transform: translateX(22vw) translateY(-40vh) scale(0.45);
    }

    36% {
      transform: translateX(-37vw) translateY(33vh) scale(0.75);
    }

    40% {
      transform: translateX(44vw) translateY(33vh) scale(0.61);
    }

    44% {
      transform: translateX(-44vw) translateY(-18vh) scale(0.57);
    }

    48% {
      transform: translateX(-9vw) translateY(39vh) scale(0.45);
    }

    52% {
      transform: translateX(10vw) translateY(41vh) scale(0.51);
    }

    56% {
      transform: translateX(23vw) translateY(-41vh) scale(0.31);
    }

    60% {
      transform: translateX(48vw) translateY(-5vh) scale(0.33);
    }

    64% {
      transform: translateX(-49vw) translateY(-43vh) scale(0.3);
    }

    68% {
      transform: translateX(-39vw) translateY(5vh) scale(0.44);
    }

    72% {
      transform: translateX(-18vw) translateY(-28vh) scale(0.94);
    }

    76% {
      transform: translateX(22vw) translateY(-18vh) scale(0.67);
    }

    80% {
      transform: translateX(10vw) translateY(-32vh) scale(0.56);
    }

    84% {
      transform: translateX(-7vw) translateY(-48vh) scale(0.47);
    }

    88% {
      transform: translateX(30vw) translateY(37vh) scale(0.64);
    }

    92% {
      transform: translateX(-46vw) translateY(-37vh) scale(0.53);
    }

    96% {
      transform: translateX(9vw) translateY(40vh) scale(0.87);
    }

    100% {
      transform: translateX(-48vw) translateY(-17vh) scale(0.46);
    }
  }

  .firefly:nth-child(4) {
    animation-name: move4;
  }

  .firefly:nth-child(4)::before {
    animation-duration: 10s;
  }

  .firefly:nth-child(4)::after {
    animation-duration: 10s, 8866ms;
    animation-delay: 0ms, 4122ms;
  }

  @keyframes move4 {
    0% {
      transform: translateX(-1vw) translateY(17vh) scale(0.76);
    }

    5.5555555556% {
      transform: translateX(-20vw) translateY(-6vh) scale(0.96);
    }

    11.1111111111% {
      transform: translateX(7vw) translateY(27vh) scale(0.44);
    }

    16.6666666667% {
      transform: translateX(1vw) translateY(32vh) scale(0.75);
    }

    22.2222222222% {
      transform: translateX(48vw) translateY(-14vh) scale(0.83);
    }

    27.7777777778% {
      transform: translateX(-34vw) translateY(-30vh) scale(0.6);
    }

    33.3333333333% {
      transform: translateX(40vw) translateY(-27vh) scale(1);
    }

    38.8888888889% {
      transform: translateX(-14vw) translateY(-28vh) scale(0.29);
    }

    44.4444444444% {
      transform: translateX(37vw) translateY(-14vh) scale(0.56);
    }

    50% {
      transform: translateX(29vw) translateY(8vh) scale(0.37);
    }

    55.5555555556% {
      transform: translateX(-14vw) translateY(21vh) scale(0.39);
    }

    61.1111111111% {
      transform: translateX(-1vw) translateY(38vh) scale(0.64);
    }

    66.6666666667% {
      transform: translateX(40vw) translateY(-17vh) scale(0.78);
    }

    72.2222222222% {
      transform: translateX(-6vw) translateY(-45vh) scale(0.54);
    }

    77.7777777778% {
      transform: translateX(-47vw) translateY(-46vh) scale(0.38);
    }

    83.3333333333% {
      transform: translateX(38vw) translateY(-13vh) scale(0.86);
    }

    88.8888888889% {
      transform: translateX(-8vw) translateY(35vh) scale(0.39);
    }

    94.4444444444% {
      transform: translateX(-25vw) translateY(-1vh) scale(0.44);
    }

    100% {
      transform: translateX(0vw) translateY(-34vh) scale(0.57);
    }
  }

  .firefly:nth-child(5) {
    animation-name: move5;
  }

  .firefly:nth-child(5)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(5)::after {
    animation-duration: 18s, 6640ms;
    animation-delay: 0ms, 1937ms;
  }

  @keyframes move5 {
    0% {
      transform: translateX(33vw) translateY(-14vh) scale(0.83);
    }

    4.5454545455% {
      transform: translateX(-14vw) translateY(6vh) scale(0.26);
    }

    9.0909090909% {
      transform: translateX(-15vw) translateY(-12vh) scale(0.56);
    }

    13.6363636364% {
      transform: translateX(39vw) translateY(20vh) scale(0.69);
    }

    18.1818181818% {
      transform: translateX(-21vw) translateY(-1vh) scale(0.27);
    }

    22.7272727273% {
      transform: translateX(-38vw) translateY(47vh) scale(0.43);
    }

    27.2727272727% {
      transform: translateX(10vw) translateY(-4vh) scale(0.53);
    }

    31.8181818182% {
      transform: translateX(-13vw) translateY(21vh) scale(0.88);
    }

    36.3636363636% {
      transform: translateX(-3vw) translateY(46vh) scale(0.76);
    }

    40.9090909091% {
      transform: translateX(-2vw) translateY(-11vh) scale(0.32);
    }

    45.4545454545% {
      transform: translateX(14vw) translateY(4vh) scale(0.32);
    }

    50% {
      transform: translateX(8vw) translateY(8vh) scale(0.76);
    }

    54.5454545455% {
      transform: translateX(18vw) translateY(-35vh) scale(0.6);
    }

    59.0909090909% {
      transform: translateX(15vw) translateY(-43vh) scale(0.28);
    }

    63.6363636364% {
      transform: translateX(-12vw) translateY(12vh) scale(0.68);
    }

    68.1818181818% {
      transform: translateX(5vw) translateY(25vh) scale(0.96);
    }

    72.7272727273% {
      transform: translateX(-21vw) translateY(-35vh) scale(0.4);
    }

    77.2727272727% {
      transform: translateX(-44vw) translateY(7vh) scale(0.98);
    }

    81.8181818182% {
      transform: translateX(40vw) translateY(-29vh) scale(0.91);
    }

    86.3636363636% {
      transform: translateX(30vw) translateY(-36vh) scale(0.49);
    }

    90.9090909091% {
      transform: translateX(48vw) translateY(22vh) scale(0.67);
    }

    95.4545454545% {
      transform: translateX(18vw) translateY(-10vh) scale(0.77);
    }

    100% {
      transform: translateX(-40vw) translateY(-6vh) scale(0.91);
    }
  }

  .firefly:nth-child(6) {
    animation-name: move6;
  }

  .firefly:nth-child(6)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(6)::after {
    animation-duration: 15s, 8079ms;
    animation-delay: 0ms, 6703ms;
  }

  @keyframes move6 {
    0% {
      transform: translateX(7vw) translateY(-40vh) scale(0.74);
    }

    4.7619047619% {
      transform: translateX(-23vw) translateY(32vh) scale(0.7);
    }

    9.5238095238% {
      transform: translateX(-9vw) translateY(0vh) scale(0.34);
    }

    14.2857142857% {
      transform: translateX(-36vw) translateY(18vh) scale(0.65);
    }

    19.0476190476% {
      transform: translateX(37vw) translateY(-8vh) scale(0.84);
    }

    23.8095238095% {
      transform: translateX(7vw) translateY(27vh) scale(0.44);
    }

    28.5714285714% {
      transform: translateX(-3vw) translateY(8vh) scale(0.93);
    }

    33.3333333333% {
      transform: translateX(-18vw) translateY(5vh) scale(0.87);
    }

    38.0952380952% {
      transform: translateX(25vw) translateY(19vh) scale(0.45);
    }

    42.8571428571% {
      transform: translateX(46vw) translateY(14vh) scale(0.95);
    }

    47.619047619% {
      transform: translateX(13vw) translateY(-10vh) scale(0.5);
    }

    52.380952381% {
      transform: translateX(15vw) translateY(11vh) scale(0.4);
    }

    57.1428571429% {
      transform: translateX(15vw) translateY(-35vh) scale(0.96);
    }

    61.9047619048% {
      transform: translateX(-13vw) translateY(41vh) scale(0.35);
    }

    66.6666666667% {
      transform: translateX(-23vw) translateY(26vh) scale(0.9);
    }

    71.4285714286% {
      transform: translateX(-7vw) translateY(4vh) scale(0.81);
    }

    76.1904761905% {
      transform: translateX(-49vw) translateY(-18vh) scale(0.84);
    }

    80.9523809524% {
      transform: translateX(26vw) translateY(33vh) scale(0.43);
    }

    85.7142857143% {
      transform: translateX(-6vw) translateY(23vh) scale(0.48);
    }

    90.4761904762% {
      transform: translateX(46vw) translateY(-5vh) scale(0.8);
    }

    95.2380952381% {
      transform: translateX(42vw) translateY(33vh) scale(0.6);
    }

    100% {
      transform: translateX(-28vw) translateY(26vh) scale(0.64);
    }
  }

  .firefly:nth-child(7) {
    animation-name: move7;
  }

  .firefly:nth-child(7)::before {
    animation-duration: 17s;
  }

  .firefly:nth-child(7)::after {
    animation-duration: 17s, 9827ms;
    animation-delay: 0ms, 3278ms;
  }

  @keyframes move7 {
    0% {
      transform: translateX(-1vw) translateY(41vh) scale(0.51);
    }

    5.5555555556% {
      transform: translateX(38vw) translateY(32vh) scale(0.61);
    }

    11.1111111111% {
      transform: translateX(36vw) translateY(44vh) scale(0.89);
    }

    16.6666666667% {
      transform: translateX(-22vw) translateY(-36vh) scale(0.68);
    }

    22.2222222222% {
      transform: translateX(4vw) translateY(-2vh) scale(0.87);
    }

    27.7777777778% {
      transform: translateX(11vw) translateY(-5vh) scale(0.48);
    }

    33.3333333333% {
      transform: translateX(-30vw) translateY(28vh) scale(0.43);
    }

    38.8888888889% {
      transform: translateX(-38vw) translateY(-4vh) scale(0.84);
    }

    44.4444444444% {
      transform: translateX(-29vw) translateY(33vh) scale(0.43);
    }

    50% {
      transform: translateX(1vw) translateY(-4vh) scale(0.96);
    }

    55.5555555556% {
      transform: translateX(38vw) translateY(-23vh) scale(0.5);
    }

    61.1111111111% {
      transform: translateX(-47vw) translateY(-1vh) scale(0.91);
    }

    66.6666666667% {
      transform: translateX(40vw) translateY(-8vh) scale(0.52);
    }

    72.2222222222% {
      transform: translateX(26vw) translateY(14vh) scale(0.83);
    }

    77.7777777778% {
      transform: translateX(17vw) translateY(-48vh) scale(0.97);
    }

    83.3333333333% {
      transform: translateX(-22vw) translateY(45vh) scale(0.85);
    }

    88.8888888889% {
      transform: translateX(-21vw) translateY(-9vh) scale(0.45);
    }

    94.4444444444% {
      transform: translateX(39vw) translateY(38vh) scale(0.59);
    }

    100% {
      transform: translateX(-37vw) translateY(-2vh) scale(0.55);
    }
  }

  .firefly:nth-child(8) {
    animation-name: move8;
  }

  .firefly:nth-child(8)::before {
    animation-duration: 9s;
  }

  .firefly:nth-child(8)::after {
    animation-duration: 9s, 8380ms;
    animation-delay: 0ms, 4603ms;
  }

  @keyframes move8 {
    0% {
      transform: translateX(-17vw) translateY(30vh) scale(0.58);
    }

    5.8823529412% {
      transform: translateX(-13vw) translateY(-42vh) scale(0.82);
    }

    11.7647058824% {
      transform: translateX(-10vw) translateY(-38vh) scale(0.86);
    }

    17.6470588235% {
      transform: translateX(49vw) translateY(47vh) scale(0.97);
    }

    23.5294117647% {
      transform: translateX(-17vw) translateY(-44vh) scale(1);
    }

    29.4117647059% {
      transform: translateX(-6vw) translateY(-20vh) scale(0.5);
    }

    35.2941176471% {
      transform: translateX(-39vw) translateY(-34vh) scale(0.33);
    }

    41.1764705882% {
      transform: translateX(38vw) translateY(49vh) scale(0.45);
    }

    47.0588235294% {
      transform: translateX(43vw) translateY(39vh) scale(0.43);
    }

    52.9411764706% {
      transform: translateX(46vw) translateY(-4vh) scale(0.27);
    }

    58.8235294118% {
      transform: translateX(-16vw) translateY(-20vh) scale(0.71);
    }

    64.7058823529% {
      transform: translateX(-43vw) translateY(-40vh) scale(0.84);
    }

    70.5882352941% {
      transform: translateX(-10vw) translateY(13vh) scale(0.81);
    }

    76.4705882353% {
      transform: translateX(38vw) translateY(1vh) scale(0.36);
    }

    82.3529411765% {
      transform: translateX(32vw) translateY(-40vh) scale(0.71);
    }

    88.2352941176% {
      transform: translateX(0vw) translateY(-49vh) scale(0.32);
    }

    94.1176470588% {
      transform: translateX(12vw) translateY(-49vh) scale(0.29);
    }

    100% {
      transform: translateX(7vw) translateY(-3vh) scale(0.94);
    }
  }

  .firefly:nth-child(9) {
    animation-name: move9;
  }

  .firefly:nth-child(9)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(9)::after {
    animation-duration: 18s, 10823ms;
    animation-delay: 0ms, 705ms;
  }

  @keyframes move9 {
    0% {
      transform: translateX(3vw) translateY(4vh) scale(0.41);
    }

    5.2631578947% {
      transform: translateX(-43vw) translateY(40vh) scale(0.79);
    }

    10.5263157895% {
      transform: translateX(-39vw) translateY(22vh) scale(0.38);
    }

    15.7894736842% {
      transform: translateX(-32vw) translateY(1vh) scale(0.41);
    }

    21.0526315789% {
      transform: translateX(-33vw) translateY(31vh) scale(0.81);
    }

    26.3157894737% {
      transform: translateX(2vw) translateY(-33vh) scale(0.43);
    }

    31.5789473684% {
      transform: translateX(-2vw) translateY(41vh) scale(0.78);
    }

    36.8421052632% {
      transform: translateX(-1vw) translateY(25vh) scale(0.69);
    }

    42.1052631579% {
      transform: translateX(-39vw) translateY(-28vh) scale(0.94);
    }

    47.3684210526% {
      transform: translateX(29vw) translateY(13vh) scale(0.42);
    }

    52.6315789474% {
      transform: translateX(42vw) translateY(17vh) scale(0.47);
    }

    57.8947368421% {
      transform: translateX(14vw) translateY(-9vh) scale(0.59);
    }

    63.1578947368% {
      transform: translateX(12vw) translateY(-8vh) scale(0.29);
    }

    68.4210526316% {
      transform: translateX(-31vw) translateY(-25vh) scale(0.81);
    }

    73.6842105263% {
      transform: translateX(-45vw) translateY(13vh) scale(0.33);
    }

    78.9473684211% {
      transform: translateX(-6vw) translateY(-48vh) scale(0.86);
    }

    84.2105263158% {
      transform: translateX(-18vw) translateY(-12vh) scale(0.99);
    }

    89.4736842105% {
      transform: translateX(22vw) translateY(-39vh) scale(0.51);
    }

    94.7368421053% {
      transform: translateX(43vw) translateY(-15vh) scale(0.6);
    }

    100% {
      transform: translateX(41vw) translateY(-14vh) scale(0.56);
    }
  }

  .firefly:nth-child(10) {
    animation-name: move10;
  }

  .firefly:nth-child(10)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(10)::after {
    animation-duration: 15s, 9714ms;
    animation-delay: 0ms, 7928ms;
  }

  @keyframes move10 {
    0% {
      transform: translateX(-5vw) translateY(25vh) scale(0.3);
    }

    3.8461538462% {
      transform: translateX(11vw) translateY(42vh) scale(0.81);
    }

    7.6923076923% {
      transform: translateX(-45vw) translateY(25vh) scale(0.44);
    }

    11.5384615385% {
      transform: translateX(23vw) translateY(-4vh) scale(0.43);
    }

    15.3846153846% {
      transform: translateX(10vw) translateY(12vh) scale(0.84);
    }

    19.2307692308% {
      transform: translateX(-33vw) translateY(38vh) scale(0.95);
    }

    23.0769230769% {
      transform: translateX(15vw) translateY(23vh) scale(0.56);
    }

    26.9230769231% {
      transform: translateX(-12vw) translateY(-17vh) scale(0.42);
    }

    30.7692307692% {
      transform: translateX(1vw) translateY(-34vh) scale(0.61);
    }

    34.6153846154% {
      transform: translateX(22vw) translateY(-25vh) scale(0.32);
    }

    38.4615384615% {
      transform: translateX(-17vw) translateY(26vh) scale(0.65);
    }

    42.3076923077% {
      transform: translateX(3vw) translateY(29vh) scale(0.91);
    }

    46.1538461538% {
      transform: translateX(-12vw) translateY(-36vh) scale(0.64);
    }

    50% {
      transform: translateX(-44vw) translateY(41vh) scale(0.44);
    }

    53.8461538462% {
      transform: translateX(9vw) translateY(-29vh) scale(0.6);
    }

    57.6923076923% {
      transform: translateX(22vw) translateY(30vh) scale(0.98);
    }

    61.5384615385% {
      transform: translateX(-20vw) translateY(33vh) scale(0.7);
    }

    65.3846153846% {
      transform: translateX(40vw) translateY(24vh) scale(0.38);
    }

    69.2307692308% {
      transform: translateX(-19vw) translateY(4vh) scale(0.48);
    }

    73.0769230769% {
      transform: translateX(-18vw) translateY(-32vh) scale(1);
    }

    76.9230769231% {
      transform: translateX(17vw) translateY(1vh) scale(0.68);
    }

    80.7692307692% {
      transform: translateX(-40vw) translateY(-16vh) scale(0.92);
    }

    84.6153846154% {
      transform: translateX(17vw) translateY(32vh) scale(0.9);
    }

    88.4615384615% {
      transform: translateX(14vw) translateY(45vh) scale(0.8);
    }

    92.3076923077% {
      transform: translateX(14vw) translateY(50vh) scale(0.74);
    }

    96.1538461538% {
      transform: translateX(26vw) translateY(47vh) scale(0.83);
    }

    100% {
      transform: translateX(49vw) translateY(-40vh) scale(0.95);
    }
  }

  .firefly:nth-child(11) {
    animation-name: move11;
  }

  .firefly:nth-child(11)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(11)::after {
    animation-duration: 13s, 7476ms;
    animation-delay: 0ms, 5436ms;
  }

  @keyframes move11 {
    0% {
      transform: translateX(-7vw) translateY(16vh) scale(0.42);
    }

    4.1666666667% {
      transform: translateX(-44vw) translateY(14vh) scale(0.89);
    }

    8.3333333333% {
      transform: translateX(17vw) translateY(-25vh) scale(0.72);
    }

    12.5% {
      transform: translateX(-40vw) translateY(-22vh) scale(0.47);
    }

    16.6666666667% {
      transform: translateX(49vw) translateY(12vh) scale(0.93);
    }

    20.8333333333% {
      transform: translateX(-45vw) translateY(21vh) scale(0.6);
    }

    25% {
      transform: translateX(36vw) translateY(-41vh) scale(0.48);
    }

    29.1666666667% {
      transform: translateX(-9vw) translateY(0vh) scale(0.98);
    }

    33.3333333333% {
      transform: translateX(-32vw) translateY(37vh) scale(0.29);
    }

    37.5% {
      transform: translateX(31vw) translateY(-46vh) scale(0.34);
    }

    41.6666666667% {
      transform: translateX(21vw) translateY(-45vh) scale(0.51);
    }

    45.8333333333% {
      transform: translateX(-12vw) translateY(-46vh) scale(0.87);
    }

    50% {
      transform: translateX(25vw) translateY(28vh) scale(0.48);
    }

    54.1666666667% {
      transform: translateX(-16vw) translateY(-25vh) scale(0.76);
    }

    58.3333333333% {
      transform: translateX(-4vw) translateY(48vh) scale(0.63);
    }

    62.5% {
      transform: translateX(48vw) translateY(-32vh) scale(0.31);
    }

    66.6666666667% {
      transform: translateX(-39vw) translateY(-39vh) scale(0.56);
    }

    70.8333333333% {
      transform: translateX(-1vw) translateY(37vh) scale(0.5);
    }

    75% {
      transform: translateX(-9vw) translateY(-5vh) scale(0.56);
    }

    79.1666666667% {
      transform: translateX(-11vw) translateY(13vh) scale(0.96);
    }

    83.3333333333% {
      transform: translateX(-1vw) translateY(-41vh) scale(0.55);
    }

    87.5% {
      transform: translateX(17vw) translateY(18vh) scale(0.58);
    }

    91.6666666667% {
      transform: translateX(-12vw) translateY(10vh) scale(0.99);
    }

    95.8333333333% {
      transform: translateX(33vw) translateY(50vh) scale(0.85);
    }

    100% {
      transform: translateX(43vw) translateY(38vh) scale(0.52);
    }
  }

  .firefly:nth-child(12) {
    animation-name: move12;
  }

  .firefly:nth-child(12)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(12)::after {
    animation-duration: 13s, 5428ms;
    animation-delay: 0ms, 4447ms;
  }

  @keyframes move12 {
    0% {
      transform: translateX(19vw) translateY(-25vh) scale(0.71);
    }

    3.7037037037% {
      transform: translateX(5vw) translateY(-44vh) scale(0.68);
    }

    7.4074074074% {
      transform: translateX(-11vw) translateY(21vh) scale(0.96);
    }

    11.1111111111% {
      transform: translateX(-16vw) translateY(-35vh) scale(0.62);
    }

    14.8148148148% {
      transform: translateX(19vw) translateY(36vh) scale(0.51);
    }

    18.5185185185% {
      transform: translateX(-31vw) translateY(39vh) scale(0.91);
    }

    22.2222222222% {
      transform: translateX(-13vw) translateY(-33vh) scale(0.57);
    }

    25.9259259259% {
      transform: translateX(-11vw) translateY(-43vh) scale(0.91);
    }

    29.6296296296% {
      transform: translateX(6vw) translateY(-23vh) scale(0.35);
    }

    33.3333333333% {
      transform: translateX(50vw) translateY(-5vh) scale(1);
    }

    37.037037037% {
      transform: translateX(-42vw) translateY(0vh) scale(0.98);
    }

    40.7407407407% {
      transform: translateX(35vw) translateY(40vh) scale(0.97);
    }

    44.4444444444% {
      transform: translateX(-6vw) translateY(-30vh) scale(0.28);
    }

    48.1481481481% {
      transform: translateX(7vw) translateY(0vh) scale(0.85);
    }

    51.8518518519% {
      transform: translateX(-29vw) translateY(-42vh) scale(0.82);
    }

    55.5555555556% {
      transform: translateX(-13vw) translateY(-39vh) scale(0.93);
    }

    59.2592592593% {
      transform: translateX(-16vw) translateY(43vh) scale(0.99);
    }

    62.962962963% {
      transform: translateX(19vw) translateY(38vh) scale(0.61);
    }

    66.6666666667% {
      transform: translateX(13vw) translateY(26vh) scale(0.75);
    }

    70.3703703704% {
      transform: translateX(-3vw) translateY(-23vh) scale(0.7);
    }

    74.0740740741% {
      transform: translateX(-19vw) translateY(3vh) scale(0.93);
    }

    77.7777777778% {
      transform: translateX(8vw) translateY(-18vh) scale(0.77);
    }

    81.4814814815% {
      transform: translateX(-23vw) translateY(-12vh) scale(0.88);
    }

    85.1851851852% {
      transform: translateX(29vw) translateY(-27vh) scale(0.75);
    }

    88.8888888889% {
      transform: translateX(-28vw) translateY(-32vh) scale(0.46);
    }

    92.5925925926% {
      transform: translateX(-40vw) translateY(11vh) scale(0.54);
    }

    96.2962962963% {
      transform: translateX(19vw) translateY(3vh) scale(0.86);
    }

    100% {
      transform: translateX(35vw) translateY(-45vh) scale(0.73);
    }
  }

  .firefly:nth-child(13) {
    animation-name: move13;
  }

  .firefly:nth-child(13)::before {
    animation-duration: 12s;
  }

  .firefly:nth-child(13)::after {
    animation-duration: 12s, 10857ms;
    animation-delay: 0ms, 8399ms;
  }

  @keyframes move13 {
    0% {
      transform: translateX(-20vw) translateY(28vh) scale(0.31);
    }

    5.2631578947% {
      transform: translateX(10vw) translateY(-35vh) scale(0.91);
    }

    10.5263157895% {
      transform: translateX(-12vw) translateY(7vh) scale(0.65);
    }

    15.7894736842% {
      transform: translateX(-35vw) translateY(-3vh) scale(0.56);
    }

    21.0526315789% {
      transform: translateX(-24vw) translateY(-16vh) scale(0.35);
    }

    26.3157894737% {
      transform: translateX(0vw) translateY(20vh) scale(0.57);
    }

    31.5789473684% {
      transform: translateX(-45vw) translateY(-3vh) scale(0.77);
    }

    36.8421052632% {
      transform: translateX(37vw) translateY(36vh) scale(0.7);
    }

    42.1052631579% {
      transform: translateX(-9vw) translateY(8vh) scale(0.92);
    }

    47.3684210526% {
      transform: translateX(-14vw) translateY(-41vh) scale(0.29);
    }

    52.6315789474% {
      transform: translateX(-12vw) translateY(-35vh) scale(0.71);
    }

    57.8947368421% {
      transform: translateX(-3vw) translateY(-21vh) scale(0.48);
    }

    63.1578947368% {
      transform: translateX(12vw) translateY(6vh) scale(0.8);
    }

    68.4210526316% {
      transform: translateX(16vw) translateY(-5vh) scale(0.75);
    }

    73.6842105263% {
      transform: translateX(-39vw) translateY(-43vh) scale(0.68);
    }

    78.9473684211% {
      transform: translateX(35vw) translateY(-46vh) scale(0.3);
    }

    84.2105263158% {
      transform: translateX(35vw) translateY(-45vh) scale(0.83);
    }

    89.4736842105% {
      transform: translateX(-8vw) translateY(13vh) scale(0.39);
    }

    94.7368421053% {
      transform: translateX(-12vw) translateY(32vh) scale(0.45);
    }

    100% {
      transform: translateX(-14vw) translateY(42vh) scale(0.67);
    }
  }

  .firefly:nth-child(14) {
    animation-name: move14;
  }

  .firefly:nth-child(14)::before {
    animation-duration: 11s;
  }

  .firefly:nth-child(14)::after {
    animation-duration: 11s, 7829ms;
    animation-delay: 0ms, 5814ms;
  }

  @keyframes move14 {
    0% {
      transform: translateX(10vw) translateY(-18vh) scale(0.99);
    }

    5.8823529412% {
      transform: translateX(7vw) translateY(18vh) scale(0.53);
    }

    11.7647058824% {
      transform: translateX(-38vw) translateY(3vh) scale(0.96);
    }

    17.6470588235% {
      transform: translateX(22vw) translateY(-38vh) scale(0.49);
    }

    23.5294117647% {
      transform: translateX(8vw) translateY(-38vh) scale(0.41);
    }

    29.4117647059% {
      transform: translateX(34vw) translateY(26vh) scale(0.7);
    }

    35.2941176471% {
      transform: translateX(-7vw) translateY(-3vh) scale(0.52);
    }

    41.1764705882% {
      transform: translateX(-43vw) translateY(45vh) scale(0.43);
    }

    47.0588235294% {
      transform: translateX(47vw) translateY(9vh) scale(0.89);
    }

    52.9411764706% {
      transform: translateX(45vw) translateY(32vh) scale(0.77);
    }

    58.8235294118% {
      transform: translateX(8vw) translateY(16vh) scale(0.64);
    }

    64.7058823529% {
      transform: translateX(-45vw) translateY(-38vh) scale(0.92);
    }

    70.5882352941% {
      transform: translateX(-6vw) translateY(-40vh) scale(0.64);
    }

    76.4705882353% {
      transform: translateX(29vw) translateY(-27vh) scale(0.83);
    }

    82.3529411765% {
      transform: translateX(4vw) translateY(10vh) scale(0.86);
    }

    88.2352941176% {
      transform: translateX(-8vw) translateY(43vh) scale(0.91);
    }

    94.1176470588% {
      transform: translateX(33vw) translateY(10vh) scale(0.57);
    }

    100% {
      transform: translateX(-17vw) translateY(14vh) scale(0.83);
    }
  }

  .firefly:nth-child(15) {
    animation-name: move15;
  }

  .firefly:nth-child(15)::before {
    animation-duration: 14s;
  }

  .firefly:nth-child(15)::after {
    animation-duration: 14s, 8455ms;
    animation-delay: 0ms, 1227ms;
  }

  @keyframes move15 {
    0% {
      transform: translateX(12vw) translateY(-1vh) scale(0.28);
    }

    4% {
      transform: translateX(6vw) translateY(-14vh) scale(0.61);
    }

    8% {
      transform: translateX(9vw) translateY(-13vh) scale(0.51);
    }

    12% {
      transform: translateX(26vw) translateY(14vh) scale(0.81);
    }

    16% {
      transform: translateX(-32vw) translateY(7vh) scale(0.99);
    }

    20% {
      transform: translateX(19vw) translateY(10vh) scale(0.27);
    }

    24% {
      transform: translateX(-13vw) translateY(-10vh) scale(0.83);
    }

    28% {
      transform: translateX(-35vw) translateY(-2vh) scale(0.39);
    }

    32% {
      transform: translateX(-28vw) translateY(-13vh) scale(0.82);
    }

    36% {
      transform: translateX(-45vw) translateY(28vh) scale(0.48);
    }

    40% {
      transform: translateX(-5vw) translateY(42vh) scale(0.74);
    }

    44% {
      transform: translateX(-30vw) translateY(22vh) scale(0.44);
    }

    48% {
      transform: translateX(15vw) translateY(-21vh) scale(0.55);
    }

    52% {
      transform: translateX(-6vw) translateY(1vh) scale(0.94);
    }

    56% {
      transform: translateX(-26vw) translateY(13vh) scale(0.26);
    }

    60% {
      transform: translateX(44vw) translateY(-49vh) scale(0.67);
    }

    64% {
      transform: translateX(23vw) translateY(-28vh) scale(0.35);
    }

    68% {
      transform: translateX(35vw) translateY(37vh) scale(0.56);
    }

    72% {
      transform: translateX(-4vw) translateY(43vh) scale(0.76);
    }

    76% {
      transform: translateX(-43vw) translateY(43vh) scale(0.56);
    }

    80% {
      transform: translateX(-12vw) translateY(1vh) scale(0.99);
    }

    84% {
      transform: translateX(-26vw) translateY(3vh) scale(0.3);
    }

    88% {
      transform: translateX(46vw) translateY(-9vh) scale(0.55);
    }

    92% {
      transform: translateX(33vw) translateY(-15vh) scale(0.66);
    }

    96% {
      transform: translateX(19vw) translateY(-3vh) scale(0.59);
    }

    100% {
      transform: translateX(-32vw) translateY(-18vh) scale(0.31);
    }
  }

  @keyframes drift {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes flash {

    0%,
    30%,
    100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw yellow;
    }

    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw yellow;
    }
  }


  .stickyBtn2 {
    width: 55px;
    height: 55px;
    opacity: 0.5;
    transition: 0.5s ease;
    z-index: 1;

  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 85%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    transition: transform .4s;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .miniBarMain{
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fd2;
    display: flex;
    justify-content: space-between;
  }

  .miniBar{
    display: flex;
    justify-content: space-between;
  }

  .navbarMain{
    padding-right: 30px;
     position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }
  
  .navbar {
    width: 50px;
    justify-content: flex-end;
    cursor: pointer;    
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    z-index: 1000000000000000000;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: rgba(218, 165, 32, 0.685) 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px rgba(49, 49, 49, 0.603) solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
     margin-left: auto;  
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }

  .allWrap {
    background-color: black;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .containerMob {
    display: none;
  }

  .cont {
    background: radial-gradient(circle, rgba(13, 24, 31, 0.2777485994397759) 0%, rgba(13, 24, 31, 0.48783263305322133) 69%, rgba(13, 24, 31, 0.8743872549019608) 100%), url('./assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .in2 p {
    font-size: 20px;
    color: white;
    font-weight: bold;
    text-align: center;
    letter-spacing: 4px;
    font-family: "Rise of Kingdom";
  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 55%;
  }

  .introduction {
    padding-top: 30px;
  }

  .container {
    max-width: 430px;
    max-height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
    display: none;
  }

  .container:hover img {
    opacity: 0.3;
  }

  .container img:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  .container img {
    width: 40%;
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
    box-shadow: 0 0 10px #d44e00;
    border: 2px solid #d6985c;
    z-index: 10000;
  }

  .containerMob {
    max-width: 430px;
    max-height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 50px;
    margin-bottom: 80px;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
  }

  .containerMob:hover img {
    opacity: 0.3;
  }

  .containerMob img:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  .containerMob img {
    width: 40%;
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
    box-shadow: 0 0 10px #d44e00;
    border: 2px solid #d6985c;
    z-index: 10000;
  }

  .containerMob div {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }


  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .ag-format-container {
    width: 1142px;
    margin: 0 auto;
  }

  .ag-sparks {
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    position: relative;
  }

  .ag-sparks:before,
  .ag-sparks:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ag-sparks:before {
    content: "";

    -webkit-animation: an-blink 30s linear 3s infinite;
    -moz-animation: an-blink 30s linear 3s infinite;
    -o-animation: an-blink 30s linear 3s infinite;
    animation: an-blink 30s linear 3s infinite;
  }

  .ag-sparks:after {
    content: "";

    z-index: 10;
  }

  .ag-spark {
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    position: relative;
  }

  .ag-spark:before,
  .ag-spark:after {
    content: "";
    background-image: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/sparks/images/spark.png);
    background-position-x: 50%;
    background-repeat: repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    opacity: 0;

    will-change: transform;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ag-spark:before {
    -webkit-animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
    -moz-animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
    -o-animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
    animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
  }

  .ag-spark:after {
    -webkit-animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
    -moz-animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
    -o-animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
    animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
  }

  .ag-lightning {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ag-lightning:before,
  .ag-lightning:after {
    content: "";

    opacity: 0;

    will-change: transform;

    position: absolute;
  }

  .ag-lightning:before {
    width: 272px;
    background-image: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/sparks/images/lightning-1.png);

    top: -9.75em;
    left: 50%;

    -webkit-animation: an-lightning-first 30s linear 3s infinite;
    -moz-animation: an-lightning-first 30s linear 3s infinite;
    -o-animation: an-lightning-first 30s linear 3s infinite;
    animation: an-lightning-first 30s linear 3s infinite;
  }

  .ag-lightning:after {
    width: 254px;
    background-image: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/sparks/images/lightning-1.png);

    top: -10.1875em;
    right: -6.1875em;

    -webkit-animation: an-lightning-second 30s linear 3s infinite;
    -moz-animation: an-lightning-second 30s linear 3s infinite;
    -o-animation: an-lightning-second 30s linear 3s infinite;
    animation: an-lightning-second 30s linear 3s infinite;
  }

  @-webkit-keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @-moz-keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @-o-keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @-webkit-keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @-moz-keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @-o-keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @-webkit-keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @-moz-keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      -moz-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      -moz-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @-o-keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      -o-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      -o-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      -moz-transform: translateX(1.25em);
      -o-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      -moz-transform: translateX(-1.25em);
      -o-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @-webkit-keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @-moz-keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      -moz-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      -moz-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @-o-keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      -o-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      -o-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      -moz-transform: scale(-1, 1) translateX(1.25em);
      -o-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      -moz-transform: scale(-1, 1) translateX(-1.25em);
      -o-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @-webkit-keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @-moz-keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @-o-keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @-moz-keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @-o-keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @media only screen and (max-width: 767px) {
    .ag-format-container {
      width: 96%;
    }

  }

  @media only screen and (max-width: 639px) {}

  @media only screen and (max-width: 479px) {}

  @media (min-width: 768px) and (max-width: 979px) {
    .ag-format-container {
      width: 750px;
    }

  }

  @media (min-width: 980px) and (max-width: 1161px) {
    .ag-format-container {
      width: 960px;
    }

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: linear-gradient(87deg, rgba(0, 0, 0, 0.1956954656862745) 0%, rgba(0, 0, 0, 0.6606814600840336) 35%, rgba(0, 0, 0, 0.8819699754901961) 100%), url('./assets/map.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 75px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyHK {
    font-size: 75px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 60px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .storyH2-2 {
    font-size: 60px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 17px;
    text-align: justify;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 85%;
    margin-right: auto;
    margin-left: auto;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }


  .map {
    width: 60%;
    height: 60%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 22px #704531;
    margin-bottom: 25px;
  }

  .logos {
    width: 55%;
    height: 55%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 22px #704531;
    margin-bottom: 25px;
    display: none;
   }

   .logos_mobile {
    width: 55%;
    height: 55%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 22px #704531;
    margin-bottom: 25px;
  }
 

  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    background: rgba(19, 19, 19, 0.767);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #e2e2e2 2px ridge;
    box-shadow: 0 0 15px #704531;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .faqbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13126969537815125) 0%, rgba(0, 0, 0, 0.3385526085434174) 35%, rgba(0, 0, 0, 0.7979363620448179) 100%), url('./assets/faqbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .team {
    width: 85%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .teambg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13126969537815125) 0%, rgba(0, 0, 0, 0.3385526085434174) 35%, rgba(0, 0, 0, 0.7979363620448179) 100%), url('./assets/teambg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
  }

  .kingdomsBg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
    padding-top: 50px;
  }

  .kingdomsSection1 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/lionKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection2 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/raakshaKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection3 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/snakeKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection4 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/eagleKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection5 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/dragonKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .logoF {
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #ff0, 0 -10px 20px #ff8000, 0 -18px 40px #F00;
    color: white;
    font-size: 52px
  }

  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(250, 219, 207);
    text-align: center;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
   }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fd2;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connect div:hover {
    color: rgb(255, 185, 138);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    transition: transform .2s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 18px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;

  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #99faf500;
    font-size: 18px;
    padding: 10px;
    width: 200px;
    border: 2px rgb(216, 179, 130) solid;
    color: rgb(255, 255, 255);
    box-shadow: 0 0 10px #b18a41;
    transition: 0.3s ease;
    cursor: none;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 78px;
    height: 78px;
  }

  .downImg {
    width: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  /*........................Roadmap........................*/

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 45px;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #eeb38b;
    color: white;
    font-family: "Rise of Kingdom";

  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 20px;

  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #f2b14b;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    border: 5px solid rgba(63, 63, 63, 0.253);
    left: 48px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #f2b14b;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #f2b14b;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid rgba(63, 63, 63, 0.253);

  }

  .footPrint2 {
    width: 60px;
    height: 60px;
  }

  .rmbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13126969537815125) 0%, rgba(0, 0, 0, 0.3385526085434174) 35%, rgba(0, 0, 0, 0.7979363620448179) 100%), url('./assets/rmbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  h4 {
    text-transform: capitalize;
    background-color: rgba(0, 0, 0, 0.384);
    color: white;
    padding: 10px;
    box-shadow: 0 0 15px #f2b24b62;
  }

  .fire {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }

  .fireGlow{
    animation: glow 2s ease-in-out infinite alternate;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }

  .firefly {
    position: sticky;
    position: -webkit-sticky;
    left: 50%;
    top: 50%;
    width: 0.4vw;
    height: 0.4vw;
    margin: -0.2vw 0 0 9.8vw;
    animation: ease 200s alternate infinite;
    pointer-events: none;
  }

  .firefly::before,
  .firefly::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: -10vw;
  }

  .firefly::before {
    background: black;
    opacity: 0.4;
    animation: drift ease alternate infinite;
  }

  .firefly::after {
    background: white;
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow;
    animation: drift ease alternate infinite, flash ease infinite;
  }

  .firefly:nth-child(1) {
    animation-name: move1;
  }

  .firefly:nth-child(1)::before {
    animation-duration: 10s;
  }

  .firefly:nth-child(1)::after {
    animation-duration: 10s, 5869ms;
    animation-delay: 0ms, 5103ms;
  }

  @keyframes move1 {
    0% {
      transform: translateX(-41vw) translateY(23vh) scale(0.42);
    }

    5% {
      transform: translateX(9vw) translateY(-45vh) scale(0.34);
    }

    10% {
      transform: translateX(-17vw) translateY(-42vh) scale(1);
    }

    15% {
      transform: translateX(8vw) translateY(-23vh) scale(0.51);
    }

    20% {
      transform: translateX(-29vw) translateY(-20vh) scale(0.56);
    }

    25% {
      transform: translateX(8vw) translateY(16vh) scale(0.79);
    }

    30% {
      transform: translateX(11vw) translateY(46vh) scale(0.54);
    }

    35% {
      transform: translateX(9vw) translateY(-45vh) scale(0.58);
    }

    40% {
      transform: translateX(10vw) translateY(-37vh) scale(0.69);
    }

    45% {
      transform: translateX(5vw) translateY(26vh) scale(0.52);
    }

    50% {
      transform: translateX(-36vw) translateY(-39vh) scale(0.43);
    }

    55% {
      transform: translateX(-45vw) translateY(35vh) scale(0.96);
    }

    60% {
      transform: translateX(17vw) translateY(38vh) scale(0.66);
    }

    65% {
      transform: translateX(-39vw) translateY(-34vh) scale(0.75);
    }

    70% {
      transform: translateX(-45vw) translateY(18vh) scale(0.72);
    }

    75% {
      transform: translateX(48vw) translateY(21vh) scale(0.42);
    }

    80% {
      transform: translateX(28vw) translateY(-2vh) scale(0.82);
    }

    85% {
      transform: translateX(-29vw) translateY(30vh) scale(0.48);
    }

    90% {
      transform: translateX(12vw) translateY(16vh) scale(0.42);
    }

    95% {
      transform: translateX(-8vw) translateY(-24vh) scale(0.52);
    }

    100% {
      transform: translateX(-49vw) translateY(6vh) scale(0.34);
    }
  }

  .firefly:nth-child(2) {
    animation-name: move2;
  }

  .firefly:nth-child(2)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(2)::after {
    animation-duration: 13s, 8622ms;
    animation-delay: 0ms, 6050ms;
  }

  @keyframes move2 {
    0% {
      transform: translateX(-13vw) translateY(17vh) scale(0.94);
    }

    4.347826087% {
      transform: translateX(12vw) translateY(24vh) scale(0.48);
    }

    8.6956521739% {
      transform: translateX(16vw) translateY(-40vh) scale(0.39);
    }

    13.0434782609% {
      transform: translateX(22vw) translateY(22vh) scale(0.68);
    }

    17.3913043478% {
      transform: translateX(19vw) translateY(-14vh) scale(0.42);
    }

    21.7391304348% {
      transform: translateX(2vw) translateY(-18vh) scale(0.3);
    }

    26.0869565217% {
      transform: translateX(2vw) translateY(38vh) scale(0.29);
    }

    30.4347826087% {
      transform: translateX(28vw) translateY(6vh) scale(0.87);
    }

    34.7826086957% {
      transform: translateX(-48vw) translateY(21vh) scale(0.88);
    }

    39.1304347826% {
      transform: translateX(32vw) translateY(15vh) scale(0.76);
    }

    43.4782608696% {
      transform: translateX(32vw) translateY(1vh) scale(0.31);
    }

    47.8260869565% {
      transform: translateX(35vw) translateY(0vh) scale(0.29);
    }

    52.1739130435% {
      transform: translateX(9vw) translateY(14vh) scale(0.27);
    }

    56.5217391304% {
      transform: translateX(12vw) translateY(-20vh) scale(0.64);
    }

    60.8695652174% {
      transform: translateX(-21vw) translateY(27vh) scale(0.72);
    }

    65.2173913043% {
      transform: translateX(-47vw) translateY(-33vh) scale(0.31);
    }

    69.5652173913% {
      transform: translateX(-25vw) translateY(7vh) scale(0.85);
    }

    73.9130434783% {
      transform: translateX(42vw) translateY(38vh) scale(0.88);
    }

    78.2608695652% {
      transform: translateX(11vw) translateY(10vh) scale(0.91);
    }

    82.6086956522% {
      transform: translateX(2vw) translateY(6vh) scale(0.59);
    }

    86.9565217391% {
      transform: translateX(37vw) translateY(4vh) scale(0.59);
    }

    91.3043478261% {
      transform: translateX(-34vw) translateY(-32vh) scale(0.95);
    }

    95.652173913% {
      transform: translateX(-47vw) translateY(32vh) scale(0.81);
    }

    100% {
      transform: translateX(32vw) translateY(22vh) scale(0.53);
    }
  }

  .firefly:nth-child(3) {
    animation-name: move3;
  }

  .firefly:nth-child(3)::before {
    animation-duration: 14s;
  }

  .firefly:nth-child(3)::after {
    animation-duration: 14s, 7972ms;
    animation-delay: 0ms, 2219ms;
  }

  @keyframes move3 {
    0% {
      transform: translateX(-9vw) translateY(-12vh) scale(0.35);
    }

    4% {
      transform: translateX(9vw) translateY(45vh) scale(0.75);
    }

    8% {
      transform: translateX(37vw) translateY(-35vh) scale(0.42);
    }

    12% {
      transform: translateX(-49vw) translateY(5vh) scale(0.46);
    }

    16% {
      transform: translateX(42vw) translateY(26vh) scale(0.34);
    }

    20% {
      transform: translateX(-12vw) translateY(19vh) scale(0.84);
    }

    24% {
      transform: translateX(23vw) translateY(6vh) scale(0.45);
    }

    28% {
      transform: translateX(18vw) translateY(-16vh) scale(0.47);
    }

    32% {
      transform: translateX(22vw) translateY(-40vh) scale(0.45);
    }

    36% {
      transform: translateX(-37vw) translateY(33vh) scale(0.75);
    }

    40% {
      transform: translateX(44vw) translateY(33vh) scale(0.61);
    }

    44% {
      transform: translateX(-44vw) translateY(-18vh) scale(0.57);
    }

    48% {
      transform: translateX(-9vw) translateY(39vh) scale(0.45);
    }

    52% {
      transform: translateX(10vw) translateY(41vh) scale(0.51);
    }

    56% {
      transform: translateX(23vw) translateY(-41vh) scale(0.31);
    }

    60% {
      transform: translateX(48vw) translateY(-5vh) scale(0.33);
    }

    64% {
      transform: translateX(-49vw) translateY(-43vh) scale(0.3);
    }

    68% {
      transform: translateX(-39vw) translateY(5vh) scale(0.44);
    }

    72% {
      transform: translateX(-18vw) translateY(-28vh) scale(0.94);
    }

    76% {
      transform: translateX(22vw) translateY(-18vh) scale(0.67);
    }

    80% {
      transform: translateX(10vw) translateY(-32vh) scale(0.56);
    }

    84% {
      transform: translateX(-7vw) translateY(-48vh) scale(0.47);
    }

    88% {
      transform: translateX(30vw) translateY(37vh) scale(0.64);
    }

    92% {
      transform: translateX(-46vw) translateY(-37vh) scale(0.53);
    }

    96% {
      transform: translateX(9vw) translateY(40vh) scale(0.87);
    }

    100% {
      transform: translateX(-48vw) translateY(-17vh) scale(0.46);
    }
  }

  .firefly:nth-child(4) {
    animation-name: move4;
  }

  .firefly:nth-child(4)::before {
    animation-duration: 10s;
  }

  .firefly:nth-child(4)::after {
    animation-duration: 10s, 8866ms;
    animation-delay: 0ms, 4122ms;
  }

  @keyframes move4 {
    0% {
      transform: translateX(-1vw) translateY(17vh) scale(0.76);
    }

    5.5555555556% {
      transform: translateX(-20vw) translateY(-6vh) scale(0.96);
    }

    11.1111111111% {
      transform: translateX(7vw) translateY(27vh) scale(0.44);
    }

    16.6666666667% {
      transform: translateX(1vw) translateY(32vh) scale(0.75);
    }

    22.2222222222% {
      transform: translateX(48vw) translateY(-14vh) scale(0.83);
    }

    27.7777777778% {
      transform: translateX(-34vw) translateY(-30vh) scale(0.6);
    }

    33.3333333333% {
      transform: translateX(40vw) translateY(-27vh) scale(1);
    }

    38.8888888889% {
      transform: translateX(-14vw) translateY(-28vh) scale(0.29);
    }

    44.4444444444% {
      transform: translateX(37vw) translateY(-14vh) scale(0.56);
    }

    50% {
      transform: translateX(29vw) translateY(8vh) scale(0.37);
    }

    55.5555555556% {
      transform: translateX(-14vw) translateY(21vh) scale(0.39);
    }

    61.1111111111% {
      transform: translateX(-1vw) translateY(38vh) scale(0.64);
    }

    66.6666666667% {
      transform: translateX(40vw) translateY(-17vh) scale(0.78);
    }

    72.2222222222% {
      transform: translateX(-6vw) translateY(-45vh) scale(0.54);
    }

    77.7777777778% {
      transform: translateX(-47vw) translateY(-46vh) scale(0.38);
    }

    83.3333333333% {
      transform: translateX(38vw) translateY(-13vh) scale(0.86);
    }

    88.8888888889% {
      transform: translateX(-8vw) translateY(35vh) scale(0.39);
    }

    94.4444444444% {
      transform: translateX(-25vw) translateY(-1vh) scale(0.44);
    }

    100% {
      transform: translateX(0vw) translateY(-34vh) scale(0.57);
    }
  }

  .firefly:nth-child(5) {
    animation-name: move5;
  }

  .firefly:nth-child(5)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(5)::after {
    animation-duration: 18s, 6640ms;
    animation-delay: 0ms, 1937ms;
  }

  @keyframes move5 {
    0% {
      transform: translateX(33vw) translateY(-14vh) scale(0.83);
    }

    4.5454545455% {
      transform: translateX(-14vw) translateY(6vh) scale(0.26);
    }

    9.0909090909% {
      transform: translateX(-15vw) translateY(-12vh) scale(0.56);
    }

    13.6363636364% {
      transform: translateX(39vw) translateY(20vh) scale(0.69);
    }

    18.1818181818% {
      transform: translateX(-21vw) translateY(-1vh) scale(0.27);
    }

    22.7272727273% {
      transform: translateX(-38vw) translateY(47vh) scale(0.43);
    }

    27.2727272727% {
      transform: translateX(10vw) translateY(-4vh) scale(0.53);
    }

    31.8181818182% {
      transform: translateX(-13vw) translateY(21vh) scale(0.88);
    }

    36.3636363636% {
      transform: translateX(-3vw) translateY(46vh) scale(0.76);
    }

    40.9090909091% {
      transform: translateX(-2vw) translateY(-11vh) scale(0.32);
    }

    45.4545454545% {
      transform: translateX(14vw) translateY(4vh) scale(0.32);
    }

    50% {
      transform: translateX(8vw) translateY(8vh) scale(0.76);
    }

    54.5454545455% {
      transform: translateX(18vw) translateY(-35vh) scale(0.6);
    }

    59.0909090909% {
      transform: translateX(15vw) translateY(-43vh) scale(0.28);
    }

    63.6363636364% {
      transform: translateX(-12vw) translateY(12vh) scale(0.68);
    }

    68.1818181818% {
      transform: translateX(5vw) translateY(25vh) scale(0.96);
    }

    72.7272727273% {
      transform: translateX(-21vw) translateY(-35vh) scale(0.4);
    }

    77.2727272727% {
      transform: translateX(-44vw) translateY(7vh) scale(0.98);
    }

    81.8181818182% {
      transform: translateX(40vw) translateY(-29vh) scale(0.91);
    }

    86.3636363636% {
      transform: translateX(30vw) translateY(-36vh) scale(0.49);
    }

    90.9090909091% {
      transform: translateX(48vw) translateY(22vh) scale(0.67);
    }

    95.4545454545% {
      transform: translateX(18vw) translateY(-10vh) scale(0.77);
    }

    100% {
      transform: translateX(-40vw) translateY(-6vh) scale(0.91);
    }
  }

  .firefly:nth-child(6) {
    animation-name: move6;
  }

  .firefly:nth-child(6)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(6)::after {
    animation-duration: 15s, 8079ms;
    animation-delay: 0ms, 6703ms;
  }

  @keyframes move6 {
    0% {
      transform: translateX(7vw) translateY(-40vh) scale(0.74);
    }

    4.7619047619% {
      transform: translateX(-23vw) translateY(32vh) scale(0.7);
    }

    9.5238095238% {
      transform: translateX(-9vw) translateY(0vh) scale(0.34);
    }

    14.2857142857% {
      transform: translateX(-36vw) translateY(18vh) scale(0.65);
    }

    19.0476190476% {
      transform: translateX(37vw) translateY(-8vh) scale(0.84);
    }

    23.8095238095% {
      transform: translateX(7vw) translateY(27vh) scale(0.44);
    }

    28.5714285714% {
      transform: translateX(-3vw) translateY(8vh) scale(0.93);
    }

    33.3333333333% {
      transform: translateX(-18vw) translateY(5vh) scale(0.87);
    }

    38.0952380952% {
      transform: translateX(25vw) translateY(19vh) scale(0.45);
    }

    42.8571428571% {
      transform: translateX(46vw) translateY(14vh) scale(0.95);
    }

    47.619047619% {
      transform: translateX(13vw) translateY(-10vh) scale(0.5);
    }

    52.380952381% {
      transform: translateX(15vw) translateY(11vh) scale(0.4);
    }

    57.1428571429% {
      transform: translateX(15vw) translateY(-35vh) scale(0.96);
    }

    61.9047619048% {
      transform: translateX(-13vw) translateY(41vh) scale(0.35);
    }

    66.6666666667% {
      transform: translateX(-23vw) translateY(26vh) scale(0.9);
    }

    71.4285714286% {
      transform: translateX(-7vw) translateY(4vh) scale(0.81);
    }

    76.1904761905% {
      transform: translateX(-49vw) translateY(-18vh) scale(0.84);
    }

    80.9523809524% {
      transform: translateX(26vw) translateY(33vh) scale(0.43);
    }

    85.7142857143% {
      transform: translateX(-6vw) translateY(23vh) scale(0.48);
    }

    90.4761904762% {
      transform: translateX(46vw) translateY(-5vh) scale(0.8);
    }

    95.2380952381% {
      transform: translateX(42vw) translateY(33vh) scale(0.6);
    }

    100% {
      transform: translateX(-28vw) translateY(26vh) scale(0.64);
    }
  }

  .firefly:nth-child(7) {
    animation-name: move7;
  }

  .firefly:nth-child(7)::before {
    animation-duration: 17s;
  }

  .firefly:nth-child(7)::after {
    animation-duration: 17s, 9827ms;
    animation-delay: 0ms, 3278ms;
  }

  @keyframes move7 {
    0% {
      transform: translateX(-1vw) translateY(41vh) scale(0.51);
    }

    5.5555555556% {
      transform: translateX(38vw) translateY(32vh) scale(0.61);
    }

    11.1111111111% {
      transform: translateX(36vw) translateY(44vh) scale(0.89);
    }

    16.6666666667% {
      transform: translateX(-22vw) translateY(-36vh) scale(0.68);
    }

    22.2222222222% {
      transform: translateX(4vw) translateY(-2vh) scale(0.87);
    }

    27.7777777778% {
      transform: translateX(11vw) translateY(-5vh) scale(0.48);
    }

    33.3333333333% {
      transform: translateX(-30vw) translateY(28vh) scale(0.43);
    }

    38.8888888889% {
      transform: translateX(-38vw) translateY(-4vh) scale(0.84);
    }

    44.4444444444% {
      transform: translateX(-29vw) translateY(33vh) scale(0.43);
    }

    50% {
      transform: translateX(1vw) translateY(-4vh) scale(0.96);
    }

    55.5555555556% {
      transform: translateX(38vw) translateY(-23vh) scale(0.5);
    }

    61.1111111111% {
      transform: translateX(-47vw) translateY(-1vh) scale(0.91);
    }

    66.6666666667% {
      transform: translateX(40vw) translateY(-8vh) scale(0.52);
    }

    72.2222222222% {
      transform: translateX(26vw) translateY(14vh) scale(0.83);
    }

    77.7777777778% {
      transform: translateX(17vw) translateY(-48vh) scale(0.97);
    }

    83.3333333333% {
      transform: translateX(-22vw) translateY(45vh) scale(0.85);
    }

    88.8888888889% {
      transform: translateX(-21vw) translateY(-9vh) scale(0.45);
    }

    94.4444444444% {
      transform: translateX(39vw) translateY(38vh) scale(0.59);
    }

    100% {
      transform: translateX(-37vw) translateY(-2vh) scale(0.55);
    }
  }

  .firefly:nth-child(8) {
    animation-name: move8;
  }

  .firefly:nth-child(8)::before {
    animation-duration: 9s;
  }

  .firefly:nth-child(8)::after {
    animation-duration: 9s, 8380ms;
    animation-delay: 0ms, 4603ms;
  }

  @keyframes move8 {
    0% {
      transform: translateX(-17vw) translateY(30vh) scale(0.58);
    }

    5.8823529412% {
      transform: translateX(-13vw) translateY(-42vh) scale(0.82);
    }

    11.7647058824% {
      transform: translateX(-10vw) translateY(-38vh) scale(0.86);
    }

    17.6470588235% {
      transform: translateX(49vw) translateY(47vh) scale(0.97);
    }

    23.5294117647% {
      transform: translateX(-17vw) translateY(-44vh) scale(1);
    }

    29.4117647059% {
      transform: translateX(-6vw) translateY(-20vh) scale(0.5);
    }

    35.2941176471% {
      transform: translateX(-39vw) translateY(-34vh) scale(0.33);
    }

    41.1764705882% {
      transform: translateX(38vw) translateY(49vh) scale(0.45);
    }

    47.0588235294% {
      transform: translateX(43vw) translateY(39vh) scale(0.43);
    }

    52.9411764706% {
      transform: translateX(46vw) translateY(-4vh) scale(0.27);
    }

    58.8235294118% {
      transform: translateX(-16vw) translateY(-20vh) scale(0.71);
    }

    64.7058823529% {
      transform: translateX(-43vw) translateY(-40vh) scale(0.84);
    }

    70.5882352941% {
      transform: translateX(-10vw) translateY(13vh) scale(0.81);
    }

    76.4705882353% {
      transform: translateX(38vw) translateY(1vh) scale(0.36);
    }

    82.3529411765% {
      transform: translateX(32vw) translateY(-40vh) scale(0.71);
    }

    88.2352941176% {
      transform: translateX(0vw) translateY(-49vh) scale(0.32);
    }

    94.1176470588% {
      transform: translateX(12vw) translateY(-49vh) scale(0.29);
    }

    100% {
      transform: translateX(7vw) translateY(-3vh) scale(0.94);
    }
  }

  .firefly:nth-child(9) {
    animation-name: move9;
  }

  .firefly:nth-child(9)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(9)::after {
    animation-duration: 18s, 10823ms;
    animation-delay: 0ms, 705ms;
  }

  @keyframes move9 {
    0% {
      transform: translateX(3vw) translateY(4vh) scale(0.41);
    }

    5.2631578947% {
      transform: translateX(-43vw) translateY(40vh) scale(0.79);
    }

    10.5263157895% {
      transform: translateX(-39vw) translateY(22vh) scale(0.38);
    }

    15.7894736842% {
      transform: translateX(-32vw) translateY(1vh) scale(0.41);
    }

    21.0526315789% {
      transform: translateX(-33vw) translateY(31vh) scale(0.81);
    }

    26.3157894737% {
      transform: translateX(2vw) translateY(-33vh) scale(0.43);
    }

    31.5789473684% {
      transform: translateX(-2vw) translateY(41vh) scale(0.78);
    }

    36.8421052632% {
      transform: translateX(-1vw) translateY(25vh) scale(0.69);
    }

    42.1052631579% {
      transform: translateX(-39vw) translateY(-28vh) scale(0.94);
    }

    47.3684210526% {
      transform: translateX(29vw) translateY(13vh) scale(0.42);
    }

    52.6315789474% {
      transform: translateX(42vw) translateY(17vh) scale(0.47);
    }

    57.8947368421% {
      transform: translateX(14vw) translateY(-9vh) scale(0.59);
    }

    63.1578947368% {
      transform: translateX(12vw) translateY(-8vh) scale(0.29);
    }

    68.4210526316% {
      transform: translateX(-31vw) translateY(-25vh) scale(0.81);
    }

    73.6842105263% {
      transform: translateX(-45vw) translateY(13vh) scale(0.33);
    }

    78.9473684211% {
      transform: translateX(-6vw) translateY(-48vh) scale(0.86);
    }

    84.2105263158% {
      transform: translateX(-18vw) translateY(-12vh) scale(0.99);
    }

    89.4736842105% {
      transform: translateX(22vw) translateY(-39vh) scale(0.51);
    }

    94.7368421053% {
      transform: translateX(43vw) translateY(-15vh) scale(0.6);
    }

    100% {
      transform: translateX(41vw) translateY(-14vh) scale(0.56);
    }
  }

  .firefly:nth-child(10) {
    animation-name: move10;
  }

  .firefly:nth-child(10)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(10)::after {
    animation-duration: 15s, 9714ms;
    animation-delay: 0ms, 7928ms;
  }

  @keyframes move10 {
    0% {
      transform: translateX(-5vw) translateY(25vh) scale(0.3);
    }

    3.8461538462% {
      transform: translateX(11vw) translateY(42vh) scale(0.81);
    }

    7.6923076923% {
      transform: translateX(-45vw) translateY(25vh) scale(0.44);
    }

    11.5384615385% {
      transform: translateX(23vw) translateY(-4vh) scale(0.43);
    }

    15.3846153846% {
      transform: translateX(10vw) translateY(12vh) scale(0.84);
    }

    19.2307692308% {
      transform: translateX(-33vw) translateY(38vh) scale(0.95);
    }

    23.0769230769% {
      transform: translateX(15vw) translateY(23vh) scale(0.56);
    }

    26.9230769231% {
      transform: translateX(-12vw) translateY(-17vh) scale(0.42);
    }

    30.7692307692% {
      transform: translateX(1vw) translateY(-34vh) scale(0.61);
    }

    34.6153846154% {
      transform: translateX(22vw) translateY(-25vh) scale(0.32);
    }

    38.4615384615% {
      transform: translateX(-17vw) translateY(26vh) scale(0.65);
    }

    42.3076923077% {
      transform: translateX(3vw) translateY(29vh) scale(0.91);
    }

    46.1538461538% {
      transform: translateX(-12vw) translateY(-36vh) scale(0.64);
    }

    50% {
      transform: translateX(-44vw) translateY(41vh) scale(0.44);
    }

    53.8461538462% {
      transform: translateX(9vw) translateY(-29vh) scale(0.6);
    }

    57.6923076923% {
      transform: translateX(22vw) translateY(30vh) scale(0.98);
    }

    61.5384615385% {
      transform: translateX(-20vw) translateY(33vh) scale(0.7);
    }

    65.3846153846% {
      transform: translateX(40vw) translateY(24vh) scale(0.38);
    }

    69.2307692308% {
      transform: translateX(-19vw) translateY(4vh) scale(0.48);
    }

    73.0769230769% {
      transform: translateX(-18vw) translateY(-32vh) scale(1);
    }

    76.9230769231% {
      transform: translateX(17vw) translateY(1vh) scale(0.68);
    }

    80.7692307692% {
      transform: translateX(-40vw) translateY(-16vh) scale(0.92);
    }

    84.6153846154% {
      transform: translateX(17vw) translateY(32vh) scale(0.9);
    }

    88.4615384615% {
      transform: translateX(14vw) translateY(45vh) scale(0.8);
    }

    92.3076923077% {
      transform: translateX(14vw) translateY(50vh) scale(0.74);
    }

    96.1538461538% {
      transform: translateX(26vw) translateY(47vh) scale(0.83);
    }

    100% {
      transform: translateX(49vw) translateY(-40vh) scale(0.95);
    }
  }

  .firefly:nth-child(11) {
    animation-name: move11;
  }

  .firefly:nth-child(11)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(11)::after {
    animation-duration: 13s, 7476ms;
    animation-delay: 0ms, 5436ms;
  }

  @keyframes move11 {
    0% {
      transform: translateX(-7vw) translateY(16vh) scale(0.42);
    }

    4.1666666667% {
      transform: translateX(-44vw) translateY(14vh) scale(0.89);
    }

    8.3333333333% {
      transform: translateX(17vw) translateY(-25vh) scale(0.72);
    }

    12.5% {
      transform: translateX(-40vw) translateY(-22vh) scale(0.47);
    }

    16.6666666667% {
      transform: translateX(49vw) translateY(12vh) scale(0.93);
    }

    20.8333333333% {
      transform: translateX(-45vw) translateY(21vh) scale(0.6);
    }

    25% {
      transform: translateX(36vw) translateY(-41vh) scale(0.48);
    }

    29.1666666667% {
      transform: translateX(-9vw) translateY(0vh) scale(0.98);
    }

    33.3333333333% {
      transform: translateX(-32vw) translateY(37vh) scale(0.29);
    }

    37.5% {
      transform: translateX(31vw) translateY(-46vh) scale(0.34);
    }

    41.6666666667% {
      transform: translateX(21vw) translateY(-45vh) scale(0.51);
    }

    45.8333333333% {
      transform: translateX(-12vw) translateY(-46vh) scale(0.87);
    }

    50% {
      transform: translateX(25vw) translateY(28vh) scale(0.48);
    }

    54.1666666667% {
      transform: translateX(-16vw) translateY(-25vh) scale(0.76);
    }

    58.3333333333% {
      transform: translateX(-4vw) translateY(48vh) scale(0.63);
    }

    62.5% {
      transform: translateX(48vw) translateY(-32vh) scale(0.31);
    }

    66.6666666667% {
      transform: translateX(-39vw) translateY(-39vh) scale(0.56);
    }

    70.8333333333% {
      transform: translateX(-1vw) translateY(37vh) scale(0.5);
    }

    75% {
      transform: translateX(-9vw) translateY(-5vh) scale(0.56);
    }

    79.1666666667% {
      transform: translateX(-11vw) translateY(13vh) scale(0.96);
    }

    83.3333333333% {
      transform: translateX(-1vw) translateY(-41vh) scale(0.55);
    }

    87.5% {
      transform: translateX(17vw) translateY(18vh) scale(0.58);
    }

    91.6666666667% {
      transform: translateX(-12vw) translateY(10vh) scale(0.99);
    }

    95.8333333333% {
      transform: translateX(33vw) translateY(50vh) scale(0.85);
    }

    100% {
      transform: translateX(43vw) translateY(38vh) scale(0.52);
    }
  }

  .firefly:nth-child(12) {
    animation-name: move12;
  }

  .firefly:nth-child(12)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(12)::after {
    animation-duration: 13s, 5428ms;
    animation-delay: 0ms, 4447ms;
  }

  @keyframes move12 {
    0% {
      transform: translateX(19vw) translateY(-25vh) scale(0.71);
    }

    3.7037037037% {
      transform: translateX(5vw) translateY(-44vh) scale(0.68);
    }

    7.4074074074% {
      transform: translateX(-11vw) translateY(21vh) scale(0.96);
    }

    11.1111111111% {
      transform: translateX(-16vw) translateY(-35vh) scale(0.62);
    }

    14.8148148148% {
      transform: translateX(19vw) translateY(36vh) scale(0.51);
    }

    18.5185185185% {
      transform: translateX(-31vw) translateY(39vh) scale(0.91);
    }

    22.2222222222% {
      transform: translateX(-13vw) translateY(-33vh) scale(0.57);
    }

    25.9259259259% {
      transform: translateX(-11vw) translateY(-43vh) scale(0.91);
    }

    29.6296296296% {
      transform: translateX(6vw) translateY(-23vh) scale(0.35);
    }

    33.3333333333% {
      transform: translateX(50vw) translateY(-5vh) scale(1);
    }

    37.037037037% {
      transform: translateX(-42vw) translateY(0vh) scale(0.98);
    }

    40.7407407407% {
      transform: translateX(35vw) translateY(40vh) scale(0.97);
    }

    44.4444444444% {
      transform: translateX(-6vw) translateY(-30vh) scale(0.28);
    }

    48.1481481481% {
      transform: translateX(7vw) translateY(0vh) scale(0.85);
    }

    51.8518518519% {
      transform: translateX(-29vw) translateY(-42vh) scale(0.82);
    }

    55.5555555556% {
      transform: translateX(-13vw) translateY(-39vh) scale(0.93);
    }

    59.2592592593% {
      transform: translateX(-16vw) translateY(43vh) scale(0.99);
    }

    62.962962963% {
      transform: translateX(19vw) translateY(38vh) scale(0.61);
    }

    66.6666666667% {
      transform: translateX(13vw) translateY(26vh) scale(0.75);
    }

    70.3703703704% {
      transform: translateX(-3vw) translateY(-23vh) scale(0.7);
    }

    74.0740740741% {
      transform: translateX(-19vw) translateY(3vh) scale(0.93);
    }

    77.7777777778% {
      transform: translateX(8vw) translateY(-18vh) scale(0.77);
    }

    81.4814814815% {
      transform: translateX(-23vw) translateY(-12vh) scale(0.88);
    }

    85.1851851852% {
      transform: translateX(29vw) translateY(-27vh) scale(0.75);
    }

    88.8888888889% {
      transform: translateX(-28vw) translateY(-32vh) scale(0.46);
    }

    92.5925925926% {
      transform: translateX(-40vw) translateY(11vh) scale(0.54);
    }

    96.2962962963% {
      transform: translateX(19vw) translateY(3vh) scale(0.86);
    }

    100% {
      transform: translateX(35vw) translateY(-45vh) scale(0.73);
    }
  }

  .firefly:nth-child(13) {
    animation-name: move13;
  }

  .firefly:nth-child(13)::before {
    animation-duration: 12s;
  }

  .firefly:nth-child(13)::after {
    animation-duration: 12s, 10857ms;
    animation-delay: 0ms, 8399ms;
  }

  @keyframes move13 {
    0% {
      transform: translateX(-20vw) translateY(28vh) scale(0.31);
    }

    5.2631578947% {
      transform: translateX(10vw) translateY(-35vh) scale(0.91);
    }

    10.5263157895% {
      transform: translateX(-12vw) translateY(7vh) scale(0.65);
    }

    15.7894736842% {
      transform: translateX(-35vw) translateY(-3vh) scale(0.56);
    }

    21.0526315789% {
      transform: translateX(-24vw) translateY(-16vh) scale(0.35);
    }

    26.3157894737% {
      transform: translateX(0vw) translateY(20vh) scale(0.57);
    }

    31.5789473684% {
      transform: translateX(-45vw) translateY(-3vh) scale(0.77);
    }

    36.8421052632% {
      transform: translateX(37vw) translateY(36vh) scale(0.7);
    }

    42.1052631579% {
      transform: translateX(-9vw) translateY(8vh) scale(0.92);
    }

    47.3684210526% {
      transform: translateX(-14vw) translateY(-41vh) scale(0.29);
    }

    52.6315789474% {
      transform: translateX(-12vw) translateY(-35vh) scale(0.71);
    }

    57.8947368421% {
      transform: translateX(-3vw) translateY(-21vh) scale(0.48);
    }

    63.1578947368% {
      transform: translateX(12vw) translateY(6vh) scale(0.8);
    }

    68.4210526316% {
      transform: translateX(16vw) translateY(-5vh) scale(0.75);
    }

    73.6842105263% {
      transform: translateX(-39vw) translateY(-43vh) scale(0.68);
    }

    78.9473684211% {
      transform: translateX(35vw) translateY(-46vh) scale(0.3);
    }

    84.2105263158% {
      transform: translateX(35vw) translateY(-45vh) scale(0.83);
    }

    89.4736842105% {
      transform: translateX(-8vw) translateY(13vh) scale(0.39);
    }

    94.7368421053% {
      transform: translateX(-12vw) translateY(32vh) scale(0.45);
    }

    100% {
      transform: translateX(-14vw) translateY(42vh) scale(0.67);
    }
  }

  .firefly:nth-child(14) {
    animation-name: move14;
  }

  .firefly:nth-child(14)::before {
    animation-duration: 11s;
  }

  .firefly:nth-child(14)::after {
    animation-duration: 11s, 7829ms;
    animation-delay: 0ms, 5814ms;
  }

  @keyframes move14 {
    0% {
      transform: translateX(10vw) translateY(-18vh) scale(0.99);
    }

    5.8823529412% {
      transform: translateX(7vw) translateY(18vh) scale(0.53);
    }

    11.7647058824% {
      transform: translateX(-38vw) translateY(3vh) scale(0.96);
    }

    17.6470588235% {
      transform: translateX(22vw) translateY(-38vh) scale(0.49);
    }

    23.5294117647% {
      transform: translateX(8vw) translateY(-38vh) scale(0.41);
    }

    29.4117647059% {
      transform: translateX(34vw) translateY(26vh) scale(0.7);
    }

    35.2941176471% {
      transform: translateX(-7vw) translateY(-3vh) scale(0.52);
    }

    41.1764705882% {
      transform: translateX(-43vw) translateY(45vh) scale(0.43);
    }

    47.0588235294% {
      transform: translateX(47vw) translateY(9vh) scale(0.89);
    }

    52.9411764706% {
      transform: translateX(45vw) translateY(32vh) scale(0.77);
    }

    58.8235294118% {
      transform: translateX(8vw) translateY(16vh) scale(0.64);
    }

    64.7058823529% {
      transform: translateX(-45vw) translateY(-38vh) scale(0.92);
    }

    70.5882352941% {
      transform: translateX(-6vw) translateY(-40vh) scale(0.64);
    }

    76.4705882353% {
      transform: translateX(29vw) translateY(-27vh) scale(0.83);
    }

    82.3529411765% {
      transform: translateX(4vw) translateY(10vh) scale(0.86);
    }

    88.2352941176% {
      transform: translateX(-8vw) translateY(43vh) scale(0.91);
    }

    94.1176470588% {
      transform: translateX(33vw) translateY(10vh) scale(0.57);
    }

    100% {
      transform: translateX(-17vw) translateY(14vh) scale(0.83);
    }
  }

  .firefly:nth-child(15) {
    animation-name: move15;
  }

  .firefly:nth-child(15)::before {
    animation-duration: 14s;
  }

  .firefly:nth-child(15)::after {
    animation-duration: 14s, 8455ms;
    animation-delay: 0ms, 1227ms;
  }

  @keyframes move15 {
    0% {
      transform: translateX(12vw) translateY(-1vh) scale(0.28);
    }

    4% {
      transform: translateX(6vw) translateY(-14vh) scale(0.61);
    }

    8% {
      transform: translateX(9vw) translateY(-13vh) scale(0.51);
    }

    12% {
      transform: translateX(26vw) translateY(14vh) scale(0.81);
    }

    16% {
      transform: translateX(-32vw) translateY(7vh) scale(0.99);
    }

    20% {
      transform: translateX(19vw) translateY(10vh) scale(0.27);
    }

    24% {
      transform: translateX(-13vw) translateY(-10vh) scale(0.83);
    }

    28% {
      transform: translateX(-35vw) translateY(-2vh) scale(0.39);
    }

    32% {
      transform: translateX(-28vw) translateY(-13vh) scale(0.82);
    }

    36% {
      transform: translateX(-45vw) translateY(28vh) scale(0.48);
    }

    40% {
      transform: translateX(-5vw) translateY(42vh) scale(0.74);
    }

    44% {
      transform: translateX(-30vw) translateY(22vh) scale(0.44);
    }

    48% {
      transform: translateX(15vw) translateY(-21vh) scale(0.55);
    }

    52% {
      transform: translateX(-6vw) translateY(1vh) scale(0.94);
    }

    56% {
      transform: translateX(-26vw) translateY(13vh) scale(0.26);
    }

    60% {
      transform: translateX(44vw) translateY(-49vh) scale(0.67);
    }

    64% {
      transform: translateX(23vw) translateY(-28vh) scale(0.35);
    }

    68% {
      transform: translateX(35vw) translateY(37vh) scale(0.56);
    }

    72% {
      transform: translateX(-4vw) translateY(43vh) scale(0.76);
    }

    76% {
      transform: translateX(-43vw) translateY(43vh) scale(0.56);
    }

    80% {
      transform: translateX(-12vw) translateY(1vh) scale(0.99);
    }

    84% {
      transform: translateX(-26vw) translateY(3vh) scale(0.3);
    }

    88% {
      transform: translateX(46vw) translateY(-9vh) scale(0.55);
    }

    92% {
      transform: translateX(33vw) translateY(-15vh) scale(0.66);
    }

    96% {
      transform: translateX(19vw) translateY(-3vh) scale(0.59);
    }

    100% {
      transform: translateX(-32vw) translateY(-18vh) scale(0.31);
    }
  }

  @keyframes drift {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes flash {

    0%,
    30%,
    100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw yellow;
    }

    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw yellow;
    }
  }


  .stickyBtn2 {
    width: 65px;
    height: 65px;
    opacity: 0.5;
    transition: 0.5s ease;
    z-index: 1;

  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 89%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 1;
    transition: transform .4s;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  .miniBarMain{
    display: none;
  }

  .littleNav{
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background-color: black;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .containerMob {
    display: none;
  }

  .cont {
    background: radial-gradient(circle, rgba(13, 24, 31, 0.2777485994397759) 0%, rgba(13, 24, 31, 0.48783263305322133) 69%, rgba(13, 24, 31, 0.8743872549019608) 100%), url('./assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .in2 p {
    font-size: 23px;
    color: white;
    font-weight: bold;
    text-align: center;
    letter-spacing: 4px;
    font-family: "Rise of Kingdom";

  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
  }

  .introduction {
    padding-top: 20px;
  }

  .container {
    max-width: 430px;
    max-height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 50px;
    margin-bottom: 50px;

  }

  .container:hover img {
    opacity: 0.3;
  }

  .container img:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  .container img {
    width: 40%;
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
    box-shadow: 0 0 10px #d44e00;
    border: 2px solid #d6985c;
    z-index: 10000;
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .ag-format-container {
    width: 1142px;
    margin: 0 auto;
  }

  .ag-sparks {
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    position: relative;
  }

  .ag-sparks:before,
  .ag-sparks:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ag-sparks:before {
    content: "";

    -webkit-animation: an-blink 30s linear 3s infinite;
    -moz-animation: an-blink 30s linear 3s infinite;
    -o-animation: an-blink 30s linear 3s infinite;
    animation: an-blink 30s linear 3s infinite;
  }

  .ag-sparks:after {
    content: "";

    z-index: 10;
  }

  .ag-spark {
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    position: relative;
  }

  .ag-spark:before,
  .ag-spark:after {
    content: "";
    background-image: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/sparks/images/spark.png);
    background-position-x: 50%;
    background-repeat: repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    opacity: 0;

    will-change: transform;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ag-spark:before {
    -webkit-animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
    -moz-animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
    -o-animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
    animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
  }

  .ag-spark:after {
    -webkit-animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
    -moz-animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
    -o-animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
    animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
  }

  .ag-lightning {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ag-lightning:before,
  .ag-lightning:after {
    content: "";

    opacity: 0;

    will-change: transform;

    position: absolute;
  }

  .ag-lightning:before {
    width: 272px;
    background-image: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/sparks/images/lightning-1.png);

    top: -9.75em;
    left: 50%;

    -webkit-animation: an-lightning-first 30s linear 3s infinite;
    -moz-animation: an-lightning-first 30s linear 3s infinite;
    -o-animation: an-lightning-first 30s linear 3s infinite;
    animation: an-lightning-first 30s linear 3s infinite;
  }

  .ag-lightning:after {
    width: 254px;
    background-image: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/sparks/images/lightning-1.png);

    top: -10.1875em;
    right: -6.1875em;

    -webkit-animation: an-lightning-second 30s linear 3s infinite;
    -moz-animation: an-lightning-second 30s linear 3s infinite;
    -o-animation: an-lightning-second 30s linear 3s infinite;
    animation: an-lightning-second 30s linear 3s infinite;
  }

  @-webkit-keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @-moz-keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @-o-keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @-webkit-keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @-moz-keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @-o-keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @-webkit-keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @-moz-keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      -moz-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      -moz-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @-o-keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      -o-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      -o-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      -moz-transform: translateX(1.25em);
      -o-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      -moz-transform: translateX(-1.25em);
      -o-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @-webkit-keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @-moz-keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      -moz-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      -moz-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @-o-keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      -o-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      -o-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      -moz-transform: scale(-1, 1) translateX(1.25em);
      -o-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      -moz-transform: scale(-1, 1) translateX(-1.25em);
      -o-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @-webkit-keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @-moz-keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @-o-keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @-moz-keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @-o-keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @media only screen and (max-width: 767px) {
    .ag-format-container {
      width: 96%;
    }

  }

  @media only screen and (max-width: 639px) {}

  @media only screen and (max-width: 479px) {}

  @media (min-width: 768px) and (max-width: 979px) {
    .ag-format-container {
      width: 750px;
    }

  }

  @media (min-width: 980px) and (max-width: 1161px) {
    .ag-format-container {
      width: 960px;
    }

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: linear-gradient(87deg, rgba(0, 0, 0, 0.1956954656862745) 0%, rgba(0, 0, 0, 0.6606814600840336) 35%, rgba(0, 0, 0, 0.8819699754901961) 100%), url('./assets/map.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 75px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyHK {
    font-size: 75px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: left;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .storyH2-2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 17px;
    text-align: left;
    display: flex;
    justify-content: space-between;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }


  .map {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    background: rgba(19, 19, 19, 0.767);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #e2e2e2 2px ridge;
    box-shadow: 0 0 15px #704531;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .faqbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13126969537815125) 0%, rgba(0, 0, 0, 0.3385526085434174) 35%, rgba(0, 0, 0, 0.7979363620448179) 100%), url('./assets/faqbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .team {
    width: 70%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .teambg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13126969537815125) 0%, rgba(0, 0, 0, 0.3385526085434174) 35%, rgba(0, 0, 0, 0.7979363620448179) 100%), url('./assets/teambg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
  }

  .kingdomsBg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
    padding-top: 50px;
  }

  .kingdomsSection1 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/lionKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection2 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/raakshaKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection3 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/snakeKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection4 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/eagleKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection5 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/dragonKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .logoF {
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #ff0, 0 -10px 20px #ff8000, 0 -18px 40px #F00;
    color: white;
    font-size: 52px
  }

  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(250, 219, 207);
    text-align: center;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fd2;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connect div:hover {
    color: rgb(255, 185, 138);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform .2s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 18px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;

  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #99faf500;
    font-size: 18px;
    padding: 10px;
    width: 200px;
    border: 2px rgb(216, 179, 130) solid;
    color: rgb(255, 255, 255);
    box-shadow: 0 0 10px #b18a41;
    transition: 0.3s ease;
    cursor: none;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  /*........................Roadmap........................*/

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 45px;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #eeb38b;
    color: white;
    font-family: "Rise of Kingdom";

  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 20px;

  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #f2b14b;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    border: 5px solid rgba(63, 63, 63, 0.253);
    left: 48px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #f2b14b;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #f2b14b;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid rgba(63, 63, 63, 0.253);

  }

  .footPrint2 {
    width: 60px;
    height: 60px;
  }

  .rmbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13126969537815125) 0%, rgba(0, 0, 0, 0.3385526085434174) 35%, rgba(0, 0, 0, 0.7979363620448179) 100%), url('./assets/rmbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  h4 {
    text-transform: capitalize;
    background-color: rgba(0, 0, 0, 0.384);
    color: white;
    padding: 10px;
    box-shadow: 0 0 15px #f2b24b62;
  }

  .fire {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }

  .fireGlow{
    animation: glow 2s ease-in-out infinite alternate;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }

  .firefly {
    position: sticky;
    position: -webkit-sticky;
    left: 50%;
    top: 50%;
    width: 0.4vw;
    height: 0.4vw;
    margin: -0.2vw 0 0 9.8vw;
    animation: ease 200s alternate infinite;
    pointer-events: none;
  }

  .firefly::before,
  .firefly::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: -10vw;
  }

  .firefly::before {
    background: black;
    opacity: 0.4;
    animation: drift ease alternate infinite;
  }

  .firefly::after {
    background: white;
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow;
    animation: drift ease alternate infinite, flash ease infinite;
  }

  .firefly:nth-child(1) {
    animation-name: move1;
  }

  .firefly:nth-child(1)::before {
    animation-duration: 10s;
  }

  .firefly:nth-child(1)::after {
    animation-duration: 10s, 5869ms;
    animation-delay: 0ms, 5103ms;
  }

  @keyframes move1 {
    0% {
      transform: translateX(-41vw) translateY(23vh) scale(0.42);
    }

    5% {
      transform: translateX(9vw) translateY(-45vh) scale(0.34);
    }

    10% {
      transform: translateX(-17vw) translateY(-42vh) scale(1);
    }

    15% {
      transform: translateX(8vw) translateY(-23vh) scale(0.51);
    }

    20% {
      transform: translateX(-29vw) translateY(-20vh) scale(0.56);
    }

    25% {
      transform: translateX(8vw) translateY(16vh) scale(0.79);
    }

    30% {
      transform: translateX(11vw) translateY(46vh) scale(0.54);
    }

    35% {
      transform: translateX(9vw) translateY(-45vh) scale(0.58);
    }

    40% {
      transform: translateX(10vw) translateY(-37vh) scale(0.69);
    }

    45% {
      transform: translateX(5vw) translateY(26vh) scale(0.52);
    }

    50% {
      transform: translateX(-36vw) translateY(-39vh) scale(0.43);
    }

    55% {
      transform: translateX(-45vw) translateY(35vh) scale(0.96);
    }

    60% {
      transform: translateX(17vw) translateY(38vh) scale(0.66);
    }

    65% {
      transform: translateX(-39vw) translateY(-34vh) scale(0.75);
    }

    70% {
      transform: translateX(-45vw) translateY(18vh) scale(0.72);
    }

    75% {
      transform: translateX(48vw) translateY(21vh) scale(0.42);
    }

    80% {
      transform: translateX(28vw) translateY(-2vh) scale(0.82);
    }

    85% {
      transform: translateX(-29vw) translateY(30vh) scale(0.48);
    }

    90% {
      transform: translateX(12vw) translateY(16vh) scale(0.42);
    }

    95% {
      transform: translateX(-8vw) translateY(-24vh) scale(0.52);
    }

    100% {
      transform: translateX(-49vw) translateY(6vh) scale(0.34);
    }
  }

  .firefly:nth-child(2) {
    animation-name: move2;
  }

  .firefly:nth-child(2)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(2)::after {
    animation-duration: 13s, 8622ms;
    animation-delay: 0ms, 6050ms;
  }

  @keyframes move2 {
    0% {
      transform: translateX(-13vw) translateY(17vh) scale(0.94);
    }

    4.347826087% {
      transform: translateX(12vw) translateY(24vh) scale(0.48);
    }

    8.6956521739% {
      transform: translateX(16vw) translateY(-40vh) scale(0.39);
    }

    13.0434782609% {
      transform: translateX(22vw) translateY(22vh) scale(0.68);
    }

    17.3913043478% {
      transform: translateX(19vw) translateY(-14vh) scale(0.42);
    }

    21.7391304348% {
      transform: translateX(2vw) translateY(-18vh) scale(0.3);
    }

    26.0869565217% {
      transform: translateX(2vw) translateY(38vh) scale(0.29);
    }

    30.4347826087% {
      transform: translateX(28vw) translateY(6vh) scale(0.87);
    }

    34.7826086957% {
      transform: translateX(-48vw) translateY(21vh) scale(0.88);
    }

    39.1304347826% {
      transform: translateX(32vw) translateY(15vh) scale(0.76);
    }

    43.4782608696% {
      transform: translateX(32vw) translateY(1vh) scale(0.31);
    }

    47.8260869565% {
      transform: translateX(35vw) translateY(0vh) scale(0.29);
    }

    52.1739130435% {
      transform: translateX(9vw) translateY(14vh) scale(0.27);
    }

    56.5217391304% {
      transform: translateX(12vw) translateY(-20vh) scale(0.64);
    }

    60.8695652174% {
      transform: translateX(-21vw) translateY(27vh) scale(0.72);
    }

    65.2173913043% {
      transform: translateX(-47vw) translateY(-33vh) scale(0.31);
    }

    69.5652173913% {
      transform: translateX(-25vw) translateY(7vh) scale(0.85);
    }

    73.9130434783% {
      transform: translateX(42vw) translateY(38vh) scale(0.88);
    }

    78.2608695652% {
      transform: translateX(11vw) translateY(10vh) scale(0.91);
    }

    82.6086956522% {
      transform: translateX(2vw) translateY(6vh) scale(0.59);
    }

    86.9565217391% {
      transform: translateX(37vw) translateY(4vh) scale(0.59);
    }

    91.3043478261% {
      transform: translateX(-34vw) translateY(-32vh) scale(0.95);
    }

    95.652173913% {
      transform: translateX(-47vw) translateY(32vh) scale(0.81);
    }

    100% {
      transform: translateX(32vw) translateY(22vh) scale(0.53);
    }
  }

  .firefly:nth-child(3) {
    animation-name: move3;
  }

  .firefly:nth-child(3)::before {
    animation-duration: 14s;
  }

  .firefly:nth-child(3)::after {
    animation-duration: 14s, 7972ms;
    animation-delay: 0ms, 2219ms;
  }

  @keyframes move3 {
    0% {
      transform: translateX(-9vw) translateY(-12vh) scale(0.35);
    }

    4% {
      transform: translateX(9vw) translateY(45vh) scale(0.75);
    }

    8% {
      transform: translateX(37vw) translateY(-35vh) scale(0.42);
    }

    12% {
      transform: translateX(-49vw) translateY(5vh) scale(0.46);
    }

    16% {
      transform: translateX(42vw) translateY(26vh) scale(0.34);
    }

    20% {
      transform: translateX(-12vw) translateY(19vh) scale(0.84);
    }

    24% {
      transform: translateX(23vw) translateY(6vh) scale(0.45);
    }

    28% {
      transform: translateX(18vw) translateY(-16vh) scale(0.47);
    }

    32% {
      transform: translateX(22vw) translateY(-40vh) scale(0.45);
    }

    36% {
      transform: translateX(-37vw) translateY(33vh) scale(0.75);
    }

    40% {
      transform: translateX(44vw) translateY(33vh) scale(0.61);
    }

    44% {
      transform: translateX(-44vw) translateY(-18vh) scale(0.57);
    }

    48% {
      transform: translateX(-9vw) translateY(39vh) scale(0.45);
    }

    52% {
      transform: translateX(10vw) translateY(41vh) scale(0.51);
    }

    56% {
      transform: translateX(23vw) translateY(-41vh) scale(0.31);
    }

    60% {
      transform: translateX(48vw) translateY(-5vh) scale(0.33);
    }

    64% {
      transform: translateX(-49vw) translateY(-43vh) scale(0.3);
    }

    68% {
      transform: translateX(-39vw) translateY(5vh) scale(0.44);
    }

    72% {
      transform: translateX(-18vw) translateY(-28vh) scale(0.94);
    }

    76% {
      transform: translateX(22vw) translateY(-18vh) scale(0.67);
    }

    80% {
      transform: translateX(10vw) translateY(-32vh) scale(0.56);
    }

    84% {
      transform: translateX(-7vw) translateY(-48vh) scale(0.47);
    }

    88% {
      transform: translateX(30vw) translateY(37vh) scale(0.64);
    }

    92% {
      transform: translateX(-46vw) translateY(-37vh) scale(0.53);
    }

    96% {
      transform: translateX(9vw) translateY(40vh) scale(0.87);
    }

    100% {
      transform: translateX(-48vw) translateY(-17vh) scale(0.46);
    }
  }

  .firefly:nth-child(4) {
    animation-name: move4;
  }

  .firefly:nth-child(4)::before {
    animation-duration: 10s;
  }

  .firefly:nth-child(4)::after {
    animation-duration: 10s, 8866ms;
    animation-delay: 0ms, 4122ms;
  }

  @keyframes move4 {
    0% {
      transform: translateX(-1vw) translateY(17vh) scale(0.76);
    }

    5.5555555556% {
      transform: translateX(-20vw) translateY(-6vh) scale(0.96);
    }

    11.1111111111% {
      transform: translateX(7vw) translateY(27vh) scale(0.44);
    }

    16.6666666667% {
      transform: translateX(1vw) translateY(32vh) scale(0.75);
    }

    22.2222222222% {
      transform: translateX(48vw) translateY(-14vh) scale(0.83);
    }

    27.7777777778% {
      transform: translateX(-34vw) translateY(-30vh) scale(0.6);
    }

    33.3333333333% {
      transform: translateX(40vw) translateY(-27vh) scale(1);
    }

    38.8888888889% {
      transform: translateX(-14vw) translateY(-28vh) scale(0.29);
    }

    44.4444444444% {
      transform: translateX(37vw) translateY(-14vh) scale(0.56);
    }

    50% {
      transform: translateX(29vw) translateY(8vh) scale(0.37);
    }

    55.5555555556% {
      transform: translateX(-14vw) translateY(21vh) scale(0.39);
    }

    61.1111111111% {
      transform: translateX(-1vw) translateY(38vh) scale(0.64);
    }

    66.6666666667% {
      transform: translateX(40vw) translateY(-17vh) scale(0.78);
    }

    72.2222222222% {
      transform: translateX(-6vw) translateY(-45vh) scale(0.54);
    }

    77.7777777778% {
      transform: translateX(-47vw) translateY(-46vh) scale(0.38);
    }

    83.3333333333% {
      transform: translateX(38vw) translateY(-13vh) scale(0.86);
    }

    88.8888888889% {
      transform: translateX(-8vw) translateY(35vh) scale(0.39);
    }

    94.4444444444% {
      transform: translateX(-25vw) translateY(-1vh) scale(0.44);
    }

    100% {
      transform: translateX(0vw) translateY(-34vh) scale(0.57);
    }
  }

  .firefly:nth-child(5) {
    animation-name: move5;
  }

  .firefly:nth-child(5)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(5)::after {
    animation-duration: 18s, 6640ms;
    animation-delay: 0ms, 1937ms;
  }

  @keyframes move5 {
    0% {
      transform: translateX(33vw) translateY(-14vh) scale(0.83);
    }

    4.5454545455% {
      transform: translateX(-14vw) translateY(6vh) scale(0.26);
    }

    9.0909090909% {
      transform: translateX(-15vw) translateY(-12vh) scale(0.56);
    }

    13.6363636364% {
      transform: translateX(39vw) translateY(20vh) scale(0.69);
    }

    18.1818181818% {
      transform: translateX(-21vw) translateY(-1vh) scale(0.27);
    }

    22.7272727273% {
      transform: translateX(-38vw) translateY(47vh) scale(0.43);
    }

    27.2727272727% {
      transform: translateX(10vw) translateY(-4vh) scale(0.53);
    }

    31.8181818182% {
      transform: translateX(-13vw) translateY(21vh) scale(0.88);
    }

    36.3636363636% {
      transform: translateX(-3vw) translateY(46vh) scale(0.76);
    }

    40.9090909091% {
      transform: translateX(-2vw) translateY(-11vh) scale(0.32);
    }

    45.4545454545% {
      transform: translateX(14vw) translateY(4vh) scale(0.32);
    }

    50% {
      transform: translateX(8vw) translateY(8vh) scale(0.76);
    }

    54.5454545455% {
      transform: translateX(18vw) translateY(-35vh) scale(0.6);
    }

    59.0909090909% {
      transform: translateX(15vw) translateY(-43vh) scale(0.28);
    }

    63.6363636364% {
      transform: translateX(-12vw) translateY(12vh) scale(0.68);
    }

    68.1818181818% {
      transform: translateX(5vw) translateY(25vh) scale(0.96);
    }

    72.7272727273% {
      transform: translateX(-21vw) translateY(-35vh) scale(0.4);
    }

    77.2727272727% {
      transform: translateX(-44vw) translateY(7vh) scale(0.98);
    }

    81.8181818182% {
      transform: translateX(40vw) translateY(-29vh) scale(0.91);
    }

    86.3636363636% {
      transform: translateX(30vw) translateY(-36vh) scale(0.49);
    }

    90.9090909091% {
      transform: translateX(48vw) translateY(22vh) scale(0.67);
    }

    95.4545454545% {
      transform: translateX(18vw) translateY(-10vh) scale(0.77);
    }

    100% {
      transform: translateX(-40vw) translateY(-6vh) scale(0.91);
    }
  }

  .firefly:nth-child(6) {
    animation-name: move6;
  }

  .firefly:nth-child(6)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(6)::after {
    animation-duration: 15s, 8079ms;
    animation-delay: 0ms, 6703ms;
  }

  @keyframes move6 {
    0% {
      transform: translateX(7vw) translateY(-40vh) scale(0.74);
    }

    4.7619047619% {
      transform: translateX(-23vw) translateY(32vh) scale(0.7);
    }

    9.5238095238% {
      transform: translateX(-9vw) translateY(0vh) scale(0.34);
    }

    14.2857142857% {
      transform: translateX(-36vw) translateY(18vh) scale(0.65);
    }

    19.0476190476% {
      transform: translateX(37vw) translateY(-8vh) scale(0.84);
    }

    23.8095238095% {
      transform: translateX(7vw) translateY(27vh) scale(0.44);
    }

    28.5714285714% {
      transform: translateX(-3vw) translateY(8vh) scale(0.93);
    }

    33.3333333333% {
      transform: translateX(-18vw) translateY(5vh) scale(0.87);
    }

    38.0952380952% {
      transform: translateX(25vw) translateY(19vh) scale(0.45);
    }

    42.8571428571% {
      transform: translateX(46vw) translateY(14vh) scale(0.95);
    }

    47.619047619% {
      transform: translateX(13vw) translateY(-10vh) scale(0.5);
    }

    52.380952381% {
      transform: translateX(15vw) translateY(11vh) scale(0.4);
    }

    57.1428571429% {
      transform: translateX(15vw) translateY(-35vh) scale(0.96);
    }

    61.9047619048% {
      transform: translateX(-13vw) translateY(41vh) scale(0.35);
    }

    66.6666666667% {
      transform: translateX(-23vw) translateY(26vh) scale(0.9);
    }

    71.4285714286% {
      transform: translateX(-7vw) translateY(4vh) scale(0.81);
    }

    76.1904761905% {
      transform: translateX(-49vw) translateY(-18vh) scale(0.84);
    }

    80.9523809524% {
      transform: translateX(26vw) translateY(33vh) scale(0.43);
    }

    85.7142857143% {
      transform: translateX(-6vw) translateY(23vh) scale(0.48);
    }

    90.4761904762% {
      transform: translateX(46vw) translateY(-5vh) scale(0.8);
    }

    95.2380952381% {
      transform: translateX(42vw) translateY(33vh) scale(0.6);
    }

    100% {
      transform: translateX(-28vw) translateY(26vh) scale(0.64);
    }
  }

  .firefly:nth-child(7) {
    animation-name: move7;
  }

  .firefly:nth-child(7)::before {
    animation-duration: 17s;
  }

  .firefly:nth-child(7)::after {
    animation-duration: 17s, 9827ms;
    animation-delay: 0ms, 3278ms;
  }

  @keyframes move7 {
    0% {
      transform: translateX(-1vw) translateY(41vh) scale(0.51);
    }

    5.5555555556% {
      transform: translateX(38vw) translateY(32vh) scale(0.61);
    }

    11.1111111111% {
      transform: translateX(36vw) translateY(44vh) scale(0.89);
    }

    16.6666666667% {
      transform: translateX(-22vw) translateY(-36vh) scale(0.68);
    }

    22.2222222222% {
      transform: translateX(4vw) translateY(-2vh) scale(0.87);
    }

    27.7777777778% {
      transform: translateX(11vw) translateY(-5vh) scale(0.48);
    }

    33.3333333333% {
      transform: translateX(-30vw) translateY(28vh) scale(0.43);
    }

    38.8888888889% {
      transform: translateX(-38vw) translateY(-4vh) scale(0.84);
    }

    44.4444444444% {
      transform: translateX(-29vw) translateY(33vh) scale(0.43);
    }

    50% {
      transform: translateX(1vw) translateY(-4vh) scale(0.96);
    }

    55.5555555556% {
      transform: translateX(38vw) translateY(-23vh) scale(0.5);
    }

    61.1111111111% {
      transform: translateX(-47vw) translateY(-1vh) scale(0.91);
    }

    66.6666666667% {
      transform: translateX(40vw) translateY(-8vh) scale(0.52);
    }

    72.2222222222% {
      transform: translateX(26vw) translateY(14vh) scale(0.83);
    }

    77.7777777778% {
      transform: translateX(17vw) translateY(-48vh) scale(0.97);
    }

    83.3333333333% {
      transform: translateX(-22vw) translateY(45vh) scale(0.85);
    }

    88.8888888889% {
      transform: translateX(-21vw) translateY(-9vh) scale(0.45);
    }

    94.4444444444% {
      transform: translateX(39vw) translateY(38vh) scale(0.59);
    }

    100% {
      transform: translateX(-37vw) translateY(-2vh) scale(0.55);
    }
  }

  .firefly:nth-child(8) {
    animation-name: move8;
  }

  .firefly:nth-child(8)::before {
    animation-duration: 9s;
  }

  .firefly:nth-child(8)::after {
    animation-duration: 9s, 8380ms;
    animation-delay: 0ms, 4603ms;
  }

  @keyframes move8 {
    0% {
      transform: translateX(-17vw) translateY(30vh) scale(0.58);
    }

    5.8823529412% {
      transform: translateX(-13vw) translateY(-42vh) scale(0.82);
    }

    11.7647058824% {
      transform: translateX(-10vw) translateY(-38vh) scale(0.86);
    }

    17.6470588235% {
      transform: translateX(49vw) translateY(47vh) scale(0.97);
    }

    23.5294117647% {
      transform: translateX(-17vw) translateY(-44vh) scale(1);
    }

    29.4117647059% {
      transform: translateX(-6vw) translateY(-20vh) scale(0.5);
    }

    35.2941176471% {
      transform: translateX(-39vw) translateY(-34vh) scale(0.33);
    }

    41.1764705882% {
      transform: translateX(38vw) translateY(49vh) scale(0.45);
    }

    47.0588235294% {
      transform: translateX(43vw) translateY(39vh) scale(0.43);
    }

    52.9411764706% {
      transform: translateX(46vw) translateY(-4vh) scale(0.27);
    }

    58.8235294118% {
      transform: translateX(-16vw) translateY(-20vh) scale(0.71);
    }

    64.7058823529% {
      transform: translateX(-43vw) translateY(-40vh) scale(0.84);
    }

    70.5882352941% {
      transform: translateX(-10vw) translateY(13vh) scale(0.81);
    }

    76.4705882353% {
      transform: translateX(38vw) translateY(1vh) scale(0.36);
    }

    82.3529411765% {
      transform: translateX(32vw) translateY(-40vh) scale(0.71);
    }

    88.2352941176% {
      transform: translateX(0vw) translateY(-49vh) scale(0.32);
    }

    94.1176470588% {
      transform: translateX(12vw) translateY(-49vh) scale(0.29);
    }

    100% {
      transform: translateX(7vw) translateY(-3vh) scale(0.94);
    }
  }

  .firefly:nth-child(9) {
    animation-name: move9;
  }

  .firefly:nth-child(9)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(9)::after {
    animation-duration: 18s, 10823ms;
    animation-delay: 0ms, 705ms;
  }

  @keyframes move9 {
    0% {
      transform: translateX(3vw) translateY(4vh) scale(0.41);
    }

    5.2631578947% {
      transform: translateX(-43vw) translateY(40vh) scale(0.79);
    }

    10.5263157895% {
      transform: translateX(-39vw) translateY(22vh) scale(0.38);
    }

    15.7894736842% {
      transform: translateX(-32vw) translateY(1vh) scale(0.41);
    }

    21.0526315789% {
      transform: translateX(-33vw) translateY(31vh) scale(0.81);
    }

    26.3157894737% {
      transform: translateX(2vw) translateY(-33vh) scale(0.43);
    }

    31.5789473684% {
      transform: translateX(-2vw) translateY(41vh) scale(0.78);
    }

    36.8421052632% {
      transform: translateX(-1vw) translateY(25vh) scale(0.69);
    }

    42.1052631579% {
      transform: translateX(-39vw) translateY(-28vh) scale(0.94);
    }

    47.3684210526% {
      transform: translateX(29vw) translateY(13vh) scale(0.42);
    }

    52.6315789474% {
      transform: translateX(42vw) translateY(17vh) scale(0.47);
    }

    57.8947368421% {
      transform: translateX(14vw) translateY(-9vh) scale(0.59);
    }

    63.1578947368% {
      transform: translateX(12vw) translateY(-8vh) scale(0.29);
    }

    68.4210526316% {
      transform: translateX(-31vw) translateY(-25vh) scale(0.81);
    }

    73.6842105263% {
      transform: translateX(-45vw) translateY(13vh) scale(0.33);
    }

    78.9473684211% {
      transform: translateX(-6vw) translateY(-48vh) scale(0.86);
    }

    84.2105263158% {
      transform: translateX(-18vw) translateY(-12vh) scale(0.99);
    }

    89.4736842105% {
      transform: translateX(22vw) translateY(-39vh) scale(0.51);
    }

    94.7368421053% {
      transform: translateX(43vw) translateY(-15vh) scale(0.6);
    }

    100% {
      transform: translateX(41vw) translateY(-14vh) scale(0.56);
    }
  }

  .firefly:nth-child(10) {
    animation-name: move10;
  }

  .firefly:nth-child(10)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(10)::after {
    animation-duration: 15s, 9714ms;
    animation-delay: 0ms, 7928ms;
  }

  @keyframes move10 {
    0% {
      transform: translateX(-5vw) translateY(25vh) scale(0.3);
    }

    3.8461538462% {
      transform: translateX(11vw) translateY(42vh) scale(0.81);
    }

    7.6923076923% {
      transform: translateX(-45vw) translateY(25vh) scale(0.44);
    }

    11.5384615385% {
      transform: translateX(23vw) translateY(-4vh) scale(0.43);
    }

    15.3846153846% {
      transform: translateX(10vw) translateY(12vh) scale(0.84);
    }

    19.2307692308% {
      transform: translateX(-33vw) translateY(38vh) scale(0.95);
    }

    23.0769230769% {
      transform: translateX(15vw) translateY(23vh) scale(0.56);
    }

    26.9230769231% {
      transform: translateX(-12vw) translateY(-17vh) scale(0.42);
    }

    30.7692307692% {
      transform: translateX(1vw) translateY(-34vh) scale(0.61);
    }

    34.6153846154% {
      transform: translateX(22vw) translateY(-25vh) scale(0.32);
    }

    38.4615384615% {
      transform: translateX(-17vw) translateY(26vh) scale(0.65);
    }

    42.3076923077% {
      transform: translateX(3vw) translateY(29vh) scale(0.91);
    }

    46.1538461538% {
      transform: translateX(-12vw) translateY(-36vh) scale(0.64);
    }

    50% {
      transform: translateX(-44vw) translateY(41vh) scale(0.44);
    }

    53.8461538462% {
      transform: translateX(9vw) translateY(-29vh) scale(0.6);
    }

    57.6923076923% {
      transform: translateX(22vw) translateY(30vh) scale(0.98);
    }

    61.5384615385% {
      transform: translateX(-20vw) translateY(33vh) scale(0.7);
    }

    65.3846153846% {
      transform: translateX(40vw) translateY(24vh) scale(0.38);
    }

    69.2307692308% {
      transform: translateX(-19vw) translateY(4vh) scale(0.48);
    }

    73.0769230769% {
      transform: translateX(-18vw) translateY(-32vh) scale(1);
    }

    76.9230769231% {
      transform: translateX(17vw) translateY(1vh) scale(0.68);
    }

    80.7692307692% {
      transform: translateX(-40vw) translateY(-16vh) scale(0.92);
    }

    84.6153846154% {
      transform: translateX(17vw) translateY(32vh) scale(0.9);
    }

    88.4615384615% {
      transform: translateX(14vw) translateY(45vh) scale(0.8);
    }

    92.3076923077% {
      transform: translateX(14vw) translateY(50vh) scale(0.74);
    }

    96.1538461538% {
      transform: translateX(26vw) translateY(47vh) scale(0.83);
    }

    100% {
      transform: translateX(49vw) translateY(-40vh) scale(0.95);
    }
  }

  .firefly:nth-child(11) {
    animation-name: move11;
  }

  .firefly:nth-child(11)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(11)::after {
    animation-duration: 13s, 7476ms;
    animation-delay: 0ms, 5436ms;
  }

  @keyframes move11 {
    0% {
      transform: translateX(-7vw) translateY(16vh) scale(0.42);
    }

    4.1666666667% {
      transform: translateX(-44vw) translateY(14vh) scale(0.89);
    }

    8.3333333333% {
      transform: translateX(17vw) translateY(-25vh) scale(0.72);
    }

    12.5% {
      transform: translateX(-40vw) translateY(-22vh) scale(0.47);
    }

    16.6666666667% {
      transform: translateX(49vw) translateY(12vh) scale(0.93);
    }

    20.8333333333% {
      transform: translateX(-45vw) translateY(21vh) scale(0.6);
    }

    25% {
      transform: translateX(36vw) translateY(-41vh) scale(0.48);
    }

    29.1666666667% {
      transform: translateX(-9vw) translateY(0vh) scale(0.98);
    }

    33.3333333333% {
      transform: translateX(-32vw) translateY(37vh) scale(0.29);
    }

    37.5% {
      transform: translateX(31vw) translateY(-46vh) scale(0.34);
    }

    41.6666666667% {
      transform: translateX(21vw) translateY(-45vh) scale(0.51);
    }

    45.8333333333% {
      transform: translateX(-12vw) translateY(-46vh) scale(0.87);
    }

    50% {
      transform: translateX(25vw) translateY(28vh) scale(0.48);
    }

    54.1666666667% {
      transform: translateX(-16vw) translateY(-25vh) scale(0.76);
    }

    58.3333333333% {
      transform: translateX(-4vw) translateY(48vh) scale(0.63);
    }

    62.5% {
      transform: translateX(48vw) translateY(-32vh) scale(0.31);
    }

    66.6666666667% {
      transform: translateX(-39vw) translateY(-39vh) scale(0.56);
    }

    70.8333333333% {
      transform: translateX(-1vw) translateY(37vh) scale(0.5);
    }

    75% {
      transform: translateX(-9vw) translateY(-5vh) scale(0.56);
    }

    79.1666666667% {
      transform: translateX(-11vw) translateY(13vh) scale(0.96);
    }

    83.3333333333% {
      transform: translateX(-1vw) translateY(-41vh) scale(0.55);
    }

    87.5% {
      transform: translateX(17vw) translateY(18vh) scale(0.58);
    }

    91.6666666667% {
      transform: translateX(-12vw) translateY(10vh) scale(0.99);
    }

    95.8333333333% {
      transform: translateX(33vw) translateY(50vh) scale(0.85);
    }

    100% {
      transform: translateX(43vw) translateY(38vh) scale(0.52);
    }
  }

  .firefly:nth-child(12) {
    animation-name: move12;
  }

  .firefly:nth-child(12)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(12)::after {
    animation-duration: 13s, 5428ms;
    animation-delay: 0ms, 4447ms;
  }

  @keyframes move12 {
    0% {
      transform: translateX(19vw) translateY(-25vh) scale(0.71);
    }

    3.7037037037% {
      transform: translateX(5vw) translateY(-44vh) scale(0.68);
    }

    7.4074074074% {
      transform: translateX(-11vw) translateY(21vh) scale(0.96);
    }

    11.1111111111% {
      transform: translateX(-16vw) translateY(-35vh) scale(0.62);
    }

    14.8148148148% {
      transform: translateX(19vw) translateY(36vh) scale(0.51);
    }

    18.5185185185% {
      transform: translateX(-31vw) translateY(39vh) scale(0.91);
    }

    22.2222222222% {
      transform: translateX(-13vw) translateY(-33vh) scale(0.57);
    }

    25.9259259259% {
      transform: translateX(-11vw) translateY(-43vh) scale(0.91);
    }

    29.6296296296% {
      transform: translateX(6vw) translateY(-23vh) scale(0.35);
    }

    33.3333333333% {
      transform: translateX(50vw) translateY(-5vh) scale(1);
    }

    37.037037037% {
      transform: translateX(-42vw) translateY(0vh) scale(0.98);
    }

    40.7407407407% {
      transform: translateX(35vw) translateY(40vh) scale(0.97);
    }

    44.4444444444% {
      transform: translateX(-6vw) translateY(-30vh) scale(0.28);
    }

    48.1481481481% {
      transform: translateX(7vw) translateY(0vh) scale(0.85);
    }

    51.8518518519% {
      transform: translateX(-29vw) translateY(-42vh) scale(0.82);
    }

    55.5555555556% {
      transform: translateX(-13vw) translateY(-39vh) scale(0.93);
    }

    59.2592592593% {
      transform: translateX(-16vw) translateY(43vh) scale(0.99);
    }

    62.962962963% {
      transform: translateX(19vw) translateY(38vh) scale(0.61);
    }

    66.6666666667% {
      transform: translateX(13vw) translateY(26vh) scale(0.75);
    }

    70.3703703704% {
      transform: translateX(-3vw) translateY(-23vh) scale(0.7);
    }

    74.0740740741% {
      transform: translateX(-19vw) translateY(3vh) scale(0.93);
    }

    77.7777777778% {
      transform: translateX(8vw) translateY(-18vh) scale(0.77);
    }

    81.4814814815% {
      transform: translateX(-23vw) translateY(-12vh) scale(0.88);
    }

    85.1851851852% {
      transform: translateX(29vw) translateY(-27vh) scale(0.75);
    }

    88.8888888889% {
      transform: translateX(-28vw) translateY(-32vh) scale(0.46);
    }

    92.5925925926% {
      transform: translateX(-40vw) translateY(11vh) scale(0.54);
    }

    96.2962962963% {
      transform: translateX(19vw) translateY(3vh) scale(0.86);
    }

    100% {
      transform: translateX(35vw) translateY(-45vh) scale(0.73);
    }
  }

  .firefly:nth-child(13) {
    animation-name: move13;
  }

  .firefly:nth-child(13)::before {
    animation-duration: 12s;
  }

  .firefly:nth-child(13)::after {
    animation-duration: 12s, 10857ms;
    animation-delay: 0ms, 8399ms;
  }

  @keyframes move13 {
    0% {
      transform: translateX(-20vw) translateY(28vh) scale(0.31);
    }

    5.2631578947% {
      transform: translateX(10vw) translateY(-35vh) scale(0.91);
    }

    10.5263157895% {
      transform: translateX(-12vw) translateY(7vh) scale(0.65);
    }

    15.7894736842% {
      transform: translateX(-35vw) translateY(-3vh) scale(0.56);
    }

    21.0526315789% {
      transform: translateX(-24vw) translateY(-16vh) scale(0.35);
    }

    26.3157894737% {
      transform: translateX(0vw) translateY(20vh) scale(0.57);
    }

    31.5789473684% {
      transform: translateX(-45vw) translateY(-3vh) scale(0.77);
    }

    36.8421052632% {
      transform: translateX(37vw) translateY(36vh) scale(0.7);
    }

    42.1052631579% {
      transform: translateX(-9vw) translateY(8vh) scale(0.92);
    }

    47.3684210526% {
      transform: translateX(-14vw) translateY(-41vh) scale(0.29);
    }

    52.6315789474% {
      transform: translateX(-12vw) translateY(-35vh) scale(0.71);
    }

    57.8947368421% {
      transform: translateX(-3vw) translateY(-21vh) scale(0.48);
    }

    63.1578947368% {
      transform: translateX(12vw) translateY(6vh) scale(0.8);
    }

    68.4210526316% {
      transform: translateX(16vw) translateY(-5vh) scale(0.75);
    }

    73.6842105263% {
      transform: translateX(-39vw) translateY(-43vh) scale(0.68);
    }

    78.9473684211% {
      transform: translateX(35vw) translateY(-46vh) scale(0.3);
    }

    84.2105263158% {
      transform: translateX(35vw) translateY(-45vh) scale(0.83);
    }

    89.4736842105% {
      transform: translateX(-8vw) translateY(13vh) scale(0.39);
    }

    94.7368421053% {
      transform: translateX(-12vw) translateY(32vh) scale(0.45);
    }

    100% {
      transform: translateX(-14vw) translateY(42vh) scale(0.67);
    }
  }

  .firefly:nth-child(14) {
    animation-name: move14;
  }

  .firefly:nth-child(14)::before {
    animation-duration: 11s;
  }

  .firefly:nth-child(14)::after {
    animation-duration: 11s, 7829ms;
    animation-delay: 0ms, 5814ms;
  }

  @keyframes move14 {
    0% {
      transform: translateX(10vw) translateY(-18vh) scale(0.99);
    }

    5.8823529412% {
      transform: translateX(7vw) translateY(18vh) scale(0.53);
    }

    11.7647058824% {
      transform: translateX(-38vw) translateY(3vh) scale(0.96);
    }

    17.6470588235% {
      transform: translateX(22vw) translateY(-38vh) scale(0.49);
    }

    23.5294117647% {
      transform: translateX(8vw) translateY(-38vh) scale(0.41);
    }

    29.4117647059% {
      transform: translateX(34vw) translateY(26vh) scale(0.7);
    }

    35.2941176471% {
      transform: translateX(-7vw) translateY(-3vh) scale(0.52);
    }

    41.1764705882% {
      transform: translateX(-43vw) translateY(45vh) scale(0.43);
    }

    47.0588235294% {
      transform: translateX(47vw) translateY(9vh) scale(0.89);
    }

    52.9411764706% {
      transform: translateX(45vw) translateY(32vh) scale(0.77);
    }

    58.8235294118% {
      transform: translateX(8vw) translateY(16vh) scale(0.64);
    }

    64.7058823529% {
      transform: translateX(-45vw) translateY(-38vh) scale(0.92);
    }

    70.5882352941% {
      transform: translateX(-6vw) translateY(-40vh) scale(0.64);
    }

    76.4705882353% {
      transform: translateX(29vw) translateY(-27vh) scale(0.83);
    }

    82.3529411765% {
      transform: translateX(4vw) translateY(10vh) scale(0.86);
    }

    88.2352941176% {
      transform: translateX(-8vw) translateY(43vh) scale(0.91);
    }

    94.1176470588% {
      transform: translateX(33vw) translateY(10vh) scale(0.57);
    }

    100% {
      transform: translateX(-17vw) translateY(14vh) scale(0.83);
    }
  }

  .firefly:nth-child(15) {
    animation-name: move15;
  }

  .firefly:nth-child(15)::before {
    animation-duration: 14s;
  }

  .firefly:nth-child(15)::after {
    animation-duration: 14s, 8455ms;
    animation-delay: 0ms, 1227ms;
  }

  @keyframes move15 {
    0% {
      transform: translateX(12vw) translateY(-1vh) scale(0.28);
    }

    4% {
      transform: translateX(6vw) translateY(-14vh) scale(0.61);
    }

    8% {
      transform: translateX(9vw) translateY(-13vh) scale(0.51);
    }

    12% {
      transform: translateX(26vw) translateY(14vh) scale(0.81);
    }

    16% {
      transform: translateX(-32vw) translateY(7vh) scale(0.99);
    }

    20% {
      transform: translateX(19vw) translateY(10vh) scale(0.27);
    }

    24% {
      transform: translateX(-13vw) translateY(-10vh) scale(0.83);
    }

    28% {
      transform: translateX(-35vw) translateY(-2vh) scale(0.39);
    }

    32% {
      transform: translateX(-28vw) translateY(-13vh) scale(0.82);
    }

    36% {
      transform: translateX(-45vw) translateY(28vh) scale(0.48);
    }

    40% {
      transform: translateX(-5vw) translateY(42vh) scale(0.74);
    }

    44% {
      transform: translateX(-30vw) translateY(22vh) scale(0.44);
    }

    48% {
      transform: translateX(15vw) translateY(-21vh) scale(0.55);
    }

    52% {
      transform: translateX(-6vw) translateY(1vh) scale(0.94);
    }

    56% {
      transform: translateX(-26vw) translateY(13vh) scale(0.26);
    }

    60% {
      transform: translateX(44vw) translateY(-49vh) scale(0.67);
    }

    64% {
      transform: translateX(23vw) translateY(-28vh) scale(0.35);
    }

    68% {
      transform: translateX(35vw) translateY(37vh) scale(0.56);
    }

    72% {
      transform: translateX(-4vw) translateY(43vh) scale(0.76);
    }

    76% {
      transform: translateX(-43vw) translateY(43vh) scale(0.56);
    }

    80% {
      transform: translateX(-12vw) translateY(1vh) scale(0.99);
    }

    84% {
      transform: translateX(-26vw) translateY(3vh) scale(0.3);
    }

    88% {
      transform: translateX(46vw) translateY(-9vh) scale(0.55);
    }

    92% {
      transform: translateX(33vw) translateY(-15vh) scale(0.66);
    }

    96% {
      transform: translateX(19vw) translateY(-3vh) scale(0.59);
    }

    100% {
      transform: translateX(-32vw) translateY(-18vh) scale(0.31);
    }
  }

  @keyframes drift {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes flash {

    0%,
    30%,
    100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw yellow;
    }

    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw yellow;
    }
  }


  .stickyBtn2 {
    width: 65px;
    height: 65px;
    opacity: 0.5;
    transition: 0.5s ease;
    z-index: 1;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
     z-index: 1;
    transition: transform .4s;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .littleNav{
    display: none;
  }

  .miniBarMain{
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background-color: black;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .containerMob {
    display: none;
  }

  .cont {
    background: radial-gradient(circle, rgba(13, 24, 31, 0.2777485994397759) 0%, rgba(13, 24, 31, 0.48783263305322133) 69%, rgba(13, 24, 31, 0.8743872549019608) 100%), url('./assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .in2 p {
    font-size: 20px;
    color: white;
    font-weight: bold;
    text-align: center;
    font-family: "Rise of Kingdom";
    letter-spacing: 4px;
  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 30%;
  }

  .introduction {
    padding-top: 15px;
    font-family: "Rise of Kingdom";
  }


  .container {
    max-width: 550px;
    max-height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
  }


  .container:hover img {
    opacity: 0.3;
  }

  .container img:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  .container img {
    width: 40%;
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
    box-shadow: 0 0 10px #d44e00;
    border: 2px solid #d6985c;
    z-index: 10000;

  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .ag-format-container {
    width: 1142px;
    margin: 0 auto;
  }

  .ag-sparks {
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    position: relative;
  }

  .ag-sparks:before,
  .ag-sparks:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ag-sparks:before {
    content: "";

    -webkit-animation: an-blink 30s linear 3s infinite;
    -moz-animation: an-blink 30s linear 3s infinite;
    -o-animation: an-blink 30s linear 3s infinite;
    animation: an-blink 30s linear 3s infinite;
  }

  .ag-sparks:after {
    content: "";

    z-index: 10;
  }

  .ag-spark {
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    position: relative;
  }

  .ag-spark:before,
  .ag-spark:after {
    content: "";
    background-image: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/sparks/images/spark.png);
    background-position-x: 50%;
    background-repeat: repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    opacity: 0;

    will-change: transform;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ag-spark:before {
    -webkit-animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
    -moz-animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
    -o-animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
    animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
  }

  .ag-spark:after {
    -webkit-animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
    -moz-animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
    -o-animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
    animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
  }

  .ag-lightning {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ag-lightning:before,
  .ag-lightning:after {
    content: "";

    opacity: 0;

    will-change: transform;

    position: absolute;
  }

  .ag-lightning:before {
    width: 272px;
    background-image: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/sparks/images/lightning-1.png);

    top: -9.75em;
    left: 50%;

    -webkit-animation: an-lightning-first 30s linear 3s infinite;
    -moz-animation: an-lightning-first 30s linear 3s infinite;
    -o-animation: an-lightning-first 30s linear 3s infinite;
    animation: an-lightning-first 30s linear 3s infinite;
  }

  .ag-lightning:after {
    width: 254px;
    background-image: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/sparks/images/lightning-1.png);

    top: -10.1875em;
    right: -6.1875em;

    -webkit-animation: an-lightning-second 30s linear 3s infinite;
    -moz-animation: an-lightning-second 30s linear 3s infinite;
    -o-animation: an-lightning-second 30s linear 3s infinite;
    animation: an-lightning-second 30s linear 3s infinite;
  }

  @-webkit-keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @-moz-keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @-o-keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @-webkit-keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @-moz-keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @-o-keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @-webkit-keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @-moz-keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      -moz-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      -moz-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @-o-keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      -o-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      -o-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      -moz-transform: translateX(1.25em);
      -o-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      -moz-transform: translateX(-1.25em);
      -o-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @-webkit-keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @-moz-keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      -moz-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      -moz-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @-o-keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      -o-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      -o-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      -moz-transform: scale(-1, 1) translateX(1.25em);
      -o-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      -moz-transform: scale(-1, 1) translateX(-1.25em);
      -o-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @-webkit-keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @-moz-keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @-o-keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @-moz-keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @-o-keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @media only screen and (max-width: 767px) {
    .ag-format-container {
      width: 96%;
    }

  }

  @media only screen and (max-width: 639px) {}

  @media only screen and (max-width: 479px) {}

  @media (min-width: 768px) and (max-width: 979px) {
    .ag-format-container {
      width: 750px;
    }

  }

  @media (min-width: 980px) and (max-width: 1161px) {
    .ag-format-container {
      width: 960px;
    }

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: linear-gradient(87deg, rgba(0, 0, 0, 0.1956954656862745) 0%, rgba(0, 0, 0, 0.6606814600840336) 35%, rgba(0, 0, 0, 0.8819699754901961) 100%), url('./assets/map.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 80px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyHK {
    font-size: 80px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: left;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .storyH2-2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-between;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }


  .map {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    background: rgba(19, 19, 19, 0.767);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #e2e2e2 2px ridge;
    box-shadow: 0 0 15px #704531;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .faqbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13126969537815125) 0%, rgba(0, 0, 0, 0.3385526085434174) 35%, rgba(0, 0, 0, 0.7979363620448179) 100%), url('./assets/faqbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .team {
    width: 60%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .teambg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13126969537815125) 0%, rgba(0, 0, 0, 0.3385526085434174) 35%, rgba(0, 0, 0, 0.7979363620448179) 100%), url('./assets/teambg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
  }

  .kingdomsBg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
    padding-top: 50px;
  }

  .kingdomsSection1 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/lionKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection2 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/raakshaKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection3 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/snakeKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection4 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/eagleKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection5 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/dragonKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .logoF {
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #ff0, 0 -10px 20px #ff8000, 0 -18px 40px #F00;
    color: white;
    font-size: 52px
  }

  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(250, 219, 207);
    text-align: center;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fd2;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connect div:hover {
    color: rgb(255, 185, 138);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform .2s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 18px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;

  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #99faf500;
    font-size: 18px;
    padding: 10px;
    width: 200px;
    border: 2px rgb(216, 179, 130) solid;
    color: rgb(255, 255, 255);
    box-shadow: 0 0 10px #b18a41;
    transition: 0.3s ease;
    cursor: none;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  /*........................Roadmap........................*/

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 45px;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #eeb38b;
    color: white;
    font-family: "Rise of Kingdom";

  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 20px;

  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #f2b14b;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    border: 5px solid rgba(63, 63, 63, 0.253);
    left: 48px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #f2b14b;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #f2b14b;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid rgba(63, 63, 63, 0.253);

  }

  .footPrint2 {
    width: 60px;
    height: 60px;
  }

  .rmbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13126969537815125) 0%, rgba(0, 0, 0, 0.3385526085434174) 35%, rgba(0, 0, 0, 0.7979363620448179) 100%), url('./assets/rmbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  h4 {
    text-transform: capitalize;
    background-color: rgba(0, 0, 0, 0.384);
    color: white;
    padding: 10px;
    box-shadow: 0 0 15px #f2b24b62;
  }

  .fire {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }

  .fireGlow{
    animation: glow 2s ease-in-out infinite alternate;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }

  .firefly {
    position: sticky;
    position: -webkit-sticky;
    left: 50%;
    top: 50%;
    width: 0.4vw;
    height: 0.4vw;
    margin: -0.2vw 0 0 9.8vw;
    animation: ease 200s alternate infinite;
    pointer-events: none;
  }

  .firefly::before,
  .firefly::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: -10vw;
  }

  .firefly::before {
    background: black;
    opacity: 0.4;
    animation: drift ease alternate infinite;
  }

  .firefly::after {
    background: white;
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow;
    animation: drift ease alternate infinite, flash ease infinite;
  }

  .firefly:nth-child(1) {
    animation-name: move1;
  }

  .firefly:nth-child(1)::before {
    animation-duration: 10s;
  }

  .firefly:nth-child(1)::after {
    animation-duration: 10s, 5869ms;
    animation-delay: 0ms, 5103ms;
  }

  @keyframes move1 {
    0% {
      transform: translateX(-41vw) translateY(23vh) scale(0.42);
    }

    5% {
      transform: translateX(9vw) translateY(-45vh) scale(0.34);
    }

    10% {
      transform: translateX(-17vw) translateY(-42vh) scale(1);
    }

    15% {
      transform: translateX(8vw) translateY(-23vh) scale(0.51);
    }

    20% {
      transform: translateX(-29vw) translateY(-20vh) scale(0.56);
    }

    25% {
      transform: translateX(8vw) translateY(16vh) scale(0.79);
    }

    30% {
      transform: translateX(11vw) translateY(46vh) scale(0.54);
    }

    35% {
      transform: translateX(9vw) translateY(-45vh) scale(0.58);
    }

    40% {
      transform: translateX(10vw) translateY(-37vh) scale(0.69);
    }

    45% {
      transform: translateX(5vw) translateY(26vh) scale(0.52);
    }

    50% {
      transform: translateX(-36vw) translateY(-39vh) scale(0.43);
    }

    55% {
      transform: translateX(-45vw) translateY(35vh) scale(0.96);
    }

    60% {
      transform: translateX(17vw) translateY(38vh) scale(0.66);
    }

    65% {
      transform: translateX(-39vw) translateY(-34vh) scale(0.75);
    }

    70% {
      transform: translateX(-45vw) translateY(18vh) scale(0.72);
    }

    75% {
      transform: translateX(48vw) translateY(21vh) scale(0.42);
    }

    80% {
      transform: translateX(28vw) translateY(-2vh) scale(0.82);
    }

    85% {
      transform: translateX(-29vw) translateY(30vh) scale(0.48);
    }

    90% {
      transform: translateX(12vw) translateY(16vh) scale(0.42);
    }

    95% {
      transform: translateX(-8vw) translateY(-24vh) scale(0.52);
    }

    100% {
      transform: translateX(-49vw) translateY(6vh) scale(0.34);
    }
  }

  .firefly:nth-child(2) {
    animation-name: move2;
  }

  .firefly:nth-child(2)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(2)::after {
    animation-duration: 13s, 8622ms;
    animation-delay: 0ms, 6050ms;
  }

  @keyframes move2 {
    0% {
      transform: translateX(-13vw) translateY(17vh) scale(0.94);
    }

    4.347826087% {
      transform: translateX(12vw) translateY(24vh) scale(0.48);
    }

    8.6956521739% {
      transform: translateX(16vw) translateY(-40vh) scale(0.39);
    }

    13.0434782609% {
      transform: translateX(22vw) translateY(22vh) scale(0.68);
    }

    17.3913043478% {
      transform: translateX(19vw) translateY(-14vh) scale(0.42);
    }

    21.7391304348% {
      transform: translateX(2vw) translateY(-18vh) scale(0.3);
    }

    26.0869565217% {
      transform: translateX(2vw) translateY(38vh) scale(0.29);
    }

    30.4347826087% {
      transform: translateX(28vw) translateY(6vh) scale(0.87);
    }

    34.7826086957% {
      transform: translateX(-48vw) translateY(21vh) scale(0.88);
    }

    39.1304347826% {
      transform: translateX(32vw) translateY(15vh) scale(0.76);
    }

    43.4782608696% {
      transform: translateX(32vw) translateY(1vh) scale(0.31);
    }

    47.8260869565% {
      transform: translateX(35vw) translateY(0vh) scale(0.29);
    }

    52.1739130435% {
      transform: translateX(9vw) translateY(14vh) scale(0.27);
    }

    56.5217391304% {
      transform: translateX(12vw) translateY(-20vh) scale(0.64);
    }

    60.8695652174% {
      transform: translateX(-21vw) translateY(27vh) scale(0.72);
    }

    65.2173913043% {
      transform: translateX(-47vw) translateY(-33vh) scale(0.31);
    }

    69.5652173913% {
      transform: translateX(-25vw) translateY(7vh) scale(0.85);
    }

    73.9130434783% {
      transform: translateX(42vw) translateY(38vh) scale(0.88);
    }

    78.2608695652% {
      transform: translateX(11vw) translateY(10vh) scale(0.91);
    }

    82.6086956522% {
      transform: translateX(2vw) translateY(6vh) scale(0.59);
    }

    86.9565217391% {
      transform: translateX(37vw) translateY(4vh) scale(0.59);
    }

    91.3043478261% {
      transform: translateX(-34vw) translateY(-32vh) scale(0.95);
    }

    95.652173913% {
      transform: translateX(-47vw) translateY(32vh) scale(0.81);
    }

    100% {
      transform: translateX(32vw) translateY(22vh) scale(0.53);
    }
  }

  .firefly:nth-child(3) {
    animation-name: move3;
  }

  .firefly:nth-child(3)::before {
    animation-duration: 14s;
  }

  .firefly:nth-child(3)::after {
    animation-duration: 14s, 7972ms;
    animation-delay: 0ms, 2219ms;
  }

  @keyframes move3 {
    0% {
      transform: translateX(-9vw) translateY(-12vh) scale(0.35);
    }

    4% {
      transform: translateX(9vw) translateY(45vh) scale(0.75);
    }

    8% {
      transform: translateX(37vw) translateY(-35vh) scale(0.42);
    }

    12% {
      transform: translateX(-49vw) translateY(5vh) scale(0.46);
    }

    16% {
      transform: translateX(42vw) translateY(26vh) scale(0.34);
    }

    20% {
      transform: translateX(-12vw) translateY(19vh) scale(0.84);
    }

    24% {
      transform: translateX(23vw) translateY(6vh) scale(0.45);
    }

    28% {
      transform: translateX(18vw) translateY(-16vh) scale(0.47);
    }

    32% {
      transform: translateX(22vw) translateY(-40vh) scale(0.45);
    }

    36% {
      transform: translateX(-37vw) translateY(33vh) scale(0.75);
    }

    40% {
      transform: translateX(44vw) translateY(33vh) scale(0.61);
    }

    44% {
      transform: translateX(-44vw) translateY(-18vh) scale(0.57);
    }

    48% {
      transform: translateX(-9vw) translateY(39vh) scale(0.45);
    }

    52% {
      transform: translateX(10vw) translateY(41vh) scale(0.51);
    }

    56% {
      transform: translateX(23vw) translateY(-41vh) scale(0.31);
    }

    60% {
      transform: translateX(48vw) translateY(-5vh) scale(0.33);
    }

    64% {
      transform: translateX(-49vw) translateY(-43vh) scale(0.3);
    }

    68% {
      transform: translateX(-39vw) translateY(5vh) scale(0.44);
    }

    72% {
      transform: translateX(-18vw) translateY(-28vh) scale(0.94);
    }

    76% {
      transform: translateX(22vw) translateY(-18vh) scale(0.67);
    }

    80% {
      transform: translateX(10vw) translateY(-32vh) scale(0.56);
    }

    84% {
      transform: translateX(-7vw) translateY(-48vh) scale(0.47);
    }

    88% {
      transform: translateX(30vw) translateY(37vh) scale(0.64);
    }

    92% {
      transform: translateX(-46vw) translateY(-37vh) scale(0.53);
    }

    96% {
      transform: translateX(9vw) translateY(40vh) scale(0.87);
    }

    100% {
      transform: translateX(-48vw) translateY(-17vh) scale(0.46);
    }
  }

  .firefly:nth-child(4) {
    animation-name: move4;
  }

  .firefly:nth-child(4)::before {
    animation-duration: 10s;
  }

  .firefly:nth-child(4)::after {
    animation-duration: 10s, 8866ms;
    animation-delay: 0ms, 4122ms;
  }

  @keyframes move4 {
    0% {
      transform: translateX(-1vw) translateY(17vh) scale(0.76);
    }

    5.5555555556% {
      transform: translateX(-20vw) translateY(-6vh) scale(0.96);
    }

    11.1111111111% {
      transform: translateX(7vw) translateY(27vh) scale(0.44);
    }

    16.6666666667% {
      transform: translateX(1vw) translateY(32vh) scale(0.75);
    }

    22.2222222222% {
      transform: translateX(48vw) translateY(-14vh) scale(0.83);
    }

    27.7777777778% {
      transform: translateX(-34vw) translateY(-30vh) scale(0.6);
    }

    33.3333333333% {
      transform: translateX(40vw) translateY(-27vh) scale(1);
    }

    38.8888888889% {
      transform: translateX(-14vw) translateY(-28vh) scale(0.29);
    }

    44.4444444444% {
      transform: translateX(37vw) translateY(-14vh) scale(0.56);
    }

    50% {
      transform: translateX(29vw) translateY(8vh) scale(0.37);
    }

    55.5555555556% {
      transform: translateX(-14vw) translateY(21vh) scale(0.39);
    }

    61.1111111111% {
      transform: translateX(-1vw) translateY(38vh) scale(0.64);
    }

    66.6666666667% {
      transform: translateX(40vw) translateY(-17vh) scale(0.78);
    }

    72.2222222222% {
      transform: translateX(-6vw) translateY(-45vh) scale(0.54);
    }

    77.7777777778% {
      transform: translateX(-47vw) translateY(-46vh) scale(0.38);
    }

    83.3333333333% {
      transform: translateX(38vw) translateY(-13vh) scale(0.86);
    }

    88.8888888889% {
      transform: translateX(-8vw) translateY(35vh) scale(0.39);
    }

    94.4444444444% {
      transform: translateX(-25vw) translateY(-1vh) scale(0.44);
    }

    100% {
      transform: translateX(0vw) translateY(-34vh) scale(0.57);
    }
  }

  .firefly:nth-child(5) {
    animation-name: move5;
  }

  .firefly:nth-child(5)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(5)::after {
    animation-duration: 18s, 6640ms;
    animation-delay: 0ms, 1937ms;
  }

  @keyframes move5 {
    0% {
      transform: translateX(33vw) translateY(-14vh) scale(0.83);
    }

    4.5454545455% {
      transform: translateX(-14vw) translateY(6vh) scale(0.26);
    }

    9.0909090909% {
      transform: translateX(-15vw) translateY(-12vh) scale(0.56);
    }

    13.6363636364% {
      transform: translateX(39vw) translateY(20vh) scale(0.69);
    }

    18.1818181818% {
      transform: translateX(-21vw) translateY(-1vh) scale(0.27);
    }

    22.7272727273% {
      transform: translateX(-38vw) translateY(47vh) scale(0.43);
    }

    27.2727272727% {
      transform: translateX(10vw) translateY(-4vh) scale(0.53);
    }

    31.8181818182% {
      transform: translateX(-13vw) translateY(21vh) scale(0.88);
    }

    36.3636363636% {
      transform: translateX(-3vw) translateY(46vh) scale(0.76);
    }

    40.9090909091% {
      transform: translateX(-2vw) translateY(-11vh) scale(0.32);
    }

    45.4545454545% {
      transform: translateX(14vw) translateY(4vh) scale(0.32);
    }

    50% {
      transform: translateX(8vw) translateY(8vh) scale(0.76);
    }

    54.5454545455% {
      transform: translateX(18vw) translateY(-35vh) scale(0.6);
    }

    59.0909090909% {
      transform: translateX(15vw) translateY(-43vh) scale(0.28);
    }

    63.6363636364% {
      transform: translateX(-12vw) translateY(12vh) scale(0.68);
    }

    68.1818181818% {
      transform: translateX(5vw) translateY(25vh) scale(0.96);
    }

    72.7272727273% {
      transform: translateX(-21vw) translateY(-35vh) scale(0.4);
    }

    77.2727272727% {
      transform: translateX(-44vw) translateY(7vh) scale(0.98);
    }

    81.8181818182% {
      transform: translateX(40vw) translateY(-29vh) scale(0.91);
    }

    86.3636363636% {
      transform: translateX(30vw) translateY(-36vh) scale(0.49);
    }

    90.9090909091% {
      transform: translateX(48vw) translateY(22vh) scale(0.67);
    }

    95.4545454545% {
      transform: translateX(18vw) translateY(-10vh) scale(0.77);
    }

    100% {
      transform: translateX(-40vw) translateY(-6vh) scale(0.91);
    }
  }

  .firefly:nth-child(6) {
    animation-name: move6;
  }

  .firefly:nth-child(6)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(6)::after {
    animation-duration: 15s, 8079ms;
    animation-delay: 0ms, 6703ms;
  }

  @keyframes move6 {
    0% {
      transform: translateX(7vw) translateY(-40vh) scale(0.74);
    }

    4.7619047619% {
      transform: translateX(-23vw) translateY(32vh) scale(0.7);
    }

    9.5238095238% {
      transform: translateX(-9vw) translateY(0vh) scale(0.34);
    }

    14.2857142857% {
      transform: translateX(-36vw) translateY(18vh) scale(0.65);
    }

    19.0476190476% {
      transform: translateX(37vw) translateY(-8vh) scale(0.84);
    }

    23.8095238095% {
      transform: translateX(7vw) translateY(27vh) scale(0.44);
    }

    28.5714285714% {
      transform: translateX(-3vw) translateY(8vh) scale(0.93);
    }

    33.3333333333% {
      transform: translateX(-18vw) translateY(5vh) scale(0.87);
    }

    38.0952380952% {
      transform: translateX(25vw) translateY(19vh) scale(0.45);
    }

    42.8571428571% {
      transform: translateX(46vw) translateY(14vh) scale(0.95);
    }

    47.619047619% {
      transform: translateX(13vw) translateY(-10vh) scale(0.5);
    }

    52.380952381% {
      transform: translateX(15vw) translateY(11vh) scale(0.4);
    }

    57.1428571429% {
      transform: translateX(15vw) translateY(-35vh) scale(0.96);
    }

    61.9047619048% {
      transform: translateX(-13vw) translateY(41vh) scale(0.35);
    }

    66.6666666667% {
      transform: translateX(-23vw) translateY(26vh) scale(0.9);
    }

    71.4285714286% {
      transform: translateX(-7vw) translateY(4vh) scale(0.81);
    }

    76.1904761905% {
      transform: translateX(-49vw) translateY(-18vh) scale(0.84);
    }

    80.9523809524% {
      transform: translateX(26vw) translateY(33vh) scale(0.43);
    }

    85.7142857143% {
      transform: translateX(-6vw) translateY(23vh) scale(0.48);
    }

    90.4761904762% {
      transform: translateX(46vw) translateY(-5vh) scale(0.8);
    }

    95.2380952381% {
      transform: translateX(42vw) translateY(33vh) scale(0.6);
    }

    100% {
      transform: translateX(-28vw) translateY(26vh) scale(0.64);
    }
  }

  .firefly:nth-child(7) {
    animation-name: move7;
  }

  .firefly:nth-child(7)::before {
    animation-duration: 17s;
  }

  .firefly:nth-child(7)::after {
    animation-duration: 17s, 9827ms;
    animation-delay: 0ms, 3278ms;
  }

  @keyframes move7 {
    0% {
      transform: translateX(-1vw) translateY(41vh) scale(0.51);
    }

    5.5555555556% {
      transform: translateX(38vw) translateY(32vh) scale(0.61);
    }

    11.1111111111% {
      transform: translateX(36vw) translateY(44vh) scale(0.89);
    }

    16.6666666667% {
      transform: translateX(-22vw) translateY(-36vh) scale(0.68);
    }

    22.2222222222% {
      transform: translateX(4vw) translateY(-2vh) scale(0.87);
    }

    27.7777777778% {
      transform: translateX(11vw) translateY(-5vh) scale(0.48);
    }

    33.3333333333% {
      transform: translateX(-30vw) translateY(28vh) scale(0.43);
    }

    38.8888888889% {
      transform: translateX(-38vw) translateY(-4vh) scale(0.84);
    }

    44.4444444444% {
      transform: translateX(-29vw) translateY(33vh) scale(0.43);
    }

    50% {
      transform: translateX(1vw) translateY(-4vh) scale(0.96);
    }

    55.5555555556% {
      transform: translateX(38vw) translateY(-23vh) scale(0.5);
    }

    61.1111111111% {
      transform: translateX(-47vw) translateY(-1vh) scale(0.91);
    }

    66.6666666667% {
      transform: translateX(40vw) translateY(-8vh) scale(0.52);
    }

    72.2222222222% {
      transform: translateX(26vw) translateY(14vh) scale(0.83);
    }

    77.7777777778% {
      transform: translateX(17vw) translateY(-48vh) scale(0.97);
    }

    83.3333333333% {
      transform: translateX(-22vw) translateY(45vh) scale(0.85);
    }

    88.8888888889% {
      transform: translateX(-21vw) translateY(-9vh) scale(0.45);
    }

    94.4444444444% {
      transform: translateX(39vw) translateY(38vh) scale(0.59);
    }

    100% {
      transform: translateX(-37vw) translateY(-2vh) scale(0.55);
    }
  }

  .firefly:nth-child(8) {
    animation-name: move8;
  }

  .firefly:nth-child(8)::before {
    animation-duration: 9s;
  }

  .firefly:nth-child(8)::after {
    animation-duration: 9s, 8380ms;
    animation-delay: 0ms, 4603ms;
  }

  @keyframes move8 {
    0% {
      transform: translateX(-17vw) translateY(30vh) scale(0.58);
    }

    5.8823529412% {
      transform: translateX(-13vw) translateY(-42vh) scale(0.82);
    }

    11.7647058824% {
      transform: translateX(-10vw) translateY(-38vh) scale(0.86);
    }

    17.6470588235% {
      transform: translateX(49vw) translateY(47vh) scale(0.97);
    }

    23.5294117647% {
      transform: translateX(-17vw) translateY(-44vh) scale(1);
    }

    29.4117647059% {
      transform: translateX(-6vw) translateY(-20vh) scale(0.5);
    }

    35.2941176471% {
      transform: translateX(-39vw) translateY(-34vh) scale(0.33);
    }

    41.1764705882% {
      transform: translateX(38vw) translateY(49vh) scale(0.45);
    }

    47.0588235294% {
      transform: translateX(43vw) translateY(39vh) scale(0.43);
    }

    52.9411764706% {
      transform: translateX(46vw) translateY(-4vh) scale(0.27);
    }

    58.8235294118% {
      transform: translateX(-16vw) translateY(-20vh) scale(0.71);
    }

    64.7058823529% {
      transform: translateX(-43vw) translateY(-40vh) scale(0.84);
    }

    70.5882352941% {
      transform: translateX(-10vw) translateY(13vh) scale(0.81);
    }

    76.4705882353% {
      transform: translateX(38vw) translateY(1vh) scale(0.36);
    }

    82.3529411765% {
      transform: translateX(32vw) translateY(-40vh) scale(0.71);
    }

    88.2352941176% {
      transform: translateX(0vw) translateY(-49vh) scale(0.32);
    }

    94.1176470588% {
      transform: translateX(12vw) translateY(-49vh) scale(0.29);
    }

    100% {
      transform: translateX(7vw) translateY(-3vh) scale(0.94);
    }
  }

  .firefly:nth-child(9) {
    animation-name: move9;
  }

  .firefly:nth-child(9)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(9)::after {
    animation-duration: 18s, 10823ms;
    animation-delay: 0ms, 705ms;
  }

  @keyframes move9 {
    0% {
      transform: translateX(3vw) translateY(4vh) scale(0.41);
    }

    5.2631578947% {
      transform: translateX(-43vw) translateY(40vh) scale(0.79);
    }

    10.5263157895% {
      transform: translateX(-39vw) translateY(22vh) scale(0.38);
    }

    15.7894736842% {
      transform: translateX(-32vw) translateY(1vh) scale(0.41);
    }

    21.0526315789% {
      transform: translateX(-33vw) translateY(31vh) scale(0.81);
    }

    26.3157894737% {
      transform: translateX(2vw) translateY(-33vh) scale(0.43);
    }

    31.5789473684% {
      transform: translateX(-2vw) translateY(41vh) scale(0.78);
    }

    36.8421052632% {
      transform: translateX(-1vw) translateY(25vh) scale(0.69);
    }

    42.1052631579% {
      transform: translateX(-39vw) translateY(-28vh) scale(0.94);
    }

    47.3684210526% {
      transform: translateX(29vw) translateY(13vh) scale(0.42);
    }

    52.6315789474% {
      transform: translateX(42vw) translateY(17vh) scale(0.47);
    }

    57.8947368421% {
      transform: translateX(14vw) translateY(-9vh) scale(0.59);
    }

    63.1578947368% {
      transform: translateX(12vw) translateY(-8vh) scale(0.29);
    }

    68.4210526316% {
      transform: translateX(-31vw) translateY(-25vh) scale(0.81);
    }

    73.6842105263% {
      transform: translateX(-45vw) translateY(13vh) scale(0.33);
    }

    78.9473684211% {
      transform: translateX(-6vw) translateY(-48vh) scale(0.86);
    }

    84.2105263158% {
      transform: translateX(-18vw) translateY(-12vh) scale(0.99);
    }

    89.4736842105% {
      transform: translateX(22vw) translateY(-39vh) scale(0.51);
    }

    94.7368421053% {
      transform: translateX(43vw) translateY(-15vh) scale(0.6);
    }

    100% {
      transform: translateX(41vw) translateY(-14vh) scale(0.56);
    }
  }

  .firefly:nth-child(10) {
    animation-name: move10;
  }

  .firefly:nth-child(10)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(10)::after {
    animation-duration: 15s, 9714ms;
    animation-delay: 0ms, 7928ms;
  }

  @keyframes move10 {
    0% {
      transform: translateX(-5vw) translateY(25vh) scale(0.3);
    }

    3.8461538462% {
      transform: translateX(11vw) translateY(42vh) scale(0.81);
    }

    7.6923076923% {
      transform: translateX(-45vw) translateY(25vh) scale(0.44);
    }

    11.5384615385% {
      transform: translateX(23vw) translateY(-4vh) scale(0.43);
    }

    15.3846153846% {
      transform: translateX(10vw) translateY(12vh) scale(0.84);
    }

    19.2307692308% {
      transform: translateX(-33vw) translateY(38vh) scale(0.95);
    }

    23.0769230769% {
      transform: translateX(15vw) translateY(23vh) scale(0.56);
    }

    26.9230769231% {
      transform: translateX(-12vw) translateY(-17vh) scale(0.42);
    }

    30.7692307692% {
      transform: translateX(1vw) translateY(-34vh) scale(0.61);
    }

    34.6153846154% {
      transform: translateX(22vw) translateY(-25vh) scale(0.32);
    }

    38.4615384615% {
      transform: translateX(-17vw) translateY(26vh) scale(0.65);
    }

    42.3076923077% {
      transform: translateX(3vw) translateY(29vh) scale(0.91);
    }

    46.1538461538% {
      transform: translateX(-12vw) translateY(-36vh) scale(0.64);
    }

    50% {
      transform: translateX(-44vw) translateY(41vh) scale(0.44);
    }

    53.8461538462% {
      transform: translateX(9vw) translateY(-29vh) scale(0.6);
    }

    57.6923076923% {
      transform: translateX(22vw) translateY(30vh) scale(0.98);
    }

    61.5384615385% {
      transform: translateX(-20vw) translateY(33vh) scale(0.7);
    }

    65.3846153846% {
      transform: translateX(40vw) translateY(24vh) scale(0.38);
    }

    69.2307692308% {
      transform: translateX(-19vw) translateY(4vh) scale(0.48);
    }

    73.0769230769% {
      transform: translateX(-18vw) translateY(-32vh) scale(1);
    }

    76.9230769231% {
      transform: translateX(17vw) translateY(1vh) scale(0.68);
    }

    80.7692307692% {
      transform: translateX(-40vw) translateY(-16vh) scale(0.92);
    }

    84.6153846154% {
      transform: translateX(17vw) translateY(32vh) scale(0.9);
    }

    88.4615384615% {
      transform: translateX(14vw) translateY(45vh) scale(0.8);
    }

    92.3076923077% {
      transform: translateX(14vw) translateY(50vh) scale(0.74);
    }

    96.1538461538% {
      transform: translateX(26vw) translateY(47vh) scale(0.83);
    }

    100% {
      transform: translateX(49vw) translateY(-40vh) scale(0.95);
    }
  }

  .firefly:nth-child(11) {
    animation-name: move11;
  }

  .firefly:nth-child(11)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(11)::after {
    animation-duration: 13s, 7476ms;
    animation-delay: 0ms, 5436ms;
  }

  @keyframes move11 {
    0% {
      transform: translateX(-7vw) translateY(16vh) scale(0.42);
    }

    4.1666666667% {
      transform: translateX(-44vw) translateY(14vh) scale(0.89);
    }

    8.3333333333% {
      transform: translateX(17vw) translateY(-25vh) scale(0.72);
    }

    12.5% {
      transform: translateX(-40vw) translateY(-22vh) scale(0.47);
    }

    16.6666666667% {
      transform: translateX(49vw) translateY(12vh) scale(0.93);
    }

    20.8333333333% {
      transform: translateX(-45vw) translateY(21vh) scale(0.6);
    }

    25% {
      transform: translateX(36vw) translateY(-41vh) scale(0.48);
    }

    29.1666666667% {
      transform: translateX(-9vw) translateY(0vh) scale(0.98);
    }

    33.3333333333% {
      transform: translateX(-32vw) translateY(37vh) scale(0.29);
    }

    37.5% {
      transform: translateX(31vw) translateY(-46vh) scale(0.34);
    }

    41.6666666667% {
      transform: translateX(21vw) translateY(-45vh) scale(0.51);
    }

    45.8333333333% {
      transform: translateX(-12vw) translateY(-46vh) scale(0.87);
    }

    50% {
      transform: translateX(25vw) translateY(28vh) scale(0.48);
    }

    54.1666666667% {
      transform: translateX(-16vw) translateY(-25vh) scale(0.76);
    }

    58.3333333333% {
      transform: translateX(-4vw) translateY(48vh) scale(0.63);
    }

    62.5% {
      transform: translateX(48vw) translateY(-32vh) scale(0.31);
    }

    66.6666666667% {
      transform: translateX(-39vw) translateY(-39vh) scale(0.56);
    }

    70.8333333333% {
      transform: translateX(-1vw) translateY(37vh) scale(0.5);
    }

    75% {
      transform: translateX(-9vw) translateY(-5vh) scale(0.56);
    }

    79.1666666667% {
      transform: translateX(-11vw) translateY(13vh) scale(0.96);
    }

    83.3333333333% {
      transform: translateX(-1vw) translateY(-41vh) scale(0.55);
    }

    87.5% {
      transform: translateX(17vw) translateY(18vh) scale(0.58);
    }

    91.6666666667% {
      transform: translateX(-12vw) translateY(10vh) scale(0.99);
    }

    95.8333333333% {
      transform: translateX(33vw) translateY(50vh) scale(0.85);
    }

    100% {
      transform: translateX(43vw) translateY(38vh) scale(0.52);
    }
  }

  .firefly:nth-child(12) {
    animation-name: move12;
  }

  .firefly:nth-child(12)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(12)::after {
    animation-duration: 13s, 5428ms;
    animation-delay: 0ms, 4447ms;
  }

  @keyframes move12 {
    0% {
      transform: translateX(19vw) translateY(-25vh) scale(0.71);
    }

    3.7037037037% {
      transform: translateX(5vw) translateY(-44vh) scale(0.68);
    }

    7.4074074074% {
      transform: translateX(-11vw) translateY(21vh) scale(0.96);
    }

    11.1111111111% {
      transform: translateX(-16vw) translateY(-35vh) scale(0.62);
    }

    14.8148148148% {
      transform: translateX(19vw) translateY(36vh) scale(0.51);
    }

    18.5185185185% {
      transform: translateX(-31vw) translateY(39vh) scale(0.91);
    }

    22.2222222222% {
      transform: translateX(-13vw) translateY(-33vh) scale(0.57);
    }

    25.9259259259% {
      transform: translateX(-11vw) translateY(-43vh) scale(0.91);
    }

    29.6296296296% {
      transform: translateX(6vw) translateY(-23vh) scale(0.35);
    }

    33.3333333333% {
      transform: translateX(50vw) translateY(-5vh) scale(1);
    }

    37.037037037% {
      transform: translateX(-42vw) translateY(0vh) scale(0.98);
    }

    40.7407407407% {
      transform: translateX(35vw) translateY(40vh) scale(0.97);
    }

    44.4444444444% {
      transform: translateX(-6vw) translateY(-30vh) scale(0.28);
    }

    48.1481481481% {
      transform: translateX(7vw) translateY(0vh) scale(0.85);
    }

    51.8518518519% {
      transform: translateX(-29vw) translateY(-42vh) scale(0.82);
    }

    55.5555555556% {
      transform: translateX(-13vw) translateY(-39vh) scale(0.93);
    }

    59.2592592593% {
      transform: translateX(-16vw) translateY(43vh) scale(0.99);
    }

    62.962962963% {
      transform: translateX(19vw) translateY(38vh) scale(0.61);
    }

    66.6666666667% {
      transform: translateX(13vw) translateY(26vh) scale(0.75);
    }

    70.3703703704% {
      transform: translateX(-3vw) translateY(-23vh) scale(0.7);
    }

    74.0740740741% {
      transform: translateX(-19vw) translateY(3vh) scale(0.93);
    }

    77.7777777778% {
      transform: translateX(8vw) translateY(-18vh) scale(0.77);
    }

    81.4814814815% {
      transform: translateX(-23vw) translateY(-12vh) scale(0.88);
    }

    85.1851851852% {
      transform: translateX(29vw) translateY(-27vh) scale(0.75);
    }

    88.8888888889% {
      transform: translateX(-28vw) translateY(-32vh) scale(0.46);
    }

    92.5925925926% {
      transform: translateX(-40vw) translateY(11vh) scale(0.54);
    }

    96.2962962963% {
      transform: translateX(19vw) translateY(3vh) scale(0.86);
    }

    100% {
      transform: translateX(35vw) translateY(-45vh) scale(0.73);
    }
  }

  .firefly:nth-child(13) {
    animation-name: move13;
  }

  .firefly:nth-child(13)::before {
    animation-duration: 12s;
  }

  .firefly:nth-child(13)::after {
    animation-duration: 12s, 10857ms;
    animation-delay: 0ms, 8399ms;
  }

  @keyframes move13 {
    0% {
      transform: translateX(-20vw) translateY(28vh) scale(0.31);
    }

    5.2631578947% {
      transform: translateX(10vw) translateY(-35vh) scale(0.91);
    }

    10.5263157895% {
      transform: translateX(-12vw) translateY(7vh) scale(0.65);
    }

    15.7894736842% {
      transform: translateX(-35vw) translateY(-3vh) scale(0.56);
    }

    21.0526315789% {
      transform: translateX(-24vw) translateY(-16vh) scale(0.35);
    }

    26.3157894737% {
      transform: translateX(0vw) translateY(20vh) scale(0.57);
    }

    31.5789473684% {
      transform: translateX(-45vw) translateY(-3vh) scale(0.77);
    }

    36.8421052632% {
      transform: translateX(37vw) translateY(36vh) scale(0.7);
    }

    42.1052631579% {
      transform: translateX(-9vw) translateY(8vh) scale(0.92);
    }

    47.3684210526% {
      transform: translateX(-14vw) translateY(-41vh) scale(0.29);
    }

    52.6315789474% {
      transform: translateX(-12vw) translateY(-35vh) scale(0.71);
    }

    57.8947368421% {
      transform: translateX(-3vw) translateY(-21vh) scale(0.48);
    }

    63.1578947368% {
      transform: translateX(12vw) translateY(6vh) scale(0.8);
    }

    68.4210526316% {
      transform: translateX(16vw) translateY(-5vh) scale(0.75);
    }

    73.6842105263% {
      transform: translateX(-39vw) translateY(-43vh) scale(0.68);
    }

    78.9473684211% {
      transform: translateX(35vw) translateY(-46vh) scale(0.3);
    }

    84.2105263158% {
      transform: translateX(35vw) translateY(-45vh) scale(0.83);
    }

    89.4736842105% {
      transform: translateX(-8vw) translateY(13vh) scale(0.39);
    }

    94.7368421053% {
      transform: translateX(-12vw) translateY(32vh) scale(0.45);
    }

    100% {
      transform: translateX(-14vw) translateY(42vh) scale(0.67);
    }
  }

  .firefly:nth-child(14) {
    animation-name: move14;
  }

  .firefly:nth-child(14)::before {
    animation-duration: 11s;
  }

  .firefly:nth-child(14)::after {
    animation-duration: 11s, 7829ms;
    animation-delay: 0ms, 5814ms;
  }

  @keyframes move14 {
    0% {
      transform: translateX(10vw) translateY(-18vh) scale(0.99);
    }

    5.8823529412% {
      transform: translateX(7vw) translateY(18vh) scale(0.53);
    }

    11.7647058824% {
      transform: translateX(-38vw) translateY(3vh) scale(0.96);
    }

    17.6470588235% {
      transform: translateX(22vw) translateY(-38vh) scale(0.49);
    }

    23.5294117647% {
      transform: translateX(8vw) translateY(-38vh) scale(0.41);
    }

    29.4117647059% {
      transform: translateX(34vw) translateY(26vh) scale(0.7);
    }

    35.2941176471% {
      transform: translateX(-7vw) translateY(-3vh) scale(0.52);
    }

    41.1764705882% {
      transform: translateX(-43vw) translateY(45vh) scale(0.43);
    }

    47.0588235294% {
      transform: translateX(47vw) translateY(9vh) scale(0.89);
    }

    52.9411764706% {
      transform: translateX(45vw) translateY(32vh) scale(0.77);
    }

    58.8235294118% {
      transform: translateX(8vw) translateY(16vh) scale(0.64);
    }

    64.7058823529% {
      transform: translateX(-45vw) translateY(-38vh) scale(0.92);
    }

    70.5882352941% {
      transform: translateX(-6vw) translateY(-40vh) scale(0.64);
    }

    76.4705882353% {
      transform: translateX(29vw) translateY(-27vh) scale(0.83);
    }

    82.3529411765% {
      transform: translateX(4vw) translateY(10vh) scale(0.86);
    }

    88.2352941176% {
      transform: translateX(-8vw) translateY(43vh) scale(0.91);
    }

    94.1176470588% {
      transform: translateX(33vw) translateY(10vh) scale(0.57);
    }

    100% {
      transform: translateX(-17vw) translateY(14vh) scale(0.83);
    }
  }

  .firefly:nth-child(15) {
    animation-name: move15;
  }

  .firefly:nth-child(15)::before {
    animation-duration: 14s;
  }

  .firefly:nth-child(15)::after {
    animation-duration: 14s, 8455ms;
    animation-delay: 0ms, 1227ms;
  }

  @keyframes move15 {
    0% {
      transform: translateX(12vw) translateY(-1vh) scale(0.28);
    }

    4% {
      transform: translateX(6vw) translateY(-14vh) scale(0.61);
    }

    8% {
      transform: translateX(9vw) translateY(-13vh) scale(0.51);
    }

    12% {
      transform: translateX(26vw) translateY(14vh) scale(0.81);
    }

    16% {
      transform: translateX(-32vw) translateY(7vh) scale(0.99);
    }

    20% {
      transform: translateX(19vw) translateY(10vh) scale(0.27);
    }

    24% {
      transform: translateX(-13vw) translateY(-10vh) scale(0.83);
    }

    28% {
      transform: translateX(-35vw) translateY(-2vh) scale(0.39);
    }

    32% {
      transform: translateX(-28vw) translateY(-13vh) scale(0.82);
    }

    36% {
      transform: translateX(-45vw) translateY(28vh) scale(0.48);
    }

    40% {
      transform: translateX(-5vw) translateY(42vh) scale(0.74);
    }

    44% {
      transform: translateX(-30vw) translateY(22vh) scale(0.44);
    }

    48% {
      transform: translateX(15vw) translateY(-21vh) scale(0.55);
    }

    52% {
      transform: translateX(-6vw) translateY(1vh) scale(0.94);
    }

    56% {
      transform: translateX(-26vw) translateY(13vh) scale(0.26);
    }

    60% {
      transform: translateX(44vw) translateY(-49vh) scale(0.67);
    }

    64% {
      transform: translateX(23vw) translateY(-28vh) scale(0.35);
    }

    68% {
      transform: translateX(35vw) translateY(37vh) scale(0.56);
    }

    72% {
      transform: translateX(-4vw) translateY(43vh) scale(0.76);
    }

    76% {
      transform: translateX(-43vw) translateY(43vh) scale(0.56);
    }

    80% {
      transform: translateX(-12vw) translateY(1vh) scale(0.99);
    }

    84% {
      transform: translateX(-26vw) translateY(3vh) scale(0.3);
    }

    88% {
      transform: translateX(46vw) translateY(-9vh) scale(0.55);
    }

    92% {
      transform: translateX(33vw) translateY(-15vh) scale(0.66);
    }

    96% {
      transform: translateX(19vw) translateY(-3vh) scale(0.59);
    }

    100% {
      transform: translateX(-32vw) translateY(-18vh) scale(0.31);
    }
  }

  @keyframes drift {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes flash {

    0%,
    30%,
    100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw yellow;
    }

    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw yellow;
    }
  }


  .stickyBtn2 {
    width: 65px;
    height: 65px;
    opacity: 0.5;
    transition: 0.5s ease;
    z-index: 1;
   }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
     transition: transform .4s;
     z-index: 1;

  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }
}

@media screen and (min-width: 1920px) {

  .miniBarMain{
    display: none;
  }

  .littleNav{
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background-color: black;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .containerMob {
    display: none;
  }

  .cont {
    background: radial-gradient(circle, rgba(13, 24, 31, 0.2777485994397759) 0%, rgba(13, 24, 31, 0.48783263305322133) 69%, rgba(13, 24, 31, 0.8743872549019608) 100%), url('./assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .in2 p {
    font-size: 30px;
    color: white;
    font-weight: bold;
    text-align: center;
    letter-spacing: 4px;
    font-family: "Rise of Kingdom";

  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
  }

  .introduction {
    padding-top: 50px;
  }

  .container {
    max-width: 800px;
    max-height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 70px;
    margin-bottom: 50px;

  }

  .container:hover img {
    opacity: 0.3;
  }

  .container img:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  .container img {
    width: 40%;
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
    box-shadow: 0 0 10px #d44e00;
    border: 2px solid #d6985c;
    z-index: 10000;
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .ag-format-container {
    width: 1142px;
    margin: 0 auto;
  }

  .ag-sparks {
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    position: relative;
  }

  .ag-sparks:before,
  .ag-sparks:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ag-sparks:before {
    content: "";

    -webkit-animation: an-blink 30s linear 3s infinite;
    -moz-animation: an-blink 30s linear 3s infinite;
    -o-animation: an-blink 30s linear 3s infinite;
    animation: an-blink 30s linear 3s infinite;
  }

  .ag-sparks:after {
    content: "";

    z-index: 10;
  }

  .ag-spark {
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    position: relative;
  }

  .ag-spark:before,
  .ag-spark:after {
    content: "";
    background-image: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/sparks/images/spark.png);
    background-position-x: 50%;
    background-repeat: repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    opacity: 0;

    will-change: transform;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ag-spark:before {
    -webkit-animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
    -moz-animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
    -o-animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
    animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
  }

  .ag-spark:after {
    -webkit-animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
    -moz-animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
    -o-animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
    animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
  }

  .ag-lightning {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ag-lightning:before,
  .ag-lightning:after {
    content: "";

    opacity: 0;

    will-change: transform;

    position: absolute;
  }

  .ag-lightning:before {
    width: 272px;
    background-image: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/sparks/images/lightning-1.png);

    top: -9.75em;
    left: 50%;

    -webkit-animation: an-lightning-first 30s linear 3s infinite;
    -moz-animation: an-lightning-first 30s linear 3s infinite;
    -o-animation: an-lightning-first 30s linear 3s infinite;
    animation: an-lightning-first 30s linear 3s infinite;
  }

  .ag-lightning:after {
    width: 254px;
    background-image: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/sparks/images/lightning-1.png);

    top: -10.1875em;
    right: -6.1875em;

    -webkit-animation: an-lightning-second 30s linear 3s infinite;
    -moz-animation: an-lightning-second 30s linear 3s infinite;
    -o-animation: an-lightning-second 30s linear 3s infinite;
    animation: an-lightning-second 30s linear 3s infinite;
  }

  @-webkit-keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @-moz-keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @-o-keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @-webkit-keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @-moz-keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @-o-keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @-webkit-keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @-moz-keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      -moz-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      -moz-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @-o-keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      -o-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      -o-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      -moz-transform: translateX(1.25em);
      -o-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      -moz-transform: translateX(-1.25em);
      -o-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @-webkit-keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @-moz-keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      -moz-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      -moz-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @-o-keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      -o-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      -o-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      -moz-transform: scale(-1, 1) translateX(1.25em);
      -o-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      -moz-transform: scale(-1, 1) translateX(-1.25em);
      -o-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @-webkit-keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @-moz-keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @-o-keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @-moz-keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @-o-keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @media only screen and (max-width: 767px) {
    .ag-format-container {
      width: 96%;
    }

  }

  @media only screen and (max-width: 639px) {}

  @media only screen and (max-width: 479px) {}

  @media (min-width: 768px) and (max-width: 979px) {
    .ag-format-container {
      width: 750px;
    }

  }

  @media (min-width: 980px) and (max-width: 1161px) {
    .ag-format-container {
      width: 960px;
    }

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: linear-gradient(87deg, rgba(0, 0, 0, 0.1956954656862745) 0%, rgba(0, 0, 0, 0.6606814600840336) 35%, rgba(0, 0, 0, 0.8819699754901961) 100%), url('./assets/map.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 110px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyHK {
    font-size: 110px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 70px;
    color: white;
    font-weight: 500;
    text-align: left;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .storyH2-2 {
    font-size: 70px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 27px;
    text-align: left;
    display: flex;
    justify-content: space-between;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;

  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }


  .map {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 28px;
    background: rgba(19, 19, 19, 0.767);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #e2e2e2 2px ridge;
    box-shadow: 0 0 15px #704531;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 27px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .faqbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13126969537815125) 0%, rgba(0, 0, 0, 0.3385526085434174) 35%, rgba(0, 0, 0, 0.7979363620448179) 100%), url('./assets/faqbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .team {
    width: 60%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .teambg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13126969537815125) 0%, rgba(0, 0, 0, 0.3385526085434174) 35%, rgba(0, 0, 0, 0.7979363620448179) 100%), url('./assets/teambg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
  }

  .kingdomsBg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
    padding-top: 50px;
  }

  .kingdomsSection1 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/lionKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection2 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/raakshaKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection3 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/snakeKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection4 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/eagleKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection5 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/dragonKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .logoF {
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #ff0, 0 -10px 20px #ff8000, 0 -18px 40px #F00;
    color: white;
    font-size: 80px
  }

  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(250, 219, 207);
    text-align: center;
    font-size: 28px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
    font-size: 28px;
  }

  .devs img {
    width: 45px;
    height: 45px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fd2;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connect div:hover {
    color: rgb(255, 185, 138);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .icons img {
    width: 43px;
    height: 43px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform .2s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 25px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;

  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #99faf500;
    font-size: 25px;
    padding: 13px;
    width: 270px;
    border: 2px rgb(216, 179, 130) solid;
    color: rgb(255, 255, 255);
    box-shadow: 0 0 10px #b18a41;
    transition: 0.3s ease;
    cursor: none;
    margin-left: 10px;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 110px;
    height: 110px;
  }

  .downImg {
    width: 50px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  /*........................Roadmap........................*/

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 70px;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #eeb38b;
    color: white;
    font-family: "Rise of Kingdom";
    margin-left: 20px;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 30px;
    width: 600px;
    margin-left: 20px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 140px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 110px;
    height: 110px;
    border: 4px solid #fff;
    position: absolute;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #f2b14b;
    left: 64.5px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    border: 5px solid rgba(63, 63, 63, 0.253);
    left: 64.5px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #f2b14b;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #f2b14b;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid rgba(63, 63, 63, 0.253);

  }

  .footPrint2 {
    width: 80px;
    height: 80px;
  }

  .rmbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13126969537815125) 0%, rgba(0, 0, 0, 0.3385526085434174) 35%, rgba(0, 0, 0, 0.7979363620448179) 100%), url('./assets/rmbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  h4 {
    text-transform: capitalize;
    background-color: rgba(0, 0, 0, 0.384);
    color: white;
    padding: 15px;
    box-shadow: 0 0 15px #f2b24b62;
    font-size: 30px;
    width: 600px;
    margin-bottom: 30px;
    margin-left: 20px;

  }

  .fire {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .fireGlow{
    animation: glow 2s ease-in-out infinite alternate;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .firefly {
    position: sticky;
    position: -webkit-sticky;
    left: 50%;
    top: 50%;
    width: 0.4vw;
    height: 0.4vw;
    margin: -0.2vw 0 0 9.8vw;
    animation: ease 200s alternate infinite;
    pointer-events: none;
  }

  .firefly::before,
  .firefly::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: -10vw;
  }

  .firefly::before {
    background: black;
    opacity: 0.4;
    animation: drift ease alternate infinite;
  }

  .firefly::after {
    background: white;
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow;
    animation: drift ease alternate infinite, flash ease infinite;
  }

  .firefly:nth-child(1) {
    animation-name: move1;
  }

  .firefly:nth-child(1)::before {
    animation-duration: 10s;
  }

  .firefly:nth-child(1)::after {
    animation-duration: 10s, 5869ms;
    animation-delay: 0ms, 5103ms;
  }

  @keyframes move1 {
    0% {
      transform: translateX(-41vw) translateY(23vh) scale(0.42);
    }

    5% {
      transform: translateX(9vw) translateY(-45vh) scale(0.34);
    }

    10% {
      transform: translateX(-17vw) translateY(-42vh) scale(1);
    }

    15% {
      transform: translateX(8vw) translateY(-23vh) scale(0.51);
    }

    20% {
      transform: translateX(-29vw) translateY(-20vh) scale(0.56);
    }

    25% {
      transform: translateX(8vw) translateY(16vh) scale(0.79);
    }

    30% {
      transform: translateX(11vw) translateY(46vh) scale(0.54);
    }

    35% {
      transform: translateX(9vw) translateY(-45vh) scale(0.58);
    }

    40% {
      transform: translateX(10vw) translateY(-37vh) scale(0.69);
    }

    45% {
      transform: translateX(5vw) translateY(26vh) scale(0.52);
    }

    50% {
      transform: translateX(-36vw) translateY(-39vh) scale(0.43);
    }

    55% {
      transform: translateX(-45vw) translateY(35vh) scale(0.96);
    }

    60% {
      transform: translateX(17vw) translateY(38vh) scale(0.66);
    }

    65% {
      transform: translateX(-39vw) translateY(-34vh) scale(0.75);
    }

    70% {
      transform: translateX(-45vw) translateY(18vh) scale(0.72);
    }

    75% {
      transform: translateX(48vw) translateY(21vh) scale(0.42);
    }

    80% {
      transform: translateX(28vw) translateY(-2vh) scale(0.82);
    }

    85% {
      transform: translateX(-29vw) translateY(30vh) scale(0.48);
    }

    90% {
      transform: translateX(12vw) translateY(16vh) scale(0.42);
    }

    95% {
      transform: translateX(-8vw) translateY(-24vh) scale(0.52);
    }

    100% {
      transform: translateX(-49vw) translateY(6vh) scale(0.34);
    }
  }

  .firefly:nth-child(2) {
    animation-name: move2;
  }

  .firefly:nth-child(2)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(2)::after {
    animation-duration: 13s, 8622ms;
    animation-delay: 0ms, 6050ms;
  }

  @keyframes move2 {
    0% {
      transform: translateX(-13vw) translateY(17vh) scale(0.94);
    }

    4.347826087% {
      transform: translateX(12vw) translateY(24vh) scale(0.48);
    }

    8.6956521739% {
      transform: translateX(16vw) translateY(-40vh) scale(0.39);
    }

    13.0434782609% {
      transform: translateX(22vw) translateY(22vh) scale(0.68);
    }

    17.3913043478% {
      transform: translateX(19vw) translateY(-14vh) scale(0.42);
    }

    21.7391304348% {
      transform: translateX(2vw) translateY(-18vh) scale(0.3);
    }

    26.0869565217% {
      transform: translateX(2vw) translateY(38vh) scale(0.29);
    }

    30.4347826087% {
      transform: translateX(28vw) translateY(6vh) scale(0.87);
    }

    34.7826086957% {
      transform: translateX(-48vw) translateY(21vh) scale(0.88);
    }

    39.1304347826% {
      transform: translateX(32vw) translateY(15vh) scale(0.76);
    }

    43.4782608696% {
      transform: translateX(32vw) translateY(1vh) scale(0.31);
    }

    47.8260869565% {
      transform: translateX(35vw) translateY(0vh) scale(0.29);
    }

    52.1739130435% {
      transform: translateX(9vw) translateY(14vh) scale(0.27);
    }

    56.5217391304% {
      transform: translateX(12vw) translateY(-20vh) scale(0.64);
    }

    60.8695652174% {
      transform: translateX(-21vw) translateY(27vh) scale(0.72);
    }

    65.2173913043% {
      transform: translateX(-47vw) translateY(-33vh) scale(0.31);
    }

    69.5652173913% {
      transform: translateX(-25vw) translateY(7vh) scale(0.85);
    }

    73.9130434783% {
      transform: translateX(42vw) translateY(38vh) scale(0.88);
    }

    78.2608695652% {
      transform: translateX(11vw) translateY(10vh) scale(0.91);
    }

    82.6086956522% {
      transform: translateX(2vw) translateY(6vh) scale(0.59);
    }

    86.9565217391% {
      transform: translateX(37vw) translateY(4vh) scale(0.59);
    }

    91.3043478261% {
      transform: translateX(-34vw) translateY(-32vh) scale(0.95);
    }

    95.652173913% {
      transform: translateX(-47vw) translateY(32vh) scale(0.81);
    }

    100% {
      transform: translateX(32vw) translateY(22vh) scale(0.53);
    }
  }

  .firefly:nth-child(3) {
    animation-name: move3;
  }

  .firefly:nth-child(3)::before {
    animation-duration: 14s;
  }

  .firefly:nth-child(3)::after {
    animation-duration: 14s, 7972ms;
    animation-delay: 0ms, 2219ms;
  }

  @keyframes move3 {
    0% {
      transform: translateX(-9vw) translateY(-12vh) scale(0.35);
    }

    4% {
      transform: translateX(9vw) translateY(45vh) scale(0.75);
    }

    8% {
      transform: translateX(37vw) translateY(-35vh) scale(0.42);
    }

    12% {
      transform: translateX(-49vw) translateY(5vh) scale(0.46);
    }

    16% {
      transform: translateX(42vw) translateY(26vh) scale(0.34);
    }

    20% {
      transform: translateX(-12vw) translateY(19vh) scale(0.84);
    }

    24% {
      transform: translateX(23vw) translateY(6vh) scale(0.45);
    }

    28% {
      transform: translateX(18vw) translateY(-16vh) scale(0.47);
    }

    32% {
      transform: translateX(22vw) translateY(-40vh) scale(0.45);
    }

    36% {
      transform: translateX(-37vw) translateY(33vh) scale(0.75);
    }

    40% {
      transform: translateX(44vw) translateY(33vh) scale(0.61);
    }

    44% {
      transform: translateX(-44vw) translateY(-18vh) scale(0.57);
    }

    48% {
      transform: translateX(-9vw) translateY(39vh) scale(0.45);
    }

    52% {
      transform: translateX(10vw) translateY(41vh) scale(0.51);
    }

    56% {
      transform: translateX(23vw) translateY(-41vh) scale(0.31);
    }

    60% {
      transform: translateX(48vw) translateY(-5vh) scale(0.33);
    }

    64% {
      transform: translateX(-49vw) translateY(-43vh) scale(0.3);
    }

    68% {
      transform: translateX(-39vw) translateY(5vh) scale(0.44);
    }

    72% {
      transform: translateX(-18vw) translateY(-28vh) scale(0.94);
    }

    76% {
      transform: translateX(22vw) translateY(-18vh) scale(0.67);
    }

    80% {
      transform: translateX(10vw) translateY(-32vh) scale(0.56);
    }

    84% {
      transform: translateX(-7vw) translateY(-48vh) scale(0.47);
    }

    88% {
      transform: translateX(30vw) translateY(37vh) scale(0.64);
    }

    92% {
      transform: translateX(-46vw) translateY(-37vh) scale(0.53);
    }

    96% {
      transform: translateX(9vw) translateY(40vh) scale(0.87);
    }

    100% {
      transform: translateX(-48vw) translateY(-17vh) scale(0.46);
    }
  }

  .firefly:nth-child(4) {
    animation-name: move4;
  }

  .firefly:nth-child(4)::before {
    animation-duration: 10s;
  }

  .firefly:nth-child(4)::after {
    animation-duration: 10s, 8866ms;
    animation-delay: 0ms, 4122ms;
  }

  @keyframes move4 {
    0% {
      transform: translateX(-1vw) translateY(17vh) scale(0.76);
    }

    5.5555555556% {
      transform: translateX(-20vw) translateY(-6vh) scale(0.96);
    }

    11.1111111111% {
      transform: translateX(7vw) translateY(27vh) scale(0.44);
    }

    16.6666666667% {
      transform: translateX(1vw) translateY(32vh) scale(0.75);
    }

    22.2222222222% {
      transform: translateX(48vw) translateY(-14vh) scale(0.83);
    }

    27.7777777778% {
      transform: translateX(-34vw) translateY(-30vh) scale(0.6);
    }

    33.3333333333% {
      transform: translateX(40vw) translateY(-27vh) scale(1);
    }

    38.8888888889% {
      transform: translateX(-14vw) translateY(-28vh) scale(0.29);
    }

    44.4444444444% {
      transform: translateX(37vw) translateY(-14vh) scale(0.56);
    }

    50% {
      transform: translateX(29vw) translateY(8vh) scale(0.37);
    }

    55.5555555556% {
      transform: translateX(-14vw) translateY(21vh) scale(0.39);
    }

    61.1111111111% {
      transform: translateX(-1vw) translateY(38vh) scale(0.64);
    }

    66.6666666667% {
      transform: translateX(40vw) translateY(-17vh) scale(0.78);
    }

    72.2222222222% {
      transform: translateX(-6vw) translateY(-45vh) scale(0.54);
    }

    77.7777777778% {
      transform: translateX(-47vw) translateY(-46vh) scale(0.38);
    }

    83.3333333333% {
      transform: translateX(38vw) translateY(-13vh) scale(0.86);
    }

    88.8888888889% {
      transform: translateX(-8vw) translateY(35vh) scale(0.39);
    }

    94.4444444444% {
      transform: translateX(-25vw) translateY(-1vh) scale(0.44);
    }

    100% {
      transform: translateX(0vw) translateY(-34vh) scale(0.57);
    }
  }

  .firefly:nth-child(5) {
    animation-name: move5;
  }

  .firefly:nth-child(5)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(5)::after {
    animation-duration: 18s, 6640ms;
    animation-delay: 0ms, 1937ms;
  }

  @keyframes move5 {
    0% {
      transform: translateX(33vw) translateY(-14vh) scale(0.83);
    }

    4.5454545455% {
      transform: translateX(-14vw) translateY(6vh) scale(0.26);
    }

    9.0909090909% {
      transform: translateX(-15vw) translateY(-12vh) scale(0.56);
    }

    13.6363636364% {
      transform: translateX(39vw) translateY(20vh) scale(0.69);
    }

    18.1818181818% {
      transform: translateX(-21vw) translateY(-1vh) scale(0.27);
    }

    22.7272727273% {
      transform: translateX(-38vw) translateY(47vh) scale(0.43);
    }

    27.2727272727% {
      transform: translateX(10vw) translateY(-4vh) scale(0.53);
    }

    31.8181818182% {
      transform: translateX(-13vw) translateY(21vh) scale(0.88);
    }

    36.3636363636% {
      transform: translateX(-3vw) translateY(46vh) scale(0.76);
    }

    40.9090909091% {
      transform: translateX(-2vw) translateY(-11vh) scale(0.32);
    }

    45.4545454545% {
      transform: translateX(14vw) translateY(4vh) scale(0.32);
    }

    50% {
      transform: translateX(8vw) translateY(8vh) scale(0.76);
    }

    54.5454545455% {
      transform: translateX(18vw) translateY(-35vh) scale(0.6);
    }

    59.0909090909% {
      transform: translateX(15vw) translateY(-43vh) scale(0.28);
    }

    63.6363636364% {
      transform: translateX(-12vw) translateY(12vh) scale(0.68);
    }

    68.1818181818% {
      transform: translateX(5vw) translateY(25vh) scale(0.96);
    }

    72.7272727273% {
      transform: translateX(-21vw) translateY(-35vh) scale(0.4);
    }

    77.2727272727% {
      transform: translateX(-44vw) translateY(7vh) scale(0.98);
    }

    81.8181818182% {
      transform: translateX(40vw) translateY(-29vh) scale(0.91);
    }

    86.3636363636% {
      transform: translateX(30vw) translateY(-36vh) scale(0.49);
    }

    90.9090909091% {
      transform: translateX(48vw) translateY(22vh) scale(0.67);
    }

    95.4545454545% {
      transform: translateX(18vw) translateY(-10vh) scale(0.77);
    }

    100% {
      transform: translateX(-40vw) translateY(-6vh) scale(0.91);
    }
  }

  .firefly:nth-child(6) {
    animation-name: move6;
  }

  .firefly:nth-child(6)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(6)::after {
    animation-duration: 15s, 8079ms;
    animation-delay: 0ms, 6703ms;
  }

  @keyframes move6 {
    0% {
      transform: translateX(7vw) translateY(-40vh) scale(0.74);
    }

    4.7619047619% {
      transform: translateX(-23vw) translateY(32vh) scale(0.7);
    }

    9.5238095238% {
      transform: translateX(-9vw) translateY(0vh) scale(0.34);
    }

    14.2857142857% {
      transform: translateX(-36vw) translateY(18vh) scale(0.65);
    }

    19.0476190476% {
      transform: translateX(37vw) translateY(-8vh) scale(0.84);
    }

    23.8095238095% {
      transform: translateX(7vw) translateY(27vh) scale(0.44);
    }

    28.5714285714% {
      transform: translateX(-3vw) translateY(8vh) scale(0.93);
    }

    33.3333333333% {
      transform: translateX(-18vw) translateY(5vh) scale(0.87);
    }

    38.0952380952% {
      transform: translateX(25vw) translateY(19vh) scale(0.45);
    }

    42.8571428571% {
      transform: translateX(46vw) translateY(14vh) scale(0.95);
    }

    47.619047619% {
      transform: translateX(13vw) translateY(-10vh) scale(0.5);
    }

    52.380952381% {
      transform: translateX(15vw) translateY(11vh) scale(0.4);
    }

    57.1428571429% {
      transform: translateX(15vw) translateY(-35vh) scale(0.96);
    }

    61.9047619048% {
      transform: translateX(-13vw) translateY(41vh) scale(0.35);
    }

    66.6666666667% {
      transform: translateX(-23vw) translateY(26vh) scale(0.9);
    }

    71.4285714286% {
      transform: translateX(-7vw) translateY(4vh) scale(0.81);
    }

    76.1904761905% {
      transform: translateX(-49vw) translateY(-18vh) scale(0.84);
    }

    80.9523809524% {
      transform: translateX(26vw) translateY(33vh) scale(0.43);
    }

    85.7142857143% {
      transform: translateX(-6vw) translateY(23vh) scale(0.48);
    }

    90.4761904762% {
      transform: translateX(46vw) translateY(-5vh) scale(0.8);
    }

    95.2380952381% {
      transform: translateX(42vw) translateY(33vh) scale(0.6);
    }

    100% {
      transform: translateX(-28vw) translateY(26vh) scale(0.64);
    }
  }

  .firefly:nth-child(7) {
    animation-name: move7;
  }

  .firefly:nth-child(7)::before {
    animation-duration: 17s;
  }

  .firefly:nth-child(7)::after {
    animation-duration: 17s, 9827ms;
    animation-delay: 0ms, 3278ms;
  }

  @keyframes move7 {
    0% {
      transform: translateX(-1vw) translateY(41vh) scale(0.51);
    }

    5.5555555556% {
      transform: translateX(38vw) translateY(32vh) scale(0.61);
    }

    11.1111111111% {
      transform: translateX(36vw) translateY(44vh) scale(0.89);
    }

    16.6666666667% {
      transform: translateX(-22vw) translateY(-36vh) scale(0.68);
    }

    22.2222222222% {
      transform: translateX(4vw) translateY(-2vh) scale(0.87);
    }

    27.7777777778% {
      transform: translateX(11vw) translateY(-5vh) scale(0.48);
    }

    33.3333333333% {
      transform: translateX(-30vw) translateY(28vh) scale(0.43);
    }

    38.8888888889% {
      transform: translateX(-38vw) translateY(-4vh) scale(0.84);
    }

    44.4444444444% {
      transform: translateX(-29vw) translateY(33vh) scale(0.43);
    }

    50% {
      transform: translateX(1vw) translateY(-4vh) scale(0.96);
    }

    55.5555555556% {
      transform: translateX(38vw) translateY(-23vh) scale(0.5);
    }

    61.1111111111% {
      transform: translateX(-47vw) translateY(-1vh) scale(0.91);
    }

    66.6666666667% {
      transform: translateX(40vw) translateY(-8vh) scale(0.52);
    }

    72.2222222222% {
      transform: translateX(26vw) translateY(14vh) scale(0.83);
    }

    77.7777777778% {
      transform: translateX(17vw) translateY(-48vh) scale(0.97);
    }

    83.3333333333% {
      transform: translateX(-22vw) translateY(45vh) scale(0.85);
    }

    88.8888888889% {
      transform: translateX(-21vw) translateY(-9vh) scale(0.45);
    }

    94.4444444444% {
      transform: translateX(39vw) translateY(38vh) scale(0.59);
    }

    100% {
      transform: translateX(-37vw) translateY(-2vh) scale(0.55);
    }
  }

  .firefly:nth-child(8) {
    animation-name: move8;
  }

  .firefly:nth-child(8)::before {
    animation-duration: 9s;
  }

  .firefly:nth-child(8)::after {
    animation-duration: 9s, 8380ms;
    animation-delay: 0ms, 4603ms;
  }

  @keyframes move8 {
    0% {
      transform: translateX(-17vw) translateY(30vh) scale(0.58);
    }

    5.8823529412% {
      transform: translateX(-13vw) translateY(-42vh) scale(0.82);
    }

    11.7647058824% {
      transform: translateX(-10vw) translateY(-38vh) scale(0.86);
    }

    17.6470588235% {
      transform: translateX(49vw) translateY(47vh) scale(0.97);
    }

    23.5294117647% {
      transform: translateX(-17vw) translateY(-44vh) scale(1);
    }

    29.4117647059% {
      transform: translateX(-6vw) translateY(-20vh) scale(0.5);
    }

    35.2941176471% {
      transform: translateX(-39vw) translateY(-34vh) scale(0.33);
    }

    41.1764705882% {
      transform: translateX(38vw) translateY(49vh) scale(0.45);
    }

    47.0588235294% {
      transform: translateX(43vw) translateY(39vh) scale(0.43);
    }

    52.9411764706% {
      transform: translateX(46vw) translateY(-4vh) scale(0.27);
    }

    58.8235294118% {
      transform: translateX(-16vw) translateY(-20vh) scale(0.71);
    }

    64.7058823529% {
      transform: translateX(-43vw) translateY(-40vh) scale(0.84);
    }

    70.5882352941% {
      transform: translateX(-10vw) translateY(13vh) scale(0.81);
    }

    76.4705882353% {
      transform: translateX(38vw) translateY(1vh) scale(0.36);
    }

    82.3529411765% {
      transform: translateX(32vw) translateY(-40vh) scale(0.71);
    }

    88.2352941176% {
      transform: translateX(0vw) translateY(-49vh) scale(0.32);
    }

    94.1176470588% {
      transform: translateX(12vw) translateY(-49vh) scale(0.29);
    }

    100% {
      transform: translateX(7vw) translateY(-3vh) scale(0.94);
    }
  }

  .firefly:nth-child(9) {
    animation-name: move9;
  }

  .firefly:nth-child(9)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(9)::after {
    animation-duration: 18s, 10823ms;
    animation-delay: 0ms, 705ms;
  }

  @keyframes move9 {
    0% {
      transform: translateX(3vw) translateY(4vh) scale(0.41);
    }

    5.2631578947% {
      transform: translateX(-43vw) translateY(40vh) scale(0.79);
    }

    10.5263157895% {
      transform: translateX(-39vw) translateY(22vh) scale(0.38);
    }

    15.7894736842% {
      transform: translateX(-32vw) translateY(1vh) scale(0.41);
    }

    21.0526315789% {
      transform: translateX(-33vw) translateY(31vh) scale(0.81);
    }

    26.3157894737% {
      transform: translateX(2vw) translateY(-33vh) scale(0.43);
    }

    31.5789473684% {
      transform: translateX(-2vw) translateY(41vh) scale(0.78);
    }

    36.8421052632% {
      transform: translateX(-1vw) translateY(25vh) scale(0.69);
    }

    42.1052631579% {
      transform: translateX(-39vw) translateY(-28vh) scale(0.94);
    }

    47.3684210526% {
      transform: translateX(29vw) translateY(13vh) scale(0.42);
    }

    52.6315789474% {
      transform: translateX(42vw) translateY(17vh) scale(0.47);
    }

    57.8947368421% {
      transform: translateX(14vw) translateY(-9vh) scale(0.59);
    }

    63.1578947368% {
      transform: translateX(12vw) translateY(-8vh) scale(0.29);
    }

    68.4210526316% {
      transform: translateX(-31vw) translateY(-25vh) scale(0.81);
    }

    73.6842105263% {
      transform: translateX(-45vw) translateY(13vh) scale(0.33);
    }

    78.9473684211% {
      transform: translateX(-6vw) translateY(-48vh) scale(0.86);
    }

    84.2105263158% {
      transform: translateX(-18vw) translateY(-12vh) scale(0.99);
    }

    89.4736842105% {
      transform: translateX(22vw) translateY(-39vh) scale(0.51);
    }

    94.7368421053% {
      transform: translateX(43vw) translateY(-15vh) scale(0.6);
    }

    100% {
      transform: translateX(41vw) translateY(-14vh) scale(0.56);
    }
  }

  .firefly:nth-child(10) {
    animation-name: move10;
  }

  .firefly:nth-child(10)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(10)::after {
    animation-duration: 15s, 9714ms;
    animation-delay: 0ms, 7928ms;
  }

  @keyframes move10 {
    0% {
      transform: translateX(-5vw) translateY(25vh) scale(0.3);
    }

    3.8461538462% {
      transform: translateX(11vw) translateY(42vh) scale(0.81);
    }

    7.6923076923% {
      transform: translateX(-45vw) translateY(25vh) scale(0.44);
    }

    11.5384615385% {
      transform: translateX(23vw) translateY(-4vh) scale(0.43);
    }

    15.3846153846% {
      transform: translateX(10vw) translateY(12vh) scale(0.84);
    }

    19.2307692308% {
      transform: translateX(-33vw) translateY(38vh) scale(0.95);
    }

    23.0769230769% {
      transform: translateX(15vw) translateY(23vh) scale(0.56);
    }

    26.9230769231% {
      transform: translateX(-12vw) translateY(-17vh) scale(0.42);
    }

    30.7692307692% {
      transform: translateX(1vw) translateY(-34vh) scale(0.61);
    }

    34.6153846154% {
      transform: translateX(22vw) translateY(-25vh) scale(0.32);
    }

    38.4615384615% {
      transform: translateX(-17vw) translateY(26vh) scale(0.65);
    }

    42.3076923077% {
      transform: translateX(3vw) translateY(29vh) scale(0.91);
    }

    46.1538461538% {
      transform: translateX(-12vw) translateY(-36vh) scale(0.64);
    }

    50% {
      transform: translateX(-44vw) translateY(41vh) scale(0.44);
    }

    53.8461538462% {
      transform: translateX(9vw) translateY(-29vh) scale(0.6);
    }

    57.6923076923% {
      transform: translateX(22vw) translateY(30vh) scale(0.98);
    }

    61.5384615385% {
      transform: translateX(-20vw) translateY(33vh) scale(0.7);
    }

    65.3846153846% {
      transform: translateX(40vw) translateY(24vh) scale(0.38);
    }

    69.2307692308% {
      transform: translateX(-19vw) translateY(4vh) scale(0.48);
    }

    73.0769230769% {
      transform: translateX(-18vw) translateY(-32vh) scale(1);
    }

    76.9230769231% {
      transform: translateX(17vw) translateY(1vh) scale(0.68);
    }

    80.7692307692% {
      transform: translateX(-40vw) translateY(-16vh) scale(0.92);
    }

    84.6153846154% {
      transform: translateX(17vw) translateY(32vh) scale(0.9);
    }

    88.4615384615% {
      transform: translateX(14vw) translateY(45vh) scale(0.8);
    }

    92.3076923077% {
      transform: translateX(14vw) translateY(50vh) scale(0.74);
    }

    96.1538461538% {
      transform: translateX(26vw) translateY(47vh) scale(0.83);
    }

    100% {
      transform: translateX(49vw) translateY(-40vh) scale(0.95);
    }
  }

  .firefly:nth-child(11) {
    animation-name: move11;
  }

  .firefly:nth-child(11)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(11)::after {
    animation-duration: 13s, 7476ms;
    animation-delay: 0ms, 5436ms;
  }

  @keyframes move11 {
    0% {
      transform: translateX(-7vw) translateY(16vh) scale(0.42);
    }

    4.1666666667% {
      transform: translateX(-44vw) translateY(14vh) scale(0.89);
    }

    8.3333333333% {
      transform: translateX(17vw) translateY(-25vh) scale(0.72);
    }

    12.5% {
      transform: translateX(-40vw) translateY(-22vh) scale(0.47);
    }

    16.6666666667% {
      transform: translateX(49vw) translateY(12vh) scale(0.93);
    }

    20.8333333333% {
      transform: translateX(-45vw) translateY(21vh) scale(0.6);
    }

    25% {
      transform: translateX(36vw) translateY(-41vh) scale(0.48);
    }

    29.1666666667% {
      transform: translateX(-9vw) translateY(0vh) scale(0.98);
    }

    33.3333333333% {
      transform: translateX(-32vw) translateY(37vh) scale(0.29);
    }

    37.5% {
      transform: translateX(31vw) translateY(-46vh) scale(0.34);
    }

    41.6666666667% {
      transform: translateX(21vw) translateY(-45vh) scale(0.51);
    }

    45.8333333333% {
      transform: translateX(-12vw) translateY(-46vh) scale(0.87);
    }

    50% {
      transform: translateX(25vw) translateY(28vh) scale(0.48);
    }

    54.1666666667% {
      transform: translateX(-16vw) translateY(-25vh) scale(0.76);
    }

    58.3333333333% {
      transform: translateX(-4vw) translateY(48vh) scale(0.63);
    }

    62.5% {
      transform: translateX(48vw) translateY(-32vh) scale(0.31);
    }

    66.6666666667% {
      transform: translateX(-39vw) translateY(-39vh) scale(0.56);
    }

    70.8333333333% {
      transform: translateX(-1vw) translateY(37vh) scale(0.5);
    }

    75% {
      transform: translateX(-9vw) translateY(-5vh) scale(0.56);
    }

    79.1666666667% {
      transform: translateX(-11vw) translateY(13vh) scale(0.96);
    }

    83.3333333333% {
      transform: translateX(-1vw) translateY(-41vh) scale(0.55);
    }

    87.5% {
      transform: translateX(17vw) translateY(18vh) scale(0.58);
    }

    91.6666666667% {
      transform: translateX(-12vw) translateY(10vh) scale(0.99);
    }

    95.8333333333% {
      transform: translateX(33vw) translateY(50vh) scale(0.85);
    }

    100% {
      transform: translateX(43vw) translateY(38vh) scale(0.52);
    }
  }

  .firefly:nth-child(12) {
    animation-name: move12;
  }

  .firefly:nth-child(12)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(12)::after {
    animation-duration: 13s, 5428ms;
    animation-delay: 0ms, 4447ms;
  }

  @keyframes move12 {
    0% {
      transform: translateX(19vw) translateY(-25vh) scale(0.71);
    }

    3.7037037037% {
      transform: translateX(5vw) translateY(-44vh) scale(0.68);
    }

    7.4074074074% {
      transform: translateX(-11vw) translateY(21vh) scale(0.96);
    }

    11.1111111111% {
      transform: translateX(-16vw) translateY(-35vh) scale(0.62);
    }

    14.8148148148% {
      transform: translateX(19vw) translateY(36vh) scale(0.51);
    }

    18.5185185185% {
      transform: translateX(-31vw) translateY(39vh) scale(0.91);
    }

    22.2222222222% {
      transform: translateX(-13vw) translateY(-33vh) scale(0.57);
    }

    25.9259259259% {
      transform: translateX(-11vw) translateY(-43vh) scale(0.91);
    }

    29.6296296296% {
      transform: translateX(6vw) translateY(-23vh) scale(0.35);
    }

    33.3333333333% {
      transform: translateX(50vw) translateY(-5vh) scale(1);
    }

    37.037037037% {
      transform: translateX(-42vw) translateY(0vh) scale(0.98);
    }

    40.7407407407% {
      transform: translateX(35vw) translateY(40vh) scale(0.97);
    }

    44.4444444444% {
      transform: translateX(-6vw) translateY(-30vh) scale(0.28);
    }

    48.1481481481% {
      transform: translateX(7vw) translateY(0vh) scale(0.85);
    }

    51.8518518519% {
      transform: translateX(-29vw) translateY(-42vh) scale(0.82);
    }

    55.5555555556% {
      transform: translateX(-13vw) translateY(-39vh) scale(0.93);
    }

    59.2592592593% {
      transform: translateX(-16vw) translateY(43vh) scale(0.99);
    }

    62.962962963% {
      transform: translateX(19vw) translateY(38vh) scale(0.61);
    }

    66.6666666667% {
      transform: translateX(13vw) translateY(26vh) scale(0.75);
    }

    70.3703703704% {
      transform: translateX(-3vw) translateY(-23vh) scale(0.7);
    }

    74.0740740741% {
      transform: translateX(-19vw) translateY(3vh) scale(0.93);
    }

    77.7777777778% {
      transform: translateX(8vw) translateY(-18vh) scale(0.77);
    }

    81.4814814815% {
      transform: translateX(-23vw) translateY(-12vh) scale(0.88);
    }

    85.1851851852% {
      transform: translateX(29vw) translateY(-27vh) scale(0.75);
    }

    88.8888888889% {
      transform: translateX(-28vw) translateY(-32vh) scale(0.46);
    }

    92.5925925926% {
      transform: translateX(-40vw) translateY(11vh) scale(0.54);
    }

    96.2962962963% {
      transform: translateX(19vw) translateY(3vh) scale(0.86);
    }

    100% {
      transform: translateX(35vw) translateY(-45vh) scale(0.73);
    }
  }

  .firefly:nth-child(13) {
    animation-name: move13;
  }

  .firefly:nth-child(13)::before {
    animation-duration: 12s;
  }

  .firefly:nth-child(13)::after {
    animation-duration: 12s, 10857ms;
    animation-delay: 0ms, 8399ms;
  }

  @keyframes move13 {
    0% {
      transform: translateX(-20vw) translateY(28vh) scale(0.31);
    }

    5.2631578947% {
      transform: translateX(10vw) translateY(-35vh) scale(0.91);
    }

    10.5263157895% {
      transform: translateX(-12vw) translateY(7vh) scale(0.65);
    }

    15.7894736842% {
      transform: translateX(-35vw) translateY(-3vh) scale(0.56);
    }

    21.0526315789% {
      transform: translateX(-24vw) translateY(-16vh) scale(0.35);
    }

    26.3157894737% {
      transform: translateX(0vw) translateY(20vh) scale(0.57);
    }

    31.5789473684% {
      transform: translateX(-45vw) translateY(-3vh) scale(0.77);
    }

    36.8421052632% {
      transform: translateX(37vw) translateY(36vh) scale(0.7);
    }

    42.1052631579% {
      transform: translateX(-9vw) translateY(8vh) scale(0.92);
    }

    47.3684210526% {
      transform: translateX(-14vw) translateY(-41vh) scale(0.29);
    }

    52.6315789474% {
      transform: translateX(-12vw) translateY(-35vh) scale(0.71);
    }

    57.8947368421% {
      transform: translateX(-3vw) translateY(-21vh) scale(0.48);
    }

    63.1578947368% {
      transform: translateX(12vw) translateY(6vh) scale(0.8);
    }

    68.4210526316% {
      transform: translateX(16vw) translateY(-5vh) scale(0.75);
    }

    73.6842105263% {
      transform: translateX(-39vw) translateY(-43vh) scale(0.68);
    }

    78.9473684211% {
      transform: translateX(35vw) translateY(-46vh) scale(0.3);
    }

    84.2105263158% {
      transform: translateX(35vw) translateY(-45vh) scale(0.83);
    }

    89.4736842105% {
      transform: translateX(-8vw) translateY(13vh) scale(0.39);
    }

    94.7368421053% {
      transform: translateX(-12vw) translateY(32vh) scale(0.45);
    }

    100% {
      transform: translateX(-14vw) translateY(42vh) scale(0.67);
    }
  }

  .firefly:nth-child(14) {
    animation-name: move14;
  }

  .firefly:nth-child(14)::before {
    animation-duration: 11s;
  }

  .firefly:nth-child(14)::after {
    animation-duration: 11s, 7829ms;
    animation-delay: 0ms, 5814ms;
  }

  @keyframes move14 {
    0% {
      transform: translateX(10vw) translateY(-18vh) scale(0.99);
    }

    5.8823529412% {
      transform: translateX(7vw) translateY(18vh) scale(0.53);
    }

    11.7647058824% {
      transform: translateX(-38vw) translateY(3vh) scale(0.96);
    }

    17.6470588235% {
      transform: translateX(22vw) translateY(-38vh) scale(0.49);
    }

    23.5294117647% {
      transform: translateX(8vw) translateY(-38vh) scale(0.41);
    }

    29.4117647059% {
      transform: translateX(34vw) translateY(26vh) scale(0.7);
    }

    35.2941176471% {
      transform: translateX(-7vw) translateY(-3vh) scale(0.52);
    }

    41.1764705882% {
      transform: translateX(-43vw) translateY(45vh) scale(0.43);
    }

    47.0588235294% {
      transform: translateX(47vw) translateY(9vh) scale(0.89);
    }

    52.9411764706% {
      transform: translateX(45vw) translateY(32vh) scale(0.77);
    }

    58.8235294118% {
      transform: translateX(8vw) translateY(16vh) scale(0.64);
    }

    64.7058823529% {
      transform: translateX(-45vw) translateY(-38vh) scale(0.92);
    }

    70.5882352941% {
      transform: translateX(-6vw) translateY(-40vh) scale(0.64);
    }

    76.4705882353% {
      transform: translateX(29vw) translateY(-27vh) scale(0.83);
    }

    82.3529411765% {
      transform: translateX(4vw) translateY(10vh) scale(0.86);
    }

    88.2352941176% {
      transform: translateX(-8vw) translateY(43vh) scale(0.91);
    }

    94.1176470588% {
      transform: translateX(33vw) translateY(10vh) scale(0.57);
    }

    100% {
      transform: translateX(-17vw) translateY(14vh) scale(0.83);
    }
  }

  .firefly:nth-child(15) {
    animation-name: move15;
  }

  .firefly:nth-child(15)::before {
    animation-duration: 14s;
  }

  .firefly:nth-child(15)::after {
    animation-duration: 14s, 8455ms;
    animation-delay: 0ms, 1227ms;
  }

  @keyframes move15 {
    0% {
      transform: translateX(12vw) translateY(-1vh) scale(0.28);
    }

    4% {
      transform: translateX(6vw) translateY(-14vh) scale(0.61);
    }

    8% {
      transform: translateX(9vw) translateY(-13vh) scale(0.51);
    }

    12% {
      transform: translateX(26vw) translateY(14vh) scale(0.81);
    }

    16% {
      transform: translateX(-32vw) translateY(7vh) scale(0.99);
    }

    20% {
      transform: translateX(19vw) translateY(10vh) scale(0.27);
    }

    24% {
      transform: translateX(-13vw) translateY(-10vh) scale(0.83);
    }

    28% {
      transform: translateX(-35vw) translateY(-2vh) scale(0.39);
    }

    32% {
      transform: translateX(-28vw) translateY(-13vh) scale(0.82);
    }

    36% {
      transform: translateX(-45vw) translateY(28vh) scale(0.48);
    }

    40% {
      transform: translateX(-5vw) translateY(42vh) scale(0.74);
    }

    44% {
      transform: translateX(-30vw) translateY(22vh) scale(0.44);
    }

    48% {
      transform: translateX(15vw) translateY(-21vh) scale(0.55);
    }

    52% {
      transform: translateX(-6vw) translateY(1vh) scale(0.94);
    }

    56% {
      transform: translateX(-26vw) translateY(13vh) scale(0.26);
    }

    60% {
      transform: translateX(44vw) translateY(-49vh) scale(0.67);
    }

    64% {
      transform: translateX(23vw) translateY(-28vh) scale(0.35);
    }

    68% {
      transform: translateX(35vw) translateY(37vh) scale(0.56);
    }

    72% {
      transform: translateX(-4vw) translateY(43vh) scale(0.76);
    }

    76% {
      transform: translateX(-43vw) translateY(43vh) scale(0.56);
    }

    80% {
      transform: translateX(-12vw) translateY(1vh) scale(0.99);
    }

    84% {
      transform: translateX(-26vw) translateY(3vh) scale(0.3);
    }

    88% {
      transform: translateX(46vw) translateY(-9vh) scale(0.55);
    }

    92% {
      transform: translateX(33vw) translateY(-15vh) scale(0.66);
    }

    96% {
      transform: translateX(19vw) translateY(-3vh) scale(0.59);
    }

    100% {
      transform: translateX(-32vw) translateY(-18vh) scale(0.31);
    }
  }

  @keyframes drift {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes flash {

    0%,
    30%,
    100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw yellow;
    }

    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw yellow;
    }
  }


  .stickyBtn2 {
    width: 90px;
    height: 90px;
    opacity: 0.5;
    transition: 0.5s ease;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;

    transition: transform .4s;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {

  .miniBarMain{
    display: none;
  }

  .littleNav{
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background-color: black;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .containerMob {
    display: none;
  }

  .cont {
    background: radial-gradient(circle, rgba(13, 24, 31, 0.2777485994397759) 0%, rgba(13, 24, 31, 0.48783263305322133) 69%, rgba(13, 24, 31, 0.8743872549019608) 100%), url('./assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .in2 p {
    font-size: 50px;
    color: white;
    font-weight: bold;
    text-align: center;
    letter-spacing: 4px;
    font-family: "Rise of Kingdom";
  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
  }

  .introduction {
    padding-top: 50px;
  }

  .container {
    max-width: 900px;
    max-height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 130px;
    margin-bottom: 50px;

  }

  .container:hover img {
    opacity: 0.3;
  }

  .container img:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  .container img {
    width: 50%;
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
    box-shadow: 0 0 10px #d44e00;
    border: 2px solid #d6985c;
    z-index: 10000;
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .ag-format-container {
    width: 1142px;
    margin: 0 auto;
  }

  .ag-sparks {
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    position: relative;
  }

  .ag-sparks:before,
  .ag-sparks:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ag-sparks:before {
    content: "";

    -webkit-animation: an-blink 30s linear 3s infinite;
    -moz-animation: an-blink 30s linear 3s infinite;
    -o-animation: an-blink 30s linear 3s infinite;
    animation: an-blink 30s linear 3s infinite;
  }

  .ag-sparks:after {
    content: "";

    z-index: 10;
  }

  .ag-spark {
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    position: relative;
  }

  .ag-spark:before,
  .ag-spark:after {
    content: "";
    background-image: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/sparks/images/spark.png);
    background-position-x: 50%;
    background-repeat: repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    opacity: 0;

    will-change: transform;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ag-spark:before {
    -webkit-animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
    -moz-animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
    -o-animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
    animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
  }

  .ag-spark:after {
    -webkit-animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
    -moz-animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
    -o-animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
    animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
  }

  .ag-lightning {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ag-lightning:before,
  .ag-lightning:after {
    content: "";

    opacity: 0;

    will-change: transform;

    position: absolute;
  }

  .ag-lightning:before {
    width: 272px;
    background-image: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/sparks/images/lightning-1.png);

    top: -9.75em;
    left: 50%;

    -webkit-animation: an-lightning-first 30s linear 3s infinite;
    -moz-animation: an-lightning-first 30s linear 3s infinite;
    -o-animation: an-lightning-first 30s linear 3s infinite;
    animation: an-lightning-first 30s linear 3s infinite;
  }

  .ag-lightning:after {
    width: 254px;
    background-image: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/sparks/images/lightning-1.png);

    top: -10.1875em;
    right: -6.1875em;

    -webkit-animation: an-lightning-second 30s linear 3s infinite;
    -moz-animation: an-lightning-second 30s linear 3s infinite;
    -o-animation: an-lightning-second 30s linear 3s infinite;
    animation: an-lightning-second 30s linear 3s infinite;
  }

  @-webkit-keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @-moz-keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @-o-keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @-webkit-keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @-moz-keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @-o-keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @-webkit-keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @-moz-keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      -moz-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      -moz-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @-o-keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      -o-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      -o-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      -moz-transform: translateX(1.25em);
      -o-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      -moz-transform: translateX(-1.25em);
      -o-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @-webkit-keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @-moz-keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      -moz-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      -moz-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @-o-keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      -o-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      -o-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      -moz-transform: scale(-1, 1) translateX(1.25em);
      -o-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      -moz-transform: scale(-1, 1) translateX(-1.25em);
      -o-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @-webkit-keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @-moz-keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @-o-keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @-moz-keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @-o-keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @media only screen and (max-width: 767px) {
    .ag-format-container {
      width: 96%;
    }

  }

  @media only screen and (max-width: 639px) {}

  @media only screen and (max-width: 479px) {}

  @media (min-width: 768px) and (max-width: 979px) {
    .ag-format-container {
      width: 750px;
    }

  }

  @media (min-width: 980px) and (max-width: 1161px) {
    .ag-format-container {
      width: 960px;
    }

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: linear-gradient(87deg, rgba(0, 0, 0, 0.1956954656862745) 0%, rgba(0, 0, 0, 0.6606814600840336) 35%, rgba(0, 0, 0, 0.8819699754901961) 100%), url('./assets/map.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 160px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyHK {
    font-size: 160px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 95px;
    color: white;
    font-weight: 500;
    text-align: left;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .storyH2-2 {
    font-size: 95px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 32px;
    text-align: left;
    display: flex;
    justify-content: space-between;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;

  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }


  .map {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 37px;
    background: rgba(19, 19, 19, 0.767);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    color: rgb(0, 0, 0);
    padding: 1.5rem;
    margin-bottom: 2rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #e2e2e2 2px ridge;
    box-shadow: 0 0 15px #704531;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 33px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .faqbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13126969537815125) 0%, rgba(0, 0, 0, 0.3385526085434174) 35%, rgba(0, 0, 0, 0.7979363620448179) 100%), url('./assets/faqbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .team {
    width: 60%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .teambg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13126969537815125) 0%, rgba(0, 0, 0, 0.3385526085434174) 35%, rgba(0, 0, 0, 0.7979363620448179) 100%), url('./assets/teambg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
  }

  .kingdomsBg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
    padding-top: 50px;
  }

  .kingdomsSection1 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/lionKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection2 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/raakshaKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection3 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/snakeKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection4 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/eagleKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection5 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/dragonKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .logoF {
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #ff0, 0 -10px 20px #ff8000, 0 -18px 40px #F00;
    color: white;
    font-size: 110px
  }

  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(250, 219, 207);
    text-align: center;
    font-size: 37px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    margin-right: 35px;
    text-align: center;
    margin-top: 80px;
    font-size: 35px;
  }

  .devs img {
    width: 55px;
    height: 55px;
    opacity: 0.9;
    transition: 0.3s ease;
    margin-left: 15px;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fd2;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connect div:hover {
    color: rgb(255, 185, 138);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .icons img {
    width: 53px;
    height: 53px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform .2s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 33px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;

  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #99faf500;
    font-size: 33px;
    padding: 16px;
    width: 320px;
    border: 2px rgb(216, 179, 130) solid;
    color: rgb(255, 255, 255);
    box-shadow: 0 0 10px #b18a41;
    transition: 0.3s ease;
    cursor: none;
    margin-left: 10px;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 140px;
    height: 140px;
  }

  .downImg {
    width: 80px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
    margin-top: 70px;
  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  /*........................Roadmap........................*/

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 90px;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #eeb38b;
    color: white;
    font-family: "Rise of Kingdom";
    margin-left: 20px;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 38px;
    width: 800px;
    margin-left: 20px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 140px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 110px;
    height: 110px;
    border: 4px solid #fff;
    position: absolute;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #f2b14b;
    left: 63.5px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    border: 5px solid rgba(63, 63, 63, 0.253);
    left: 63.5px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #f2b14b;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #f2b14b;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid rgba(63, 63, 63, 0.253);

  }

  .footPrint2 {
    width: 80px;
    height: 80px;
  }

  .rmbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13126969537815125) 0%, rgba(0, 0, 0, 0.3385526085434174) 35%, rgba(0, 0, 0, 0.7979363620448179) 100%), url('./assets/rmbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  h4 {
    text-transform: capitalize;
    background-color: rgba(0, 0, 0, 0.384);
    color: white;
    padding: 15px;
    box-shadow: 0 0 15px #f2b24b62;
    font-size: 45px;
    width: 800px;
    margin-bottom: 30px;
    margin-left: 20px;

  }

  .fire {
    width: 70px;
    height: 70px;
    margin-right: 10px;
  }

  .fireGlow{
    animation: glow 2s ease-in-out infinite alternate;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    margin-right: 10px;
  }

  .firefly {
    position: sticky;
    position: -webkit-sticky;
    left: 50%;
    top: 50%;
    width: 0.4vw;
    height: 0.4vw;
    margin: -0.2vw 0 0 9.8vw;
    animation: ease 200s alternate infinite;
    pointer-events: none;
  }

  .firefly::before,
  .firefly::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: -10vw;
  }

  .firefly::before {
    background: black;
    opacity: 0.4;
    animation: drift ease alternate infinite;
  }

  .firefly::after {
    background: white;
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow;
    animation: drift ease alternate infinite, flash ease infinite;
  }

  .firefly:nth-child(1) {
    animation-name: move1;
  }

  .firefly:nth-child(1)::before {
    animation-duration: 10s;
  }

  .firefly:nth-child(1)::after {
    animation-duration: 10s, 5869ms;
    animation-delay: 0ms, 5103ms;
  }

  @keyframes move1 {
    0% {
      transform: translateX(-41vw) translateY(23vh) scale(0.42);
    }

    5% {
      transform: translateX(9vw) translateY(-45vh) scale(0.34);
    }

    10% {
      transform: translateX(-17vw) translateY(-42vh) scale(1);
    }

    15% {
      transform: translateX(8vw) translateY(-23vh) scale(0.51);
    }

    20% {
      transform: translateX(-29vw) translateY(-20vh) scale(0.56);
    }

    25% {
      transform: translateX(8vw) translateY(16vh) scale(0.79);
    }

    30% {
      transform: translateX(11vw) translateY(46vh) scale(0.54);
    }

    35% {
      transform: translateX(9vw) translateY(-45vh) scale(0.58);
    }

    40% {
      transform: translateX(10vw) translateY(-37vh) scale(0.69);
    }

    45% {
      transform: translateX(5vw) translateY(26vh) scale(0.52);
    }

    50% {
      transform: translateX(-36vw) translateY(-39vh) scale(0.43);
    }

    55% {
      transform: translateX(-45vw) translateY(35vh) scale(0.96);
    }

    60% {
      transform: translateX(17vw) translateY(38vh) scale(0.66);
    }

    65% {
      transform: translateX(-39vw) translateY(-34vh) scale(0.75);
    }

    70% {
      transform: translateX(-45vw) translateY(18vh) scale(0.72);
    }

    75% {
      transform: translateX(48vw) translateY(21vh) scale(0.42);
    }

    80% {
      transform: translateX(28vw) translateY(-2vh) scale(0.82);
    }

    85% {
      transform: translateX(-29vw) translateY(30vh) scale(0.48);
    }

    90% {
      transform: translateX(12vw) translateY(16vh) scale(0.42);
    }

    95% {
      transform: translateX(-8vw) translateY(-24vh) scale(0.52);
    }

    100% {
      transform: translateX(-49vw) translateY(6vh) scale(0.34);
    }
  }

  .firefly:nth-child(2) {
    animation-name: move2;
  }

  .firefly:nth-child(2)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(2)::after {
    animation-duration: 13s, 8622ms;
    animation-delay: 0ms, 6050ms;
  }

  @keyframes move2 {
    0% {
      transform: translateX(-13vw) translateY(17vh) scale(0.94);
    }

    4.347826087% {
      transform: translateX(12vw) translateY(24vh) scale(0.48);
    }

    8.6956521739% {
      transform: translateX(16vw) translateY(-40vh) scale(0.39);
    }

    13.0434782609% {
      transform: translateX(22vw) translateY(22vh) scale(0.68);
    }

    17.3913043478% {
      transform: translateX(19vw) translateY(-14vh) scale(0.42);
    }

    21.7391304348% {
      transform: translateX(2vw) translateY(-18vh) scale(0.3);
    }

    26.0869565217% {
      transform: translateX(2vw) translateY(38vh) scale(0.29);
    }

    30.4347826087% {
      transform: translateX(28vw) translateY(6vh) scale(0.87);
    }

    34.7826086957% {
      transform: translateX(-48vw) translateY(21vh) scale(0.88);
    }

    39.1304347826% {
      transform: translateX(32vw) translateY(15vh) scale(0.76);
    }

    43.4782608696% {
      transform: translateX(32vw) translateY(1vh) scale(0.31);
    }

    47.8260869565% {
      transform: translateX(35vw) translateY(0vh) scale(0.29);
    }

    52.1739130435% {
      transform: translateX(9vw) translateY(14vh) scale(0.27);
    }

    56.5217391304% {
      transform: translateX(12vw) translateY(-20vh) scale(0.64);
    }

    60.8695652174% {
      transform: translateX(-21vw) translateY(27vh) scale(0.72);
    }

    65.2173913043% {
      transform: translateX(-47vw) translateY(-33vh) scale(0.31);
    }

    69.5652173913% {
      transform: translateX(-25vw) translateY(7vh) scale(0.85);
    }

    73.9130434783% {
      transform: translateX(42vw) translateY(38vh) scale(0.88);
    }

    78.2608695652% {
      transform: translateX(11vw) translateY(10vh) scale(0.91);
    }

    82.6086956522% {
      transform: translateX(2vw) translateY(6vh) scale(0.59);
    }

    86.9565217391% {
      transform: translateX(37vw) translateY(4vh) scale(0.59);
    }

    91.3043478261% {
      transform: translateX(-34vw) translateY(-32vh) scale(0.95);
    }

    95.652173913% {
      transform: translateX(-47vw) translateY(32vh) scale(0.81);
    }

    100% {
      transform: translateX(32vw) translateY(22vh) scale(0.53);
    }
  }

  .firefly:nth-child(3) {
    animation-name: move3;
  }

  .firefly:nth-child(3)::before {
    animation-duration: 14s;
  }

  .firefly:nth-child(3)::after {
    animation-duration: 14s, 7972ms;
    animation-delay: 0ms, 2219ms;
  }

  @keyframes move3 {
    0% {
      transform: translateX(-9vw) translateY(-12vh) scale(0.35);
    }

    4% {
      transform: translateX(9vw) translateY(45vh) scale(0.75);
    }

    8% {
      transform: translateX(37vw) translateY(-35vh) scale(0.42);
    }

    12% {
      transform: translateX(-49vw) translateY(5vh) scale(0.46);
    }

    16% {
      transform: translateX(42vw) translateY(26vh) scale(0.34);
    }

    20% {
      transform: translateX(-12vw) translateY(19vh) scale(0.84);
    }

    24% {
      transform: translateX(23vw) translateY(6vh) scale(0.45);
    }

    28% {
      transform: translateX(18vw) translateY(-16vh) scale(0.47);
    }

    32% {
      transform: translateX(22vw) translateY(-40vh) scale(0.45);
    }

    36% {
      transform: translateX(-37vw) translateY(33vh) scale(0.75);
    }

    40% {
      transform: translateX(44vw) translateY(33vh) scale(0.61);
    }

    44% {
      transform: translateX(-44vw) translateY(-18vh) scale(0.57);
    }

    48% {
      transform: translateX(-9vw) translateY(39vh) scale(0.45);
    }

    52% {
      transform: translateX(10vw) translateY(41vh) scale(0.51);
    }

    56% {
      transform: translateX(23vw) translateY(-41vh) scale(0.31);
    }

    60% {
      transform: translateX(48vw) translateY(-5vh) scale(0.33);
    }

    64% {
      transform: translateX(-49vw) translateY(-43vh) scale(0.3);
    }

    68% {
      transform: translateX(-39vw) translateY(5vh) scale(0.44);
    }

    72% {
      transform: translateX(-18vw) translateY(-28vh) scale(0.94);
    }

    76% {
      transform: translateX(22vw) translateY(-18vh) scale(0.67);
    }

    80% {
      transform: translateX(10vw) translateY(-32vh) scale(0.56);
    }

    84% {
      transform: translateX(-7vw) translateY(-48vh) scale(0.47);
    }

    88% {
      transform: translateX(30vw) translateY(37vh) scale(0.64);
    }

    92% {
      transform: translateX(-46vw) translateY(-37vh) scale(0.53);
    }

    96% {
      transform: translateX(9vw) translateY(40vh) scale(0.87);
    }

    100% {
      transform: translateX(-48vw) translateY(-17vh) scale(0.46);
    }
  }

  .firefly:nth-child(4) {
    animation-name: move4;
  }

  .firefly:nth-child(4)::before {
    animation-duration: 10s;
  }

  .firefly:nth-child(4)::after {
    animation-duration: 10s, 8866ms;
    animation-delay: 0ms, 4122ms;
  }

  @keyframes move4 {
    0% {
      transform: translateX(-1vw) translateY(17vh) scale(0.76);
    }

    5.5555555556% {
      transform: translateX(-20vw) translateY(-6vh) scale(0.96);
    }

    11.1111111111% {
      transform: translateX(7vw) translateY(27vh) scale(0.44);
    }

    16.6666666667% {
      transform: translateX(1vw) translateY(32vh) scale(0.75);
    }

    22.2222222222% {
      transform: translateX(48vw) translateY(-14vh) scale(0.83);
    }

    27.7777777778% {
      transform: translateX(-34vw) translateY(-30vh) scale(0.6);
    }

    33.3333333333% {
      transform: translateX(40vw) translateY(-27vh) scale(1);
    }

    38.8888888889% {
      transform: translateX(-14vw) translateY(-28vh) scale(0.29);
    }

    44.4444444444% {
      transform: translateX(37vw) translateY(-14vh) scale(0.56);
    }

    50% {
      transform: translateX(29vw) translateY(8vh) scale(0.37);
    }

    55.5555555556% {
      transform: translateX(-14vw) translateY(21vh) scale(0.39);
    }

    61.1111111111% {
      transform: translateX(-1vw) translateY(38vh) scale(0.64);
    }

    66.6666666667% {
      transform: translateX(40vw) translateY(-17vh) scale(0.78);
    }

    72.2222222222% {
      transform: translateX(-6vw) translateY(-45vh) scale(0.54);
    }

    77.7777777778% {
      transform: translateX(-47vw) translateY(-46vh) scale(0.38);
    }

    83.3333333333% {
      transform: translateX(38vw) translateY(-13vh) scale(0.86);
    }

    88.8888888889% {
      transform: translateX(-8vw) translateY(35vh) scale(0.39);
    }

    94.4444444444% {
      transform: translateX(-25vw) translateY(-1vh) scale(0.44);
    }

    100% {
      transform: translateX(0vw) translateY(-34vh) scale(0.57);
    }
  }

  .firefly:nth-child(5) {
    animation-name: move5;
  }

  .firefly:nth-child(5)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(5)::after {
    animation-duration: 18s, 6640ms;
    animation-delay: 0ms, 1937ms;
  }

  @keyframes move5 {
    0% {
      transform: translateX(33vw) translateY(-14vh) scale(0.83);
    }

    4.5454545455% {
      transform: translateX(-14vw) translateY(6vh) scale(0.26);
    }

    9.0909090909% {
      transform: translateX(-15vw) translateY(-12vh) scale(0.56);
    }

    13.6363636364% {
      transform: translateX(39vw) translateY(20vh) scale(0.69);
    }

    18.1818181818% {
      transform: translateX(-21vw) translateY(-1vh) scale(0.27);
    }

    22.7272727273% {
      transform: translateX(-38vw) translateY(47vh) scale(0.43);
    }

    27.2727272727% {
      transform: translateX(10vw) translateY(-4vh) scale(0.53);
    }

    31.8181818182% {
      transform: translateX(-13vw) translateY(21vh) scale(0.88);
    }

    36.3636363636% {
      transform: translateX(-3vw) translateY(46vh) scale(0.76);
    }

    40.9090909091% {
      transform: translateX(-2vw) translateY(-11vh) scale(0.32);
    }

    45.4545454545% {
      transform: translateX(14vw) translateY(4vh) scale(0.32);
    }

    50% {
      transform: translateX(8vw) translateY(8vh) scale(0.76);
    }

    54.5454545455% {
      transform: translateX(18vw) translateY(-35vh) scale(0.6);
    }

    59.0909090909% {
      transform: translateX(15vw) translateY(-43vh) scale(0.28);
    }

    63.6363636364% {
      transform: translateX(-12vw) translateY(12vh) scale(0.68);
    }

    68.1818181818% {
      transform: translateX(5vw) translateY(25vh) scale(0.96);
    }

    72.7272727273% {
      transform: translateX(-21vw) translateY(-35vh) scale(0.4);
    }

    77.2727272727% {
      transform: translateX(-44vw) translateY(7vh) scale(0.98);
    }

    81.8181818182% {
      transform: translateX(40vw) translateY(-29vh) scale(0.91);
    }

    86.3636363636% {
      transform: translateX(30vw) translateY(-36vh) scale(0.49);
    }

    90.9090909091% {
      transform: translateX(48vw) translateY(22vh) scale(0.67);
    }

    95.4545454545% {
      transform: translateX(18vw) translateY(-10vh) scale(0.77);
    }

    100% {
      transform: translateX(-40vw) translateY(-6vh) scale(0.91);
    }
  }

  .firefly:nth-child(6) {
    animation-name: move6;
  }

  .firefly:nth-child(6)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(6)::after {
    animation-duration: 15s, 8079ms;
    animation-delay: 0ms, 6703ms;
  }

  @keyframes move6 {
    0% {
      transform: translateX(7vw) translateY(-40vh) scale(0.74);
    }

    4.7619047619% {
      transform: translateX(-23vw) translateY(32vh) scale(0.7);
    }

    9.5238095238% {
      transform: translateX(-9vw) translateY(0vh) scale(0.34);
    }

    14.2857142857% {
      transform: translateX(-36vw) translateY(18vh) scale(0.65);
    }

    19.0476190476% {
      transform: translateX(37vw) translateY(-8vh) scale(0.84);
    }

    23.8095238095% {
      transform: translateX(7vw) translateY(27vh) scale(0.44);
    }

    28.5714285714% {
      transform: translateX(-3vw) translateY(8vh) scale(0.93);
    }

    33.3333333333% {
      transform: translateX(-18vw) translateY(5vh) scale(0.87);
    }

    38.0952380952% {
      transform: translateX(25vw) translateY(19vh) scale(0.45);
    }

    42.8571428571% {
      transform: translateX(46vw) translateY(14vh) scale(0.95);
    }

    47.619047619% {
      transform: translateX(13vw) translateY(-10vh) scale(0.5);
    }

    52.380952381% {
      transform: translateX(15vw) translateY(11vh) scale(0.4);
    }

    57.1428571429% {
      transform: translateX(15vw) translateY(-35vh) scale(0.96);
    }

    61.9047619048% {
      transform: translateX(-13vw) translateY(41vh) scale(0.35);
    }

    66.6666666667% {
      transform: translateX(-23vw) translateY(26vh) scale(0.9);
    }

    71.4285714286% {
      transform: translateX(-7vw) translateY(4vh) scale(0.81);
    }

    76.1904761905% {
      transform: translateX(-49vw) translateY(-18vh) scale(0.84);
    }

    80.9523809524% {
      transform: translateX(26vw) translateY(33vh) scale(0.43);
    }

    85.7142857143% {
      transform: translateX(-6vw) translateY(23vh) scale(0.48);
    }

    90.4761904762% {
      transform: translateX(46vw) translateY(-5vh) scale(0.8);
    }

    95.2380952381% {
      transform: translateX(42vw) translateY(33vh) scale(0.6);
    }

    100% {
      transform: translateX(-28vw) translateY(26vh) scale(0.64);
    }
  }

  .firefly:nth-child(7) {
    animation-name: move7;
  }

  .firefly:nth-child(7)::before {
    animation-duration: 17s;
  }

  .firefly:nth-child(7)::after {
    animation-duration: 17s, 9827ms;
    animation-delay: 0ms, 3278ms;
  }

  @keyframes move7 {
    0% {
      transform: translateX(-1vw) translateY(41vh) scale(0.51);
    }

    5.5555555556% {
      transform: translateX(38vw) translateY(32vh) scale(0.61);
    }

    11.1111111111% {
      transform: translateX(36vw) translateY(44vh) scale(0.89);
    }

    16.6666666667% {
      transform: translateX(-22vw) translateY(-36vh) scale(0.68);
    }

    22.2222222222% {
      transform: translateX(4vw) translateY(-2vh) scale(0.87);
    }

    27.7777777778% {
      transform: translateX(11vw) translateY(-5vh) scale(0.48);
    }

    33.3333333333% {
      transform: translateX(-30vw) translateY(28vh) scale(0.43);
    }

    38.8888888889% {
      transform: translateX(-38vw) translateY(-4vh) scale(0.84);
    }

    44.4444444444% {
      transform: translateX(-29vw) translateY(33vh) scale(0.43);
    }

    50% {
      transform: translateX(1vw) translateY(-4vh) scale(0.96);
    }

    55.5555555556% {
      transform: translateX(38vw) translateY(-23vh) scale(0.5);
    }

    61.1111111111% {
      transform: translateX(-47vw) translateY(-1vh) scale(0.91);
    }

    66.6666666667% {
      transform: translateX(40vw) translateY(-8vh) scale(0.52);
    }

    72.2222222222% {
      transform: translateX(26vw) translateY(14vh) scale(0.83);
    }

    77.7777777778% {
      transform: translateX(17vw) translateY(-48vh) scale(0.97);
    }

    83.3333333333% {
      transform: translateX(-22vw) translateY(45vh) scale(0.85);
    }

    88.8888888889% {
      transform: translateX(-21vw) translateY(-9vh) scale(0.45);
    }

    94.4444444444% {
      transform: translateX(39vw) translateY(38vh) scale(0.59);
    }

    100% {
      transform: translateX(-37vw) translateY(-2vh) scale(0.55);
    }
  }

  .firefly:nth-child(8) {
    animation-name: move8;
  }

  .firefly:nth-child(8)::before {
    animation-duration: 9s;
  }

  .firefly:nth-child(8)::after {
    animation-duration: 9s, 8380ms;
    animation-delay: 0ms, 4603ms;
  }

  @keyframes move8 {
    0% {
      transform: translateX(-17vw) translateY(30vh) scale(0.58);
    }

    5.8823529412% {
      transform: translateX(-13vw) translateY(-42vh) scale(0.82);
    }

    11.7647058824% {
      transform: translateX(-10vw) translateY(-38vh) scale(0.86);
    }

    17.6470588235% {
      transform: translateX(49vw) translateY(47vh) scale(0.97);
    }

    23.5294117647% {
      transform: translateX(-17vw) translateY(-44vh) scale(1);
    }

    29.4117647059% {
      transform: translateX(-6vw) translateY(-20vh) scale(0.5);
    }

    35.2941176471% {
      transform: translateX(-39vw) translateY(-34vh) scale(0.33);
    }

    41.1764705882% {
      transform: translateX(38vw) translateY(49vh) scale(0.45);
    }

    47.0588235294% {
      transform: translateX(43vw) translateY(39vh) scale(0.43);
    }

    52.9411764706% {
      transform: translateX(46vw) translateY(-4vh) scale(0.27);
    }

    58.8235294118% {
      transform: translateX(-16vw) translateY(-20vh) scale(0.71);
    }

    64.7058823529% {
      transform: translateX(-43vw) translateY(-40vh) scale(0.84);
    }

    70.5882352941% {
      transform: translateX(-10vw) translateY(13vh) scale(0.81);
    }

    76.4705882353% {
      transform: translateX(38vw) translateY(1vh) scale(0.36);
    }

    82.3529411765% {
      transform: translateX(32vw) translateY(-40vh) scale(0.71);
    }

    88.2352941176% {
      transform: translateX(0vw) translateY(-49vh) scale(0.32);
    }

    94.1176470588% {
      transform: translateX(12vw) translateY(-49vh) scale(0.29);
    }

    100% {
      transform: translateX(7vw) translateY(-3vh) scale(0.94);
    }
  }

  .firefly:nth-child(9) {
    animation-name: move9;
  }

  .firefly:nth-child(9)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(9)::after {
    animation-duration: 18s, 10823ms;
    animation-delay: 0ms, 705ms;
  }

  @keyframes move9 {
    0% {
      transform: translateX(3vw) translateY(4vh) scale(0.41);
    }

    5.2631578947% {
      transform: translateX(-43vw) translateY(40vh) scale(0.79);
    }

    10.5263157895% {
      transform: translateX(-39vw) translateY(22vh) scale(0.38);
    }

    15.7894736842% {
      transform: translateX(-32vw) translateY(1vh) scale(0.41);
    }

    21.0526315789% {
      transform: translateX(-33vw) translateY(31vh) scale(0.81);
    }

    26.3157894737% {
      transform: translateX(2vw) translateY(-33vh) scale(0.43);
    }

    31.5789473684% {
      transform: translateX(-2vw) translateY(41vh) scale(0.78);
    }

    36.8421052632% {
      transform: translateX(-1vw) translateY(25vh) scale(0.69);
    }

    42.1052631579% {
      transform: translateX(-39vw) translateY(-28vh) scale(0.94);
    }

    47.3684210526% {
      transform: translateX(29vw) translateY(13vh) scale(0.42);
    }

    52.6315789474% {
      transform: translateX(42vw) translateY(17vh) scale(0.47);
    }

    57.8947368421% {
      transform: translateX(14vw) translateY(-9vh) scale(0.59);
    }

    63.1578947368% {
      transform: translateX(12vw) translateY(-8vh) scale(0.29);
    }

    68.4210526316% {
      transform: translateX(-31vw) translateY(-25vh) scale(0.81);
    }

    73.6842105263% {
      transform: translateX(-45vw) translateY(13vh) scale(0.33);
    }

    78.9473684211% {
      transform: translateX(-6vw) translateY(-48vh) scale(0.86);
    }

    84.2105263158% {
      transform: translateX(-18vw) translateY(-12vh) scale(0.99);
    }

    89.4736842105% {
      transform: translateX(22vw) translateY(-39vh) scale(0.51);
    }

    94.7368421053% {
      transform: translateX(43vw) translateY(-15vh) scale(0.6);
    }

    100% {
      transform: translateX(41vw) translateY(-14vh) scale(0.56);
    }
  }

  .firefly:nth-child(10) {
    animation-name: move10;
  }

  .firefly:nth-child(10)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(10)::after {
    animation-duration: 15s, 9714ms;
    animation-delay: 0ms, 7928ms;
  }

  @keyframes move10 {
    0% {
      transform: translateX(-5vw) translateY(25vh) scale(0.3);
    }

    3.8461538462% {
      transform: translateX(11vw) translateY(42vh) scale(0.81);
    }

    7.6923076923% {
      transform: translateX(-45vw) translateY(25vh) scale(0.44);
    }

    11.5384615385% {
      transform: translateX(23vw) translateY(-4vh) scale(0.43);
    }

    15.3846153846% {
      transform: translateX(10vw) translateY(12vh) scale(0.84);
    }

    19.2307692308% {
      transform: translateX(-33vw) translateY(38vh) scale(0.95);
    }

    23.0769230769% {
      transform: translateX(15vw) translateY(23vh) scale(0.56);
    }

    26.9230769231% {
      transform: translateX(-12vw) translateY(-17vh) scale(0.42);
    }

    30.7692307692% {
      transform: translateX(1vw) translateY(-34vh) scale(0.61);
    }

    34.6153846154% {
      transform: translateX(22vw) translateY(-25vh) scale(0.32);
    }

    38.4615384615% {
      transform: translateX(-17vw) translateY(26vh) scale(0.65);
    }

    42.3076923077% {
      transform: translateX(3vw) translateY(29vh) scale(0.91);
    }

    46.1538461538% {
      transform: translateX(-12vw) translateY(-36vh) scale(0.64);
    }

    50% {
      transform: translateX(-44vw) translateY(41vh) scale(0.44);
    }

    53.8461538462% {
      transform: translateX(9vw) translateY(-29vh) scale(0.6);
    }

    57.6923076923% {
      transform: translateX(22vw) translateY(30vh) scale(0.98);
    }

    61.5384615385% {
      transform: translateX(-20vw) translateY(33vh) scale(0.7);
    }

    65.3846153846% {
      transform: translateX(40vw) translateY(24vh) scale(0.38);
    }

    69.2307692308% {
      transform: translateX(-19vw) translateY(4vh) scale(0.48);
    }

    73.0769230769% {
      transform: translateX(-18vw) translateY(-32vh) scale(1);
    }

    76.9230769231% {
      transform: translateX(17vw) translateY(1vh) scale(0.68);
    }

    80.7692307692% {
      transform: translateX(-40vw) translateY(-16vh) scale(0.92);
    }

    84.6153846154% {
      transform: translateX(17vw) translateY(32vh) scale(0.9);
    }

    88.4615384615% {
      transform: translateX(14vw) translateY(45vh) scale(0.8);
    }

    92.3076923077% {
      transform: translateX(14vw) translateY(50vh) scale(0.74);
    }

    96.1538461538% {
      transform: translateX(26vw) translateY(47vh) scale(0.83);
    }

    100% {
      transform: translateX(49vw) translateY(-40vh) scale(0.95);
    }
  }

  .firefly:nth-child(11) {
    animation-name: move11;
  }

  .firefly:nth-child(11)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(11)::after {
    animation-duration: 13s, 7476ms;
    animation-delay: 0ms, 5436ms;
  }

  @keyframes move11 {
    0% {
      transform: translateX(-7vw) translateY(16vh) scale(0.42);
    }

    4.1666666667% {
      transform: translateX(-44vw) translateY(14vh) scale(0.89);
    }

    8.3333333333% {
      transform: translateX(17vw) translateY(-25vh) scale(0.72);
    }

    12.5% {
      transform: translateX(-40vw) translateY(-22vh) scale(0.47);
    }

    16.6666666667% {
      transform: translateX(49vw) translateY(12vh) scale(0.93);
    }

    20.8333333333% {
      transform: translateX(-45vw) translateY(21vh) scale(0.6);
    }

    25% {
      transform: translateX(36vw) translateY(-41vh) scale(0.48);
    }

    29.1666666667% {
      transform: translateX(-9vw) translateY(0vh) scale(0.98);
    }

    33.3333333333% {
      transform: translateX(-32vw) translateY(37vh) scale(0.29);
    }

    37.5% {
      transform: translateX(31vw) translateY(-46vh) scale(0.34);
    }

    41.6666666667% {
      transform: translateX(21vw) translateY(-45vh) scale(0.51);
    }

    45.8333333333% {
      transform: translateX(-12vw) translateY(-46vh) scale(0.87);
    }

    50% {
      transform: translateX(25vw) translateY(28vh) scale(0.48);
    }

    54.1666666667% {
      transform: translateX(-16vw) translateY(-25vh) scale(0.76);
    }

    58.3333333333% {
      transform: translateX(-4vw) translateY(48vh) scale(0.63);
    }

    62.5% {
      transform: translateX(48vw) translateY(-32vh) scale(0.31);
    }

    66.6666666667% {
      transform: translateX(-39vw) translateY(-39vh) scale(0.56);
    }

    70.8333333333% {
      transform: translateX(-1vw) translateY(37vh) scale(0.5);
    }

    75% {
      transform: translateX(-9vw) translateY(-5vh) scale(0.56);
    }

    79.1666666667% {
      transform: translateX(-11vw) translateY(13vh) scale(0.96);
    }

    83.3333333333% {
      transform: translateX(-1vw) translateY(-41vh) scale(0.55);
    }

    87.5% {
      transform: translateX(17vw) translateY(18vh) scale(0.58);
    }

    91.6666666667% {
      transform: translateX(-12vw) translateY(10vh) scale(0.99);
    }

    95.8333333333% {
      transform: translateX(33vw) translateY(50vh) scale(0.85);
    }

    100% {
      transform: translateX(43vw) translateY(38vh) scale(0.52);
    }
  }

  .firefly:nth-child(12) {
    animation-name: move12;
  }

  .firefly:nth-child(12)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(12)::after {
    animation-duration: 13s, 5428ms;
    animation-delay: 0ms, 4447ms;
  }

  @keyframes move12 {
    0% {
      transform: translateX(19vw) translateY(-25vh) scale(0.71);
    }

    3.7037037037% {
      transform: translateX(5vw) translateY(-44vh) scale(0.68);
    }

    7.4074074074% {
      transform: translateX(-11vw) translateY(21vh) scale(0.96);
    }

    11.1111111111% {
      transform: translateX(-16vw) translateY(-35vh) scale(0.62);
    }

    14.8148148148% {
      transform: translateX(19vw) translateY(36vh) scale(0.51);
    }

    18.5185185185% {
      transform: translateX(-31vw) translateY(39vh) scale(0.91);
    }

    22.2222222222% {
      transform: translateX(-13vw) translateY(-33vh) scale(0.57);
    }

    25.9259259259% {
      transform: translateX(-11vw) translateY(-43vh) scale(0.91);
    }

    29.6296296296% {
      transform: translateX(6vw) translateY(-23vh) scale(0.35);
    }

    33.3333333333% {
      transform: translateX(50vw) translateY(-5vh) scale(1);
    }

    37.037037037% {
      transform: translateX(-42vw) translateY(0vh) scale(0.98);
    }

    40.7407407407% {
      transform: translateX(35vw) translateY(40vh) scale(0.97);
    }

    44.4444444444% {
      transform: translateX(-6vw) translateY(-30vh) scale(0.28);
    }

    48.1481481481% {
      transform: translateX(7vw) translateY(0vh) scale(0.85);
    }

    51.8518518519% {
      transform: translateX(-29vw) translateY(-42vh) scale(0.82);
    }

    55.5555555556% {
      transform: translateX(-13vw) translateY(-39vh) scale(0.93);
    }

    59.2592592593% {
      transform: translateX(-16vw) translateY(43vh) scale(0.99);
    }

    62.962962963% {
      transform: translateX(19vw) translateY(38vh) scale(0.61);
    }

    66.6666666667% {
      transform: translateX(13vw) translateY(26vh) scale(0.75);
    }

    70.3703703704% {
      transform: translateX(-3vw) translateY(-23vh) scale(0.7);
    }

    74.0740740741% {
      transform: translateX(-19vw) translateY(3vh) scale(0.93);
    }

    77.7777777778% {
      transform: translateX(8vw) translateY(-18vh) scale(0.77);
    }

    81.4814814815% {
      transform: translateX(-23vw) translateY(-12vh) scale(0.88);
    }

    85.1851851852% {
      transform: translateX(29vw) translateY(-27vh) scale(0.75);
    }

    88.8888888889% {
      transform: translateX(-28vw) translateY(-32vh) scale(0.46);
    }

    92.5925925926% {
      transform: translateX(-40vw) translateY(11vh) scale(0.54);
    }

    96.2962962963% {
      transform: translateX(19vw) translateY(3vh) scale(0.86);
    }

    100% {
      transform: translateX(35vw) translateY(-45vh) scale(0.73);
    }
  }

  .firefly:nth-child(13) {
    animation-name: move13;
  }

  .firefly:nth-child(13)::before {
    animation-duration: 12s;
  }

  .firefly:nth-child(13)::after {
    animation-duration: 12s, 10857ms;
    animation-delay: 0ms, 8399ms;
  }

  @keyframes move13 {
    0% {
      transform: translateX(-20vw) translateY(28vh) scale(0.31);
    }

    5.2631578947% {
      transform: translateX(10vw) translateY(-35vh) scale(0.91);
    }

    10.5263157895% {
      transform: translateX(-12vw) translateY(7vh) scale(0.65);
    }

    15.7894736842% {
      transform: translateX(-35vw) translateY(-3vh) scale(0.56);
    }

    21.0526315789% {
      transform: translateX(-24vw) translateY(-16vh) scale(0.35);
    }

    26.3157894737% {
      transform: translateX(0vw) translateY(20vh) scale(0.57);
    }

    31.5789473684% {
      transform: translateX(-45vw) translateY(-3vh) scale(0.77);
    }

    36.8421052632% {
      transform: translateX(37vw) translateY(36vh) scale(0.7);
    }

    42.1052631579% {
      transform: translateX(-9vw) translateY(8vh) scale(0.92);
    }

    47.3684210526% {
      transform: translateX(-14vw) translateY(-41vh) scale(0.29);
    }

    52.6315789474% {
      transform: translateX(-12vw) translateY(-35vh) scale(0.71);
    }

    57.8947368421% {
      transform: translateX(-3vw) translateY(-21vh) scale(0.48);
    }

    63.1578947368% {
      transform: translateX(12vw) translateY(6vh) scale(0.8);
    }

    68.4210526316% {
      transform: translateX(16vw) translateY(-5vh) scale(0.75);
    }

    73.6842105263% {
      transform: translateX(-39vw) translateY(-43vh) scale(0.68);
    }

    78.9473684211% {
      transform: translateX(35vw) translateY(-46vh) scale(0.3);
    }

    84.2105263158% {
      transform: translateX(35vw) translateY(-45vh) scale(0.83);
    }

    89.4736842105% {
      transform: translateX(-8vw) translateY(13vh) scale(0.39);
    }

    94.7368421053% {
      transform: translateX(-12vw) translateY(32vh) scale(0.45);
    }

    100% {
      transform: translateX(-14vw) translateY(42vh) scale(0.67);
    }
  }

  .firefly:nth-child(14) {
    animation-name: move14;
  }

  .firefly:nth-child(14)::before {
    animation-duration: 11s;
  }

  .firefly:nth-child(14)::after {
    animation-duration: 11s, 7829ms;
    animation-delay: 0ms, 5814ms;
  }

  @keyframes move14 {
    0% {
      transform: translateX(10vw) translateY(-18vh) scale(0.99);
    }

    5.8823529412% {
      transform: translateX(7vw) translateY(18vh) scale(0.53);
    }

    11.7647058824% {
      transform: translateX(-38vw) translateY(3vh) scale(0.96);
    }

    17.6470588235% {
      transform: translateX(22vw) translateY(-38vh) scale(0.49);
    }

    23.5294117647% {
      transform: translateX(8vw) translateY(-38vh) scale(0.41);
    }

    29.4117647059% {
      transform: translateX(34vw) translateY(26vh) scale(0.7);
    }

    35.2941176471% {
      transform: translateX(-7vw) translateY(-3vh) scale(0.52);
    }

    41.1764705882% {
      transform: translateX(-43vw) translateY(45vh) scale(0.43);
    }

    47.0588235294% {
      transform: translateX(47vw) translateY(9vh) scale(0.89);
    }

    52.9411764706% {
      transform: translateX(45vw) translateY(32vh) scale(0.77);
    }

    58.8235294118% {
      transform: translateX(8vw) translateY(16vh) scale(0.64);
    }

    64.7058823529% {
      transform: translateX(-45vw) translateY(-38vh) scale(0.92);
    }

    70.5882352941% {
      transform: translateX(-6vw) translateY(-40vh) scale(0.64);
    }

    76.4705882353% {
      transform: translateX(29vw) translateY(-27vh) scale(0.83);
    }

    82.3529411765% {
      transform: translateX(4vw) translateY(10vh) scale(0.86);
    }

    88.2352941176% {
      transform: translateX(-8vw) translateY(43vh) scale(0.91);
    }

    94.1176470588% {
      transform: translateX(33vw) translateY(10vh) scale(0.57);
    }

    100% {
      transform: translateX(-17vw) translateY(14vh) scale(0.83);
    }
  }

  .firefly:nth-child(15) {
    animation-name: move15;
  }

  .firefly:nth-child(15)::before {
    animation-duration: 14s;
  }

  .firefly:nth-child(15)::after {
    animation-duration: 14s, 8455ms;
    animation-delay: 0ms, 1227ms;
  }

  @keyframes move15 {
    0% {
      transform: translateX(12vw) translateY(-1vh) scale(0.28);
    }

    4% {
      transform: translateX(6vw) translateY(-14vh) scale(0.61);
    }

    8% {
      transform: translateX(9vw) translateY(-13vh) scale(0.51);
    }

    12% {
      transform: translateX(26vw) translateY(14vh) scale(0.81);
    }

    16% {
      transform: translateX(-32vw) translateY(7vh) scale(0.99);
    }

    20% {
      transform: translateX(19vw) translateY(10vh) scale(0.27);
    }

    24% {
      transform: translateX(-13vw) translateY(-10vh) scale(0.83);
    }

    28% {
      transform: translateX(-35vw) translateY(-2vh) scale(0.39);
    }

    32% {
      transform: translateX(-28vw) translateY(-13vh) scale(0.82);
    }

    36% {
      transform: translateX(-45vw) translateY(28vh) scale(0.48);
    }

    40% {
      transform: translateX(-5vw) translateY(42vh) scale(0.74);
    }

    44% {
      transform: translateX(-30vw) translateY(22vh) scale(0.44);
    }

    48% {
      transform: translateX(15vw) translateY(-21vh) scale(0.55);
    }

    52% {
      transform: translateX(-6vw) translateY(1vh) scale(0.94);
    }

    56% {
      transform: translateX(-26vw) translateY(13vh) scale(0.26);
    }

    60% {
      transform: translateX(44vw) translateY(-49vh) scale(0.67);
    }

    64% {
      transform: translateX(23vw) translateY(-28vh) scale(0.35);
    }

    68% {
      transform: translateX(35vw) translateY(37vh) scale(0.56);
    }

    72% {
      transform: translateX(-4vw) translateY(43vh) scale(0.76);
    }

    76% {
      transform: translateX(-43vw) translateY(43vh) scale(0.56);
    }

    80% {
      transform: translateX(-12vw) translateY(1vh) scale(0.99);
    }

    84% {
      transform: translateX(-26vw) translateY(3vh) scale(0.3);
    }

    88% {
      transform: translateX(46vw) translateY(-9vh) scale(0.55);
    }

    92% {
      transform: translateX(33vw) translateY(-15vh) scale(0.66);
    }

    96% {
      transform: translateX(19vw) translateY(-3vh) scale(0.59);
    }

    100% {
      transform: translateX(-32vw) translateY(-18vh) scale(0.31);
    }
  }

  @keyframes drift {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes flash {

    0%,
    30%,
    100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw yellow;
    }

    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw yellow;
    }
  }


  .stickyBtn2 {
    width: 140px;
    height: 140px;
    opacity: 0.5;
    transition: 0.5s ease;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;

    transition: transform .4s;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }
}

@media screen and (min-width: 3840px) {

  .miniBarMain{
    display: none;
  }

  .littleNav{
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background-color: black;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .containerMob {
    display: none;
  }

  .cont {
    background: radial-gradient(circle, rgba(13, 24, 31, 0.2777485994397759) 0%, rgba(13, 24, 31, 0.48783263305322133) 69%, rgba(13, 24, 31, 0.8743872549019608) 100%), url('./assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .in2 p {
    font-size: 70px;
    color: white;
    font-weight: bold;
    text-align: center;
    letter-spacing: 4px;
    font-family: "Rise of Kingdom";
  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
  }

  .introduction {
    padding-top: 50px;
  }

  .container {
    max-width: 1100px;
    max-height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 280px;
    margin-bottom: 50px;
  }

  .container:hover img {
    opacity: 0.3;
  }

  .container img:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  .container img {
    width: 55%;
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(15deg);
    transition: 0.5s;
    box-shadow: 0 0 10px #d44e00;
    border: 2px solid #d6985c;
    z-index: 10000;
  }


  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .ag-format-container {
    width: 1142px;
    margin: 0 auto;
  }

  .ag-sparks {
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    position: relative;
  }

  .ag-sparks:before,
  .ag-sparks:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ag-sparks:before {
    content: "";

    -webkit-animation: an-blink 30s linear 3s infinite;
    -moz-animation: an-blink 30s linear 3s infinite;
    -o-animation: an-blink 30s linear 3s infinite;
    animation: an-blink 30s linear 3s infinite;
  }

  .ag-sparks:after {
    content: "";

    z-index: 10;
  }

  .ag-spark {
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    position: relative;
  }

  .ag-spark:before,
  .ag-spark:after {
    content: "";
    background-image: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/sparks/images/spark.png);
    background-position-x: 50%;
    background-repeat: repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    opacity: 0;

    will-change: transform;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ag-spark:before {
    -webkit-animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
    -moz-animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
    -o-animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
    animation: an-spark 1800s linear infinite, an-slow-shake 26s linear 13s infinite;
  }

  .ag-spark:after {
    -webkit-animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
    -moz-animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
    -o-animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
    animation: an-spark 3600s linear infinite, an-slow-shake-revert 26s linear infinite;
  }

  .ag-lightning {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ag-lightning:before,
  .ag-lightning:after {
    content: "";

    opacity: 0;

    will-change: transform;

    position: absolute;
  }

  .ag-lightning:before {
    width: 272px;
    background-image: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/sparks/images/lightning-1.png);

    top: -9.75em;
    left: 50%;

    -webkit-animation: an-lightning-first 30s linear 3s infinite;
    -moz-animation: an-lightning-first 30s linear 3s infinite;
    -o-animation: an-lightning-first 30s linear 3s infinite;
    animation: an-lightning-first 30s linear 3s infinite;
  }

  .ag-lightning:after {
    width: 254px;
    background-image: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/sparks/images/lightning-1.png);

    top: -10.1875em;
    right: -6.1875em;

    -webkit-animation: an-lightning-second 30s linear 3s infinite;
    -moz-animation: an-lightning-second 30s linear 3s infinite;
    -o-animation: an-lightning-second 30s linear 3s infinite;
    animation: an-lightning-second 30s linear 3s infinite;
  }

  @-webkit-keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @-moz-keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @-o-keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @keyframes an-blink {
    0% {
      background: rgba(255, 255, 255, 0);
    }

    1.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    1.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    1.7% {
      background: rgba(255, 255, 255, 0);
    }

    2.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    2.9% {
      background: rgba(255, 255, 255, 0);
    }

    3.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    3.4% {
      background: rgba(255, 255, 255, 0);
    }

    6.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    6.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    7.2% {
      background: rgba(255, 255, 255, 0);
    }

    36.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    36.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    37.2% {
      background: rgba(255, 255, 255, 0);
    }

    53.5% {
      background: rgba(0, 0, 0, 0.1);
    }

    53.66% {
      background: rgba(255, 255, 255, 0.1);
    }

    53.7% {
      background: rgba(255, 255, 255, 0);
    }

    54.66% {
      background: rgba(0, 0, 0, 0.1);
    }

    54.9% {
      background: rgba(255, 255, 255, 0);
    }

    55.2% {
      background: rgba(255, 255, 255, 0.1);
    }

    55.4% {
      background: rgba(255, 255, 255, 0);
    }

    76.7% {
      background: rgba(0, 0, 0, 0.1);
    }

    76.8% {
      background: rgba(255, 255, 255, 0.1);
    }

    77.2% {
      background: rgba(255, 255, 255, 0);
    }

    100% {
      background: rgba(255, 255, 255, 0);
    }
  }

  @-webkit-keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @-moz-keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @-o-keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @keyframes an-spark {
    0% {
      background-position-y: 0;

      opacity: 0;
    }

    1%,
    99% {
      opacity: 1;
    }

    100% {
      background-position-y: -3750em;

      opacity: 0;
    }
  }

  @-webkit-keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @-moz-keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      -moz-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      -moz-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @-o-keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      -o-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      -o-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @keyframes an-slow-shake {
    0% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(1.25em);
      -moz-transform: translateX(1.25em);
      -o-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }

    50% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(-1.25em);
      -moz-transform: translateX(-1.25em);
      -o-transform: translateX(-1.25em);
      transform: translateX(-1.25em);
    }

    100% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @-webkit-keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @-moz-keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      -moz-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      -moz-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @-o-keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      -o-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      -o-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @keyframes an-slow-shake-revert {
    0% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    25% {
      -webkit-transform: scale(-1, 1) translateX(1.25em);
      -moz-transform: scale(-1, 1) translateX(1.25em);
      -o-transform: scale(-1, 1) translateX(1.25em);
      transform: scale(-1, 1) translateX(1.25em);
    }

    50% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }

    75% {
      -webkit-transform: scale(-1, 1) translateX(-1.25em);
      -moz-transform: scale(-1, 1) translateX(-1.25em);
      -o-transform: scale(-1, 1) translateX(-1.25em);
      transform: scale(-1, 1) translateX(-1.25em);
    }

    100% {
      -webkit-transform: scale(-1, 1) translateX(0);
      -moz-transform: scale(-1, 1) translateX(0);
      -o-transform: scale(-1, 1) translateX(0);
      transform: scale(-1, 1) translateX(0);
    }
  }

  @-webkit-keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @-moz-keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @-o-keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes an-lightning-first {

    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }

    6.8% {
      height: 22.375em;

      opacity: 1;
    }

    7.6% {
      opacity: 0;
    }

    7.7% {
      height: 22.375em;
    }

    7.8%,
    49.9% {
      height: 0;
    }

    50% {
      height: 22.375em;

      opacity: 1;
    }

    51% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @-moz-keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @-o-keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @keyframes an-lightning-second {
    0% {
      height: 0;

      opacity: 0;
    }

    1.66% {
      height: 23.9375em;

      opacity: 1;
    }

    1.7% {
      opacity: 0;
    }

    2.66% {
      opacity: 0;
    }

    3.2% {
      opacity: 1;
    }

    3.4% {
      opacity: 0;
    }

    7.8%,
    76.9% {
      height: 0;
    }

    77% {
      height: 23.9375em;

      opacity: 1;
    }

    78%,
    100% {
      opacity: 0;
    }
  }

  @media only screen and (max-width: 767px) {
    .ag-format-container {
      width: 96%;
    }

  }

  @media only screen and (max-width: 639px) {}

  @media only screen and (max-width: 479px) {}

  @media (min-width: 768px) and (max-width: 979px) {
    .ag-format-container {
      width: 750px;
    }

  }

  @media (min-width: 980px) and (max-width: 1161px) {
    .ag-format-container {
      width: 960px;
    }

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: linear-gradient(87deg, rgba(0, 0, 0, 0.1956954656862745) 0%, rgba(0, 0, 0, 0.6606814600840336) 35%, rgba(0, 0, 0, 0.8819699754901961) 100%), url('./assets/map.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 200px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 -1px 8px #FFF, 0 -2px 20px rgba(255, 255, 0, 0.616), 0 -10px 35px #ff80008c, 0 -18px 55px rgba(255, 0, 0, 0.719);
  }

  .storyHK {
    font-size: 200px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 50px;
    text-shadow: 0 -1px 8px #FFF, 0 -2px 20px rgba(255, 255, 0, 0.616), 0 -10px 35px #ff80008c, 0 -18px 55px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 150px;
    color: white;
    font-weight: 500;
    text-align: left;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .storyH2-2 {
    font-size: 150px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 52px;
    text-align: left;
    display: flex;
    justify-content: space-between;
  }

  .aboutP p {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;

  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }


  .map {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 50px;
    background: rgba(19, 19, 19, 0.767);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    color: rgb(0, 0, 0);
    padding: 1.5rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #e2e2e2 2px ridge;
    box-shadow: 0 0 15px #704531;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 45px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .faqbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13126969537815125) 0%, rgba(0, 0, 0, 0.3385526085434174) 35%, rgba(0, 0, 0, 0.7979363620448179) 100%), url('./assets/faqbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .team {
    width: 60%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .teambg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13126969537815125) 0%, rgba(0, 0, 0, 0.3385526085434174) 35%, rgba(0, 0, 0, 0.7979363620448179) 100%), url('./assets/teambg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
  }

  .kingdomsBg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
    padding-top: 50px;
  }

  .kingdomsSection1 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/lionKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection2 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/raakshaKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection3 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/snakeKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection4 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/eagleKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .kingdomsSection5 {
    display: flex;
    flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4730063900560224) 0%, rgba(0, 0, 0, 0.7391128326330532) 35%, rgba(0, 0, 0, 0.8539587710084033) 100%), url('./assets/dragonKing.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .logoF {
    text-align: center;
    font-family: "Rise of Kingdom";
    margin-bottom: 10px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #ff0, 0 -10px 20px #ff8000, 0 -18px 40px #F00;
    color: white;
    font-size: 150px
  }

  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(250, 219, 207);
    text-align: center;
    font-size: 45px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    margin-right: 35px;
    text-align: center;
    margin-top: 80px;
    font-size: 45px;
    margin-bottom: 40px;
  }

  .devs img {
    width: 65px;
    height: 65px;
    opacity: 0.9;
    transition: 0.3s ease;
    margin-left: 15px;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fd2;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 25px;
    margin-right: 25px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connect div:hover {
    color: rgb(255, 185, 138);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .icons img {
    width: 80px;
    height: 80px;
    margin-left: 25px;
    margin-right: 25px;
    transition: transform .2s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 50px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;

  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #99faf500;
    font-size: 50px;
    padding: 25px;
    width: 480px;
    border: 2px rgb(216, 179, 130) solid;
    color: rgb(255, 255, 255);
    box-shadow: 0 0 10px #b18a41;
    transition: 0.3s ease;
    cursor: none;
    margin-left: 10px;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 220px;
    height: 220px;
  }

  .downImg {
    width: 110px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
    margin-top: 160px;
  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  /*........................Roadmap........................*/

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 90px;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #eeb38b;
    color: white;
    font-family: "Rise of Kingdom";
    margin-left: 20px;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 38px;
    width: 800px;
    margin-left: 20px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 140px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 110px;
    height: 110px;
    border: 4px solid #fff;
    position: absolute;
    border: 5px solid rgba(63, 63, 63, 0.253);
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #f2b14b;
    left: 63.5px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    border: 5px solid rgba(63, 63, 63, 0.253);
    left: 63.5px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #f2b14b;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #f2b14b;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid rgba(63, 63, 63, 0.253);

  }

  .footPrint2 {
    width: 80px;
    height: 80px;
  }

  .rmbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13126969537815125) 0%, rgba(0, 0, 0, 0.3385526085434174) 35%, rgba(0, 0, 0, 0.7979363620448179) 100%), url('./assets/rmbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  h4 {
    text-transform: capitalize;
    background-color: rgba(0, 0, 0, 0.384);
    color: white;
    padding: 15px;
    box-shadow: 0 0 15px #f2b24b62;
    font-size: 45px;
    width: 800px;
    margin-bottom: 30px;
    margin-left: 20px;

  }

  .fire {
    width: 70px;
    height: 70px;
    margin-right: 10px;
  }

  .fireGlow{
    animation: glow 2s ease-in-out infinite alternate;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    margin-right: 10px;
  }

  .firefly {
    position: sticky;
    position: -webkit-sticky;
    left: 50%;
    top: 50%;
    width: 0.4vw;
    height: 0.4vw;
    margin: -0.2vw 0 0 9.8vw;
    animation: ease 200s alternate infinite;
    pointer-events: none;
  }

  .firefly::before,
  .firefly::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: -10vw;
  }

  .firefly::before {
    background: black;
    opacity: 0.4;
    animation: drift ease alternate infinite;
  }

  .firefly::after {
    background: white;
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow;
    animation: drift ease alternate infinite, flash ease infinite;
  }

  .firefly:nth-child(1) {
    animation-name: move1;
  }

  .firefly:nth-child(1)::before {
    animation-duration: 10s;
  }

  .firefly:nth-child(1)::after {
    animation-duration: 10s, 5869ms;
    animation-delay: 0ms, 5103ms;
  }

  @keyframes move1 {
    0% {
      transform: translateX(-41vw) translateY(23vh) scale(0.42);
    }

    5% {
      transform: translateX(9vw) translateY(-45vh) scale(0.34);
    }

    10% {
      transform: translateX(-17vw) translateY(-42vh) scale(1);
    }

    15% {
      transform: translateX(8vw) translateY(-23vh) scale(0.51);
    }

    20% {
      transform: translateX(-29vw) translateY(-20vh) scale(0.56);
    }

    25% {
      transform: translateX(8vw) translateY(16vh) scale(0.79);
    }

    30% {
      transform: translateX(11vw) translateY(46vh) scale(0.54);
    }

    35% {
      transform: translateX(9vw) translateY(-45vh) scale(0.58);
    }

    40% {
      transform: translateX(10vw) translateY(-37vh) scale(0.69);
    }

    45% {
      transform: translateX(5vw) translateY(26vh) scale(0.52);
    }

    50% {
      transform: translateX(-36vw) translateY(-39vh) scale(0.43);
    }

    55% {
      transform: translateX(-45vw) translateY(35vh) scale(0.96);
    }

    60% {
      transform: translateX(17vw) translateY(38vh) scale(0.66);
    }

    65% {
      transform: translateX(-39vw) translateY(-34vh) scale(0.75);
    }

    70% {
      transform: translateX(-45vw) translateY(18vh) scale(0.72);
    }

    75% {
      transform: translateX(48vw) translateY(21vh) scale(0.42);
    }

    80% {
      transform: translateX(28vw) translateY(-2vh) scale(0.82);
    }

    85% {
      transform: translateX(-29vw) translateY(30vh) scale(0.48);
    }

    90% {
      transform: translateX(12vw) translateY(16vh) scale(0.42);
    }

    95% {
      transform: translateX(-8vw) translateY(-24vh) scale(0.52);
    }

    100% {
      transform: translateX(-49vw) translateY(6vh) scale(0.34);
    }
  }

  .firefly:nth-child(2) {
    animation-name: move2;
  }

  .firefly:nth-child(2)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(2)::after {
    animation-duration: 13s, 8622ms;
    animation-delay: 0ms, 6050ms;
  }

  @keyframes move2 {
    0% {
      transform: translateX(-13vw) translateY(17vh) scale(0.94);
    }

    4.347826087% {
      transform: translateX(12vw) translateY(24vh) scale(0.48);
    }

    8.6956521739% {
      transform: translateX(16vw) translateY(-40vh) scale(0.39);
    }

    13.0434782609% {
      transform: translateX(22vw) translateY(22vh) scale(0.68);
    }

    17.3913043478% {
      transform: translateX(19vw) translateY(-14vh) scale(0.42);
    }

    21.7391304348% {
      transform: translateX(2vw) translateY(-18vh) scale(0.3);
    }

    26.0869565217% {
      transform: translateX(2vw) translateY(38vh) scale(0.29);
    }

    30.4347826087% {
      transform: translateX(28vw) translateY(6vh) scale(0.87);
    }

    34.7826086957% {
      transform: translateX(-48vw) translateY(21vh) scale(0.88);
    }

    39.1304347826% {
      transform: translateX(32vw) translateY(15vh) scale(0.76);
    }

    43.4782608696% {
      transform: translateX(32vw) translateY(1vh) scale(0.31);
    }

    47.8260869565% {
      transform: translateX(35vw) translateY(0vh) scale(0.29);
    }

    52.1739130435% {
      transform: translateX(9vw) translateY(14vh) scale(0.27);
    }

    56.5217391304% {
      transform: translateX(12vw) translateY(-20vh) scale(0.64);
    }

    60.8695652174% {
      transform: translateX(-21vw) translateY(27vh) scale(0.72);
    }

    65.2173913043% {
      transform: translateX(-47vw) translateY(-33vh) scale(0.31);
    }

    69.5652173913% {
      transform: translateX(-25vw) translateY(7vh) scale(0.85);
    }

    73.9130434783% {
      transform: translateX(42vw) translateY(38vh) scale(0.88);
    }

    78.2608695652% {
      transform: translateX(11vw) translateY(10vh) scale(0.91);
    }

    82.6086956522% {
      transform: translateX(2vw) translateY(6vh) scale(0.59);
    }

    86.9565217391% {
      transform: translateX(37vw) translateY(4vh) scale(0.59);
    }

    91.3043478261% {
      transform: translateX(-34vw) translateY(-32vh) scale(0.95);
    }

    95.652173913% {
      transform: translateX(-47vw) translateY(32vh) scale(0.81);
    }

    100% {
      transform: translateX(32vw) translateY(22vh) scale(0.53);
    }
  }

  .firefly:nth-child(3) {
    animation-name: move3;
  }

  .firefly:nth-child(3)::before {
    animation-duration: 14s;
  }

  .firefly:nth-child(3)::after {
    animation-duration: 14s, 7972ms;
    animation-delay: 0ms, 2219ms;
  }

  @keyframes move3 {
    0% {
      transform: translateX(-9vw) translateY(-12vh) scale(0.35);
    }

    4% {
      transform: translateX(9vw) translateY(45vh) scale(0.75);
    }

    8% {
      transform: translateX(37vw) translateY(-35vh) scale(0.42);
    }

    12% {
      transform: translateX(-49vw) translateY(5vh) scale(0.46);
    }

    16% {
      transform: translateX(42vw) translateY(26vh) scale(0.34);
    }

    20% {
      transform: translateX(-12vw) translateY(19vh) scale(0.84);
    }

    24% {
      transform: translateX(23vw) translateY(6vh) scale(0.45);
    }

    28% {
      transform: translateX(18vw) translateY(-16vh) scale(0.47);
    }

    32% {
      transform: translateX(22vw) translateY(-40vh) scale(0.45);
    }

    36% {
      transform: translateX(-37vw) translateY(33vh) scale(0.75);
    }

    40% {
      transform: translateX(44vw) translateY(33vh) scale(0.61);
    }

    44% {
      transform: translateX(-44vw) translateY(-18vh) scale(0.57);
    }

    48% {
      transform: translateX(-9vw) translateY(39vh) scale(0.45);
    }

    52% {
      transform: translateX(10vw) translateY(41vh) scale(0.51);
    }

    56% {
      transform: translateX(23vw) translateY(-41vh) scale(0.31);
    }

    60% {
      transform: translateX(48vw) translateY(-5vh) scale(0.33);
    }

    64% {
      transform: translateX(-49vw) translateY(-43vh) scale(0.3);
    }

    68% {
      transform: translateX(-39vw) translateY(5vh) scale(0.44);
    }

    72% {
      transform: translateX(-18vw) translateY(-28vh) scale(0.94);
    }

    76% {
      transform: translateX(22vw) translateY(-18vh) scale(0.67);
    }

    80% {
      transform: translateX(10vw) translateY(-32vh) scale(0.56);
    }

    84% {
      transform: translateX(-7vw) translateY(-48vh) scale(0.47);
    }

    88% {
      transform: translateX(30vw) translateY(37vh) scale(0.64);
    }

    92% {
      transform: translateX(-46vw) translateY(-37vh) scale(0.53);
    }

    96% {
      transform: translateX(9vw) translateY(40vh) scale(0.87);
    }

    100% {
      transform: translateX(-48vw) translateY(-17vh) scale(0.46);
    }
  }

  .firefly:nth-child(4) {
    animation-name: move4;
  }

  .firefly:nth-child(4)::before {
    animation-duration: 10s;
  }

  .firefly:nth-child(4)::after {
    animation-duration: 10s, 8866ms;
    animation-delay: 0ms, 4122ms;
  }

  @keyframes move4 {
    0% {
      transform: translateX(-1vw) translateY(17vh) scale(0.76);
    }

    5.5555555556% {
      transform: translateX(-20vw) translateY(-6vh) scale(0.96);
    }

    11.1111111111% {
      transform: translateX(7vw) translateY(27vh) scale(0.44);
    }

    16.6666666667% {
      transform: translateX(1vw) translateY(32vh) scale(0.75);
    }

    22.2222222222% {
      transform: translateX(48vw) translateY(-14vh) scale(0.83);
    }

    27.7777777778% {
      transform: translateX(-34vw) translateY(-30vh) scale(0.6);
    }

    33.3333333333% {
      transform: translateX(40vw) translateY(-27vh) scale(1);
    }

    38.8888888889% {
      transform: translateX(-14vw) translateY(-28vh) scale(0.29);
    }

    44.4444444444% {
      transform: translateX(37vw) translateY(-14vh) scale(0.56);
    }

    50% {
      transform: translateX(29vw) translateY(8vh) scale(0.37);
    }

    55.5555555556% {
      transform: translateX(-14vw) translateY(21vh) scale(0.39);
    }

    61.1111111111% {
      transform: translateX(-1vw) translateY(38vh) scale(0.64);
    }

    66.6666666667% {
      transform: translateX(40vw) translateY(-17vh) scale(0.78);
    }

    72.2222222222% {
      transform: translateX(-6vw) translateY(-45vh) scale(0.54);
    }

    77.7777777778% {
      transform: translateX(-47vw) translateY(-46vh) scale(0.38);
    }

    83.3333333333% {
      transform: translateX(38vw) translateY(-13vh) scale(0.86);
    }

    88.8888888889% {
      transform: translateX(-8vw) translateY(35vh) scale(0.39);
    }

    94.4444444444% {
      transform: translateX(-25vw) translateY(-1vh) scale(0.44);
    }

    100% {
      transform: translateX(0vw) translateY(-34vh) scale(0.57);
    }
  }

  .firefly:nth-child(5) {
    animation-name: move5;
  }

  .firefly:nth-child(5)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(5)::after {
    animation-duration: 18s, 6640ms;
    animation-delay: 0ms, 1937ms;
  }

  @keyframes move5 {
    0% {
      transform: translateX(33vw) translateY(-14vh) scale(0.83);
    }

    4.5454545455% {
      transform: translateX(-14vw) translateY(6vh) scale(0.26);
    }

    9.0909090909% {
      transform: translateX(-15vw) translateY(-12vh) scale(0.56);
    }

    13.6363636364% {
      transform: translateX(39vw) translateY(20vh) scale(0.69);
    }

    18.1818181818% {
      transform: translateX(-21vw) translateY(-1vh) scale(0.27);
    }

    22.7272727273% {
      transform: translateX(-38vw) translateY(47vh) scale(0.43);
    }

    27.2727272727% {
      transform: translateX(10vw) translateY(-4vh) scale(0.53);
    }

    31.8181818182% {
      transform: translateX(-13vw) translateY(21vh) scale(0.88);
    }

    36.3636363636% {
      transform: translateX(-3vw) translateY(46vh) scale(0.76);
    }

    40.9090909091% {
      transform: translateX(-2vw) translateY(-11vh) scale(0.32);
    }

    45.4545454545% {
      transform: translateX(14vw) translateY(4vh) scale(0.32);
    }

    50% {
      transform: translateX(8vw) translateY(8vh) scale(0.76);
    }

    54.5454545455% {
      transform: translateX(18vw) translateY(-35vh) scale(0.6);
    }

    59.0909090909% {
      transform: translateX(15vw) translateY(-43vh) scale(0.28);
    }

    63.6363636364% {
      transform: translateX(-12vw) translateY(12vh) scale(0.68);
    }

    68.1818181818% {
      transform: translateX(5vw) translateY(25vh) scale(0.96);
    }

    72.7272727273% {
      transform: translateX(-21vw) translateY(-35vh) scale(0.4);
    }

    77.2727272727% {
      transform: translateX(-44vw) translateY(7vh) scale(0.98);
    }

    81.8181818182% {
      transform: translateX(40vw) translateY(-29vh) scale(0.91);
    }

    86.3636363636% {
      transform: translateX(30vw) translateY(-36vh) scale(0.49);
    }

    90.9090909091% {
      transform: translateX(48vw) translateY(22vh) scale(0.67);
    }

    95.4545454545% {
      transform: translateX(18vw) translateY(-10vh) scale(0.77);
    }

    100% {
      transform: translateX(-40vw) translateY(-6vh) scale(0.91);
    }
  }

  .firefly:nth-child(6) {
    animation-name: move6;
  }

  .firefly:nth-child(6)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(6)::after {
    animation-duration: 15s, 8079ms;
    animation-delay: 0ms, 6703ms;
  }

  @keyframes move6 {
    0% {
      transform: translateX(7vw) translateY(-40vh) scale(0.74);
    }

    4.7619047619% {
      transform: translateX(-23vw) translateY(32vh) scale(0.7);
    }

    9.5238095238% {
      transform: translateX(-9vw) translateY(0vh) scale(0.34);
    }

    14.2857142857% {
      transform: translateX(-36vw) translateY(18vh) scale(0.65);
    }

    19.0476190476% {
      transform: translateX(37vw) translateY(-8vh) scale(0.84);
    }

    23.8095238095% {
      transform: translateX(7vw) translateY(27vh) scale(0.44);
    }

    28.5714285714% {
      transform: translateX(-3vw) translateY(8vh) scale(0.93);
    }

    33.3333333333% {
      transform: translateX(-18vw) translateY(5vh) scale(0.87);
    }

    38.0952380952% {
      transform: translateX(25vw) translateY(19vh) scale(0.45);
    }

    42.8571428571% {
      transform: translateX(46vw) translateY(14vh) scale(0.95);
    }

    47.619047619% {
      transform: translateX(13vw) translateY(-10vh) scale(0.5);
    }

    52.380952381% {
      transform: translateX(15vw) translateY(11vh) scale(0.4);
    }

    57.1428571429% {
      transform: translateX(15vw) translateY(-35vh) scale(0.96);
    }

    61.9047619048% {
      transform: translateX(-13vw) translateY(41vh) scale(0.35);
    }

    66.6666666667% {
      transform: translateX(-23vw) translateY(26vh) scale(0.9);
    }

    71.4285714286% {
      transform: translateX(-7vw) translateY(4vh) scale(0.81);
    }

    76.1904761905% {
      transform: translateX(-49vw) translateY(-18vh) scale(0.84);
    }

    80.9523809524% {
      transform: translateX(26vw) translateY(33vh) scale(0.43);
    }

    85.7142857143% {
      transform: translateX(-6vw) translateY(23vh) scale(0.48);
    }

    90.4761904762% {
      transform: translateX(46vw) translateY(-5vh) scale(0.8);
    }

    95.2380952381% {
      transform: translateX(42vw) translateY(33vh) scale(0.6);
    }

    100% {
      transform: translateX(-28vw) translateY(26vh) scale(0.64);
    }
  }

  .firefly:nth-child(7) {
    animation-name: move7;
  }

  .firefly:nth-child(7)::before {
    animation-duration: 17s;
  }

  .firefly:nth-child(7)::after {
    animation-duration: 17s, 9827ms;
    animation-delay: 0ms, 3278ms;
  }

  @keyframes move7 {
    0% {
      transform: translateX(-1vw) translateY(41vh) scale(0.51);
    }

    5.5555555556% {
      transform: translateX(38vw) translateY(32vh) scale(0.61);
    }

    11.1111111111% {
      transform: translateX(36vw) translateY(44vh) scale(0.89);
    }

    16.6666666667% {
      transform: translateX(-22vw) translateY(-36vh) scale(0.68);
    }

    22.2222222222% {
      transform: translateX(4vw) translateY(-2vh) scale(0.87);
    }

    27.7777777778% {
      transform: translateX(11vw) translateY(-5vh) scale(0.48);
    }

    33.3333333333% {
      transform: translateX(-30vw) translateY(28vh) scale(0.43);
    }

    38.8888888889% {
      transform: translateX(-38vw) translateY(-4vh) scale(0.84);
    }

    44.4444444444% {
      transform: translateX(-29vw) translateY(33vh) scale(0.43);
    }

    50% {
      transform: translateX(1vw) translateY(-4vh) scale(0.96);
    }

    55.5555555556% {
      transform: translateX(38vw) translateY(-23vh) scale(0.5);
    }

    61.1111111111% {
      transform: translateX(-47vw) translateY(-1vh) scale(0.91);
    }

    66.6666666667% {
      transform: translateX(40vw) translateY(-8vh) scale(0.52);
    }

    72.2222222222% {
      transform: translateX(26vw) translateY(14vh) scale(0.83);
    }

    77.7777777778% {
      transform: translateX(17vw) translateY(-48vh) scale(0.97);
    }

    83.3333333333% {
      transform: translateX(-22vw) translateY(45vh) scale(0.85);
    }

    88.8888888889% {
      transform: translateX(-21vw) translateY(-9vh) scale(0.45);
    }

    94.4444444444% {
      transform: translateX(39vw) translateY(38vh) scale(0.59);
    }

    100% {
      transform: translateX(-37vw) translateY(-2vh) scale(0.55);
    }
  }

  .firefly:nth-child(8) {
    animation-name: move8;
  }

  .firefly:nth-child(8)::before {
    animation-duration: 9s;
  }

  .firefly:nth-child(8)::after {
    animation-duration: 9s, 8380ms;
    animation-delay: 0ms, 4603ms;
  }

  @keyframes move8 {
    0% {
      transform: translateX(-17vw) translateY(30vh) scale(0.58);
    }

    5.8823529412% {
      transform: translateX(-13vw) translateY(-42vh) scale(0.82);
    }

    11.7647058824% {
      transform: translateX(-10vw) translateY(-38vh) scale(0.86);
    }

    17.6470588235% {
      transform: translateX(49vw) translateY(47vh) scale(0.97);
    }

    23.5294117647% {
      transform: translateX(-17vw) translateY(-44vh) scale(1);
    }

    29.4117647059% {
      transform: translateX(-6vw) translateY(-20vh) scale(0.5);
    }

    35.2941176471% {
      transform: translateX(-39vw) translateY(-34vh) scale(0.33);
    }

    41.1764705882% {
      transform: translateX(38vw) translateY(49vh) scale(0.45);
    }

    47.0588235294% {
      transform: translateX(43vw) translateY(39vh) scale(0.43);
    }

    52.9411764706% {
      transform: translateX(46vw) translateY(-4vh) scale(0.27);
    }

    58.8235294118% {
      transform: translateX(-16vw) translateY(-20vh) scale(0.71);
    }

    64.7058823529% {
      transform: translateX(-43vw) translateY(-40vh) scale(0.84);
    }

    70.5882352941% {
      transform: translateX(-10vw) translateY(13vh) scale(0.81);
    }

    76.4705882353% {
      transform: translateX(38vw) translateY(1vh) scale(0.36);
    }

    82.3529411765% {
      transform: translateX(32vw) translateY(-40vh) scale(0.71);
    }

    88.2352941176% {
      transform: translateX(0vw) translateY(-49vh) scale(0.32);
    }

    94.1176470588% {
      transform: translateX(12vw) translateY(-49vh) scale(0.29);
    }

    100% {
      transform: translateX(7vw) translateY(-3vh) scale(0.94);
    }
  }

  .firefly:nth-child(9) {
    animation-name: move9;
  }

  .firefly:nth-child(9)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(9)::after {
    animation-duration: 18s, 10823ms;
    animation-delay: 0ms, 705ms;
  }

  @keyframes move9 {
    0% {
      transform: translateX(3vw) translateY(4vh) scale(0.41);
    }

    5.2631578947% {
      transform: translateX(-43vw) translateY(40vh) scale(0.79);
    }

    10.5263157895% {
      transform: translateX(-39vw) translateY(22vh) scale(0.38);
    }

    15.7894736842% {
      transform: translateX(-32vw) translateY(1vh) scale(0.41);
    }

    21.0526315789% {
      transform: translateX(-33vw) translateY(31vh) scale(0.81);
    }

    26.3157894737% {
      transform: translateX(2vw) translateY(-33vh) scale(0.43);
    }

    31.5789473684% {
      transform: translateX(-2vw) translateY(41vh) scale(0.78);
    }

    36.8421052632% {
      transform: translateX(-1vw) translateY(25vh) scale(0.69);
    }

    42.1052631579% {
      transform: translateX(-39vw) translateY(-28vh) scale(0.94);
    }

    47.3684210526% {
      transform: translateX(29vw) translateY(13vh) scale(0.42);
    }

    52.6315789474% {
      transform: translateX(42vw) translateY(17vh) scale(0.47);
    }

    57.8947368421% {
      transform: translateX(14vw) translateY(-9vh) scale(0.59);
    }

    63.1578947368% {
      transform: translateX(12vw) translateY(-8vh) scale(0.29);
    }

    68.4210526316% {
      transform: translateX(-31vw) translateY(-25vh) scale(0.81);
    }

    73.6842105263% {
      transform: translateX(-45vw) translateY(13vh) scale(0.33);
    }

    78.9473684211% {
      transform: translateX(-6vw) translateY(-48vh) scale(0.86);
    }

    84.2105263158% {
      transform: translateX(-18vw) translateY(-12vh) scale(0.99);
    }

    89.4736842105% {
      transform: translateX(22vw) translateY(-39vh) scale(0.51);
    }

    94.7368421053% {
      transform: translateX(43vw) translateY(-15vh) scale(0.6);
    }

    100% {
      transform: translateX(41vw) translateY(-14vh) scale(0.56);
    }
  }

  .firefly:nth-child(10) {
    animation-name: move10;
  }

  .firefly:nth-child(10)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(10)::after {
    animation-duration: 15s, 9714ms;
    animation-delay: 0ms, 7928ms;
  }

  @keyframes move10 {
    0% {
      transform: translateX(-5vw) translateY(25vh) scale(0.3);
    }

    3.8461538462% {
      transform: translateX(11vw) translateY(42vh) scale(0.81);
    }

    7.6923076923% {
      transform: translateX(-45vw) translateY(25vh) scale(0.44);
    }

    11.5384615385% {
      transform: translateX(23vw) translateY(-4vh) scale(0.43);
    }

    15.3846153846% {
      transform: translateX(10vw) translateY(12vh) scale(0.84);
    }

    19.2307692308% {
      transform: translateX(-33vw) translateY(38vh) scale(0.95);
    }

    23.0769230769% {
      transform: translateX(15vw) translateY(23vh) scale(0.56);
    }

    26.9230769231% {
      transform: translateX(-12vw) translateY(-17vh) scale(0.42);
    }

    30.7692307692% {
      transform: translateX(1vw) translateY(-34vh) scale(0.61);
    }

    34.6153846154% {
      transform: translateX(22vw) translateY(-25vh) scale(0.32);
    }

    38.4615384615% {
      transform: translateX(-17vw) translateY(26vh) scale(0.65);
    }

    42.3076923077% {
      transform: translateX(3vw) translateY(29vh) scale(0.91);
    }

    46.1538461538% {
      transform: translateX(-12vw) translateY(-36vh) scale(0.64);
    }

    50% {
      transform: translateX(-44vw) translateY(41vh) scale(0.44);
    }

    53.8461538462% {
      transform: translateX(9vw) translateY(-29vh) scale(0.6);
    }

    57.6923076923% {
      transform: translateX(22vw) translateY(30vh) scale(0.98);
    }

    61.5384615385% {
      transform: translateX(-20vw) translateY(33vh) scale(0.7);
    }

    65.3846153846% {
      transform: translateX(40vw) translateY(24vh) scale(0.38);
    }

    69.2307692308% {
      transform: translateX(-19vw) translateY(4vh) scale(0.48);
    }

    73.0769230769% {
      transform: translateX(-18vw) translateY(-32vh) scale(1);
    }

    76.9230769231% {
      transform: translateX(17vw) translateY(1vh) scale(0.68);
    }

    80.7692307692% {
      transform: translateX(-40vw) translateY(-16vh) scale(0.92);
    }

    84.6153846154% {
      transform: translateX(17vw) translateY(32vh) scale(0.9);
    }

    88.4615384615% {
      transform: translateX(14vw) translateY(45vh) scale(0.8);
    }

    92.3076923077% {
      transform: translateX(14vw) translateY(50vh) scale(0.74);
    }

    96.1538461538% {
      transform: translateX(26vw) translateY(47vh) scale(0.83);
    }

    100% {
      transform: translateX(49vw) translateY(-40vh) scale(0.95);
    }
  }

  .firefly:nth-child(11) {
    animation-name: move11;
  }

  .firefly:nth-child(11)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(11)::after {
    animation-duration: 13s, 7476ms;
    animation-delay: 0ms, 5436ms;
  }

  @keyframes move11 {
    0% {
      transform: translateX(-7vw) translateY(16vh) scale(0.42);
    }

    4.1666666667% {
      transform: translateX(-44vw) translateY(14vh) scale(0.89);
    }

    8.3333333333% {
      transform: translateX(17vw) translateY(-25vh) scale(0.72);
    }

    12.5% {
      transform: translateX(-40vw) translateY(-22vh) scale(0.47);
    }

    16.6666666667% {
      transform: translateX(49vw) translateY(12vh) scale(0.93);
    }

    20.8333333333% {
      transform: translateX(-45vw) translateY(21vh) scale(0.6);
    }

    25% {
      transform: translateX(36vw) translateY(-41vh) scale(0.48);
    }

    29.1666666667% {
      transform: translateX(-9vw) translateY(0vh) scale(0.98);
    }

    33.3333333333% {
      transform: translateX(-32vw) translateY(37vh) scale(0.29);
    }

    37.5% {
      transform: translateX(31vw) translateY(-46vh) scale(0.34);
    }

    41.6666666667% {
      transform: translateX(21vw) translateY(-45vh) scale(0.51);
    }

    45.8333333333% {
      transform: translateX(-12vw) translateY(-46vh) scale(0.87);
    }

    50% {
      transform: translateX(25vw) translateY(28vh) scale(0.48);
    }

    54.1666666667% {
      transform: translateX(-16vw) translateY(-25vh) scale(0.76);
    }

    58.3333333333% {
      transform: translateX(-4vw) translateY(48vh) scale(0.63);
    }

    62.5% {
      transform: translateX(48vw) translateY(-32vh) scale(0.31);
    }

    66.6666666667% {
      transform: translateX(-39vw) translateY(-39vh) scale(0.56);
    }

    70.8333333333% {
      transform: translateX(-1vw) translateY(37vh) scale(0.5);
    }

    75% {
      transform: translateX(-9vw) translateY(-5vh) scale(0.56);
    }

    79.1666666667% {
      transform: translateX(-11vw) translateY(13vh) scale(0.96);
    }

    83.3333333333% {
      transform: translateX(-1vw) translateY(-41vh) scale(0.55);
    }

    87.5% {
      transform: translateX(17vw) translateY(18vh) scale(0.58);
    }

    91.6666666667% {
      transform: translateX(-12vw) translateY(10vh) scale(0.99);
    }

    95.8333333333% {
      transform: translateX(33vw) translateY(50vh) scale(0.85);
    }

    100% {
      transform: translateX(43vw) translateY(38vh) scale(0.52);
    }
  }

  .firefly:nth-child(12) {
    animation-name: move12;
  }

  .firefly:nth-child(12)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(12)::after {
    animation-duration: 13s, 5428ms;
    animation-delay: 0ms, 4447ms;
  }

  @keyframes move12 {
    0% {
      transform: translateX(19vw) translateY(-25vh) scale(0.71);
    }

    3.7037037037% {
      transform: translateX(5vw) translateY(-44vh) scale(0.68);
    }

    7.4074074074% {
      transform: translateX(-11vw) translateY(21vh) scale(0.96);
    }

    11.1111111111% {
      transform: translateX(-16vw) translateY(-35vh) scale(0.62);
    }

    14.8148148148% {
      transform: translateX(19vw) translateY(36vh) scale(0.51);
    }

    18.5185185185% {
      transform: translateX(-31vw) translateY(39vh) scale(0.91);
    }

    22.2222222222% {
      transform: translateX(-13vw) translateY(-33vh) scale(0.57);
    }

    25.9259259259% {
      transform: translateX(-11vw) translateY(-43vh) scale(0.91);
    }

    29.6296296296% {
      transform: translateX(6vw) translateY(-23vh) scale(0.35);
    }

    33.3333333333% {
      transform: translateX(50vw) translateY(-5vh) scale(1);
    }

    37.037037037% {
      transform: translateX(-42vw) translateY(0vh) scale(0.98);
    }

    40.7407407407% {
      transform: translateX(35vw) translateY(40vh) scale(0.97);
    }

    44.4444444444% {
      transform: translateX(-6vw) translateY(-30vh) scale(0.28);
    }

    48.1481481481% {
      transform: translateX(7vw) translateY(0vh) scale(0.85);
    }

    51.8518518519% {
      transform: translateX(-29vw) translateY(-42vh) scale(0.82);
    }

    55.5555555556% {
      transform: translateX(-13vw) translateY(-39vh) scale(0.93);
    }

    59.2592592593% {
      transform: translateX(-16vw) translateY(43vh) scale(0.99);
    }

    62.962962963% {
      transform: translateX(19vw) translateY(38vh) scale(0.61);
    }

    66.6666666667% {
      transform: translateX(13vw) translateY(26vh) scale(0.75);
    }

    70.3703703704% {
      transform: translateX(-3vw) translateY(-23vh) scale(0.7);
    }

    74.0740740741% {
      transform: translateX(-19vw) translateY(3vh) scale(0.93);
    }

    77.7777777778% {
      transform: translateX(8vw) translateY(-18vh) scale(0.77);
    }

    81.4814814815% {
      transform: translateX(-23vw) translateY(-12vh) scale(0.88);
    }

    85.1851851852% {
      transform: translateX(29vw) translateY(-27vh) scale(0.75);
    }

    88.8888888889% {
      transform: translateX(-28vw) translateY(-32vh) scale(0.46);
    }

    92.5925925926% {
      transform: translateX(-40vw) translateY(11vh) scale(0.54);
    }

    96.2962962963% {
      transform: translateX(19vw) translateY(3vh) scale(0.86);
    }

    100% {
      transform: translateX(35vw) translateY(-45vh) scale(0.73);
    }
  }

  .firefly:nth-child(13) {
    animation-name: move13;
  }

  .firefly:nth-child(13)::before {
    animation-duration: 12s;
  }

  .firefly:nth-child(13)::after {
    animation-duration: 12s, 10857ms;
    animation-delay: 0ms, 8399ms;
  }

  @keyframes move13 {
    0% {
      transform: translateX(-20vw) translateY(28vh) scale(0.31);
    }

    5.2631578947% {
      transform: translateX(10vw) translateY(-35vh) scale(0.91);
    }

    10.5263157895% {
      transform: translateX(-12vw) translateY(7vh) scale(0.65);
    }

    15.7894736842% {
      transform: translateX(-35vw) translateY(-3vh) scale(0.56);
    }

    21.0526315789% {
      transform: translateX(-24vw) translateY(-16vh) scale(0.35);
    }

    26.3157894737% {
      transform: translateX(0vw) translateY(20vh) scale(0.57);
    }

    31.5789473684% {
      transform: translateX(-45vw) translateY(-3vh) scale(0.77);
    }

    36.8421052632% {
      transform: translateX(37vw) translateY(36vh) scale(0.7);
    }

    42.1052631579% {
      transform: translateX(-9vw) translateY(8vh) scale(0.92);
    }

    47.3684210526% {
      transform: translateX(-14vw) translateY(-41vh) scale(0.29);
    }

    52.6315789474% {
      transform: translateX(-12vw) translateY(-35vh) scale(0.71);
    }

    57.8947368421% {
      transform: translateX(-3vw) translateY(-21vh) scale(0.48);
    }

    63.1578947368% {
      transform: translateX(12vw) translateY(6vh) scale(0.8);
    }

    68.4210526316% {
      transform: translateX(16vw) translateY(-5vh) scale(0.75);
    }

    73.6842105263% {
      transform: translateX(-39vw) translateY(-43vh) scale(0.68);
    }

    78.9473684211% {
      transform: translateX(35vw) translateY(-46vh) scale(0.3);
    }

    84.2105263158% {
      transform: translateX(35vw) translateY(-45vh) scale(0.83);
    }

    89.4736842105% {
      transform: translateX(-8vw) translateY(13vh) scale(0.39);
    }

    94.7368421053% {
      transform: translateX(-12vw) translateY(32vh) scale(0.45);
    }

    100% {
      transform: translateX(-14vw) translateY(42vh) scale(0.67);
    }
  }

  .firefly:nth-child(14) {
    animation-name: move14;
  }

  .firefly:nth-child(14)::before {
    animation-duration: 11s;
  }

  .firefly:nth-child(14)::after {
    animation-duration: 11s, 7829ms;
    animation-delay: 0ms, 5814ms;
  }

  @keyframes move14 {
    0% {
      transform: translateX(10vw) translateY(-18vh) scale(0.99);
    }

    5.8823529412% {
      transform: translateX(7vw) translateY(18vh) scale(0.53);
    }

    11.7647058824% {
      transform: translateX(-38vw) translateY(3vh) scale(0.96);
    }

    17.6470588235% {
      transform: translateX(22vw) translateY(-38vh) scale(0.49);
    }

    23.5294117647% {
      transform: translateX(8vw) translateY(-38vh) scale(0.41);
    }

    29.4117647059% {
      transform: translateX(34vw) translateY(26vh) scale(0.7);
    }

    35.2941176471% {
      transform: translateX(-7vw) translateY(-3vh) scale(0.52);
    }

    41.1764705882% {
      transform: translateX(-43vw) translateY(45vh) scale(0.43);
    }

    47.0588235294% {
      transform: translateX(47vw) translateY(9vh) scale(0.89);
    }

    52.9411764706% {
      transform: translateX(45vw) translateY(32vh) scale(0.77);
    }

    58.8235294118% {
      transform: translateX(8vw) translateY(16vh) scale(0.64);
    }

    64.7058823529% {
      transform: translateX(-45vw) translateY(-38vh) scale(0.92);
    }

    70.5882352941% {
      transform: translateX(-6vw) translateY(-40vh) scale(0.64);
    }

    76.4705882353% {
      transform: translateX(29vw) translateY(-27vh) scale(0.83);
    }

    82.3529411765% {
      transform: translateX(4vw) translateY(10vh) scale(0.86);
    }

    88.2352941176% {
      transform: translateX(-8vw) translateY(43vh) scale(0.91);
    }

    94.1176470588% {
      transform: translateX(33vw) translateY(10vh) scale(0.57);
    }

    100% {
      transform: translateX(-17vw) translateY(14vh) scale(0.83);
    }
  }

  .firefly:nth-child(15) {
    animation-name: move15;
  }

  .firefly:nth-child(15)::before {
    animation-duration: 14s;
  }

  .firefly:nth-child(15)::after {
    animation-duration: 14s, 8455ms;
    animation-delay: 0ms, 1227ms;
  }

  @keyframes move15 {
    0% {
      transform: translateX(12vw) translateY(-1vh) scale(0.28);
    }

    4% {
      transform: translateX(6vw) translateY(-14vh) scale(0.61);
    }

    8% {
      transform: translateX(9vw) translateY(-13vh) scale(0.51);
    }

    12% {
      transform: translateX(26vw) translateY(14vh) scale(0.81);
    }

    16% {
      transform: translateX(-32vw) translateY(7vh) scale(0.99);
    }

    20% {
      transform: translateX(19vw) translateY(10vh) scale(0.27);
    }

    24% {
      transform: translateX(-13vw) translateY(-10vh) scale(0.83);
    }

    28% {
      transform: translateX(-35vw) translateY(-2vh) scale(0.39);
    }

    32% {
      transform: translateX(-28vw) translateY(-13vh) scale(0.82);
    }

    36% {
      transform: translateX(-45vw) translateY(28vh) scale(0.48);
    }

    40% {
      transform: translateX(-5vw) translateY(42vh) scale(0.74);
    }

    44% {
      transform: translateX(-30vw) translateY(22vh) scale(0.44);
    }

    48% {
      transform: translateX(15vw) translateY(-21vh) scale(0.55);
    }

    52% {
      transform: translateX(-6vw) translateY(1vh) scale(0.94);
    }

    56% {
      transform: translateX(-26vw) translateY(13vh) scale(0.26);
    }

    60% {
      transform: translateX(44vw) translateY(-49vh) scale(0.67);
    }

    64% {
      transform: translateX(23vw) translateY(-28vh) scale(0.35);
    }

    68% {
      transform: translateX(35vw) translateY(37vh) scale(0.56);
    }

    72% {
      transform: translateX(-4vw) translateY(43vh) scale(0.76);
    }

    76% {
      transform: translateX(-43vw) translateY(43vh) scale(0.56);
    }

    80% {
      transform: translateX(-12vw) translateY(1vh) scale(0.99);
    }

    84% {
      transform: translateX(-26vw) translateY(3vh) scale(0.3);
    }

    88% {
      transform: translateX(46vw) translateY(-9vh) scale(0.55);
    }

    92% {
      transform: translateX(33vw) translateY(-15vh) scale(0.66);
    }

    96% {
      transform: translateX(19vw) translateY(-3vh) scale(0.59);
    }

    100% {
      transform: translateX(-32vw) translateY(-18vh) scale(0.31);
    }
  }

  @keyframes drift {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes flash {

    0%,
    30%,
    100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw yellow;
    }

    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw yellow;
    }
  }


  .stickyBtn2 {
    width: 170px;
    height: 170px;
    opacity: 0.5;
    transition: 0.5s ease;
    z-index: 1;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    transition: transform .4s;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    opacity: 100%;
    z-index: 1;
  }
}